import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import HealthGallery from '../../components/HealthGallery/HealthGallery';
import VisionaireForm from '../../components/VisionaireForm/VisionaireForm';
import './Register.scss';
import ClusterIcons from '../../components/cluster-icons';
import Footer from '../../components/Footer/Footer';
import { useLocation } from 'react-router-dom';


const Register =()=> {
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  return (
    <div className='register-page'>
    
         <div className="banner">
         <Header  />
         <ClusterIcons active />

        <section>
            <Row>
            <Col sm={12} md={12} className="r-banner-content">
                    <h1>Get Started</h1>
                </Col>
            </Row>
        </section>
    </div>
    <section className="p-tb-35">
      <Container>
            <Row>
            <Col sm={12} md={6} >
                <VisionaireForm name={searchParams.get('name')?.toString()} email={searchParams.get('email')?.toString() }/>
                </Col>
                <Col sm={12} md={6} className="border-lefts mobile-border-hide">
                <HealthGallery/>
                </Col>
                </Row>
      </Container>
    </section>
    <Footer />
    </div>
  );
}

export default Register;
