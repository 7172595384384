import React from 'react';
import ActionMind from '../../assets/images/mind-health/mind.png';
import './index.scss';

const HealthBook = (props) => {
    return (
      <div className="health-book">
      <h4>Health psychology Book</h4>
      <div className="book-container">
          <div className="book-info">
              <h3>Health Psychology</h3>
              <span> A Paradigm Shift to Greater Health Span</span>
              <span className="auther">Author: <i>Kim Larking</i></span>
              <p>Description: Awaken Mind X Body by understanding and successfully governing your Self Protecting and Healing Mind-Body System - shifting the health paradigm from institutional reliance to self-care, through simple ‘Life Logical Health Care Principles & Systems’, which every person can comfortably take charge of.</p>
              <p>Learn the processors of the nervous, endocrine and immune systems and discover how your psychology determines your physiology. Gain the Tools and Techniques to create a mind that serves you, champion mental health issues, evolve emotional vibrancy and joy, and unlock faster Natural Remedies and trauma processing through amplifying the power of the body through the advanced application of the mind.</p>
              <p>Use this Principle Guide as a solid base to start proactively generating holistic health, and to lead into and interlock the following five Guides in the Vitality and Longevity Series.</p>
          </div>
          <div className="book-action">
              <div className="more">
                  <h3>understand the workings<br/> of the mind</h3>
                  <button>View More</button>
              </div>
              <img src={ActionMind} alt='mind-img' />
          </div>
      </div>

  </div>
    )
}

export default HealthBook;