import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Header from "../../components/Header/Header";
import  Carousel  from 'react-spring-3d-carousel';
import { config } from "react-spring";

import Banner from "../../assets/images/metawheel/banner.png";
import MetawheelLogo from "../../assets/images/metawheel/metawheel-logo.png";
import User1 from "../../assets/images/metawheel/user1.png";
import Footer from "../../components/Footer/Footer";
import FooterBanner from "../../components/footer-banner";
import mobs from "../../assets/images/metawheel/mobs.png";
import metawheel from "../../assets/images/metawheel/metawheel.png";
import playerIcon from "../../assets/images/metawheel/player-icon.png";
import Grid1 from "../../assets/images/metawheel/grid1.png";
import Grid2 from "../../assets/images/metawheel/grid2.png";
import Grid3 from "../../assets/images/metawheel/grid3.png";
import Grid4 from "../../assets/images/metawheel/grid4.png";
import Slider from "../../assets/images/metawheel/slider.png";
import bannerImg from "../../assets/images/metawheel/banner-img.png";
import "./index.scss";

const DiscoverMetawheel = () => {
  let slides = [
    {
      key: 1,
      content: <img src={Slider} alt="1" />
    },
    {
      key: 2,
      content:<img src={Slider} alt="2" />,

    },
    {
      key: 3,
      content:<img src={Slider} alt="3" />,

    },
    {
      key: 4,
      content:<img src={Slider} alt="4" />,

    },
    {
      key: 5,
      content:<img src={Slider} alt="5" />,

    },
    {
      key: 6,
      content:<img src={Slider} alt="6" />,

    }
    
  ];
  const callback = function (index) {
    console.log("callback", index);
  };

  return (
    <div className="discover-metawheel page-w">
      <Header />
      <div className="discover-metawheel_banner">
        <div className="discover-metawheel_banner_content">
          <div className="discover-metawheel_banner_content_cont">
            <h2>Metawheel</h2>
            <p>Human Advancement Architecture</p>
            <p>
              Unlock the pathways to your full Life force, Holistic <br /> Human
              Vibrancy, Significance and Purpose.
            </p>
            <ul>
              <li>
                <a href="#MetaWheel">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                  MetaWheel
                </a>
              </li>
              <li>
                <a href="#BecomeaPowerfulCreator">
                <i className="fa fa-chevron-right" aria-hidden="true"></i> Become a
                Powerful Creator{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href="#discoverr">
                <i className="fa fa-chevron-right" aria-hidden="true"></i> Discover
                </a>
              </li>
              <li>
                {" "}
                <a href="#shiftt">
                <i className="fa fa-chevron-right" aria-hidden="true"></i> Shift
                </a>
              </li>
              <li>
                {" "}
                <a href="#evolvee">
                <i className="fa fa-chevron-right" aria-hidden="true"></i> Evolve
                </a>
              </li>
              <li>
                {" "}
                <a href="#createe">
                <i className="fa fa-chevron-right" aria-hidden="true"></i> Create
                </a>
              </li>
              <li>
                <a href="#SelfEvolutionary">
                <i className="fa fa-chevron-right" aria-hidden="true"></i> You are
                Self Evolutionary{" "}
                </a>
              </li>
            </ul>
            <button>Get IT Now</button>
          </div>
          <div className="discover-metawheel_banner_content_rightImg">
            <img alt="no image" src={Banner} className="left-img" />
            <p>View Live App Demo</p>
          </div>
        </div>
      </div>
      <div className="content-sec1">
        <Row>
          <Col sm={12} md={6}>
            <div className="container-img">
              <img alt="no image" src={User1} className="left-img" />
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="container-text" id="MetaWheel">
              <h2>
                <img alt="no image" src={MetawheelLogo} className="left-img" />
                Metawheel
              </h2>
              <p>Discover Shift Evolve Create</p>
              <p>Human Advancement Architecture</p>
              <p>
                Use our comprehensive questionnaire process and testing
                facilities to help understand your current life state. Discover
                deeper aspects to yourself that you may not have considered
                before; identifying areas in your life that you are excelling
                at, and areas of concern that are undermining your full Life
                Force that you wish to Shift.
              </p>
              <p>
                Use a personalised Recommendation Schedule to establish your
                Desired Being, the 2nd Genome blue print of the very best you.
                Evolve your human potential horizon beyond your expectations to
                encompass your full Holistic Human Vibrancy, Significance and
                Purpose.
              </p>
              <p>
                Then with the help of this personalised advancement system and
                our facilitators, Create your Desired Being using all of our
                Sanctuary services in the most advanced wellness centre on earth
                and experience your physical, mental, emotional and spiritual
                magnificence.
              </p>
              <h1>Discover Your Magnificence</h1>
            </div>
          </Col>
        </Row>
      </div>
      <div className="content-sec2">
        <div className="container-text" id="BecomeaPowerfulCreator">
          <h2>Become a Powerful Creator </h2>
          <ul>
            <li>
              <i className="fa fa-circle" aria-hidden="true"></i>
              <span>
                {" "}
                Access age and gender orientated Self Discovery questionnaires
                and micro apps.
              </span>
            </li>
            <li>
              <i className="fa fa-circle" aria-hidden="true"></i>
              <span>
                {" "}
                Map, understand and evaluate for yourself, your personal Life
                Architecture.{" "}
              </span>
            </li>
            <li>
              <i className="fa fa-circle" aria-hidden="true"></i>
              <span>
                {" "}
                Receive personalised and comprehensive analysis reports based
                around your Physical, Mental, Emotional and Spiritual wellbeing.
              </span>
            </li>
            <li>
              <i className="fa fa-circle" aria-hidden="true"></i>
              <span>
                {" "}
                Equip yourself with a Sanctuary Recommendation Schedules to best
                advance your life.
              </span>
            </li>
            <li>
              <i className="fa fa-circle" aria-hidden="true"></i>
              <span>
                {" "}
                Tap directly into the best Human Advancement services for you,
                at the Sanctuary. Become a master creator with the Metawheel.{" "}
              </span>
            </li>
          </ul>
          <div className="btn-sec1">
            <button>Download</button>
            <button>
              {" "}
              <img alt="no image" src={playerIcon} className="left-img" />
              View Live DEmo
            </button>
          </div>
        </div>

        <div className="container-img">
          <img alt="no image" src={mobs} className="left-img" />
        </div>
      </div>
      <div className="grid-container">
        <div className="grid-section1">
          <img alt="no image" src={Grid1} className="left-img" />
          <div className="grid-text-sec" id="discoverr">
            <div className="grid-text">
              <h2>
                Discover deeper aspects to <br />
                yourself{" "}
              </h2>
              <p>
                The only way to grow yourself is to know yourself. Use our
                comprehensive questionnaire process and testing facilities to
                help understand your current life state. Start your personal
                Self discovery today with the Metawheel.
              </p>
              <h1>Discover</h1>
            </div>
          </div>
        </div>
        <div className="grid-section1" id="shiftt">
          <div className="grid-text-sec">
            <div className="grid-text">
              <h2>
                Identify the Shift areas in your life that you wish to embark
                upon
              </h2>
              <p>
                Become self-empowered through constructive choose; identify for
                yourself the blockages once removed, and your inherent key
                strengths,that have the potential to catalyse a transformational
                Shift to greater fulfilment.
              </p>
              <h1 className="blu1">Shift</h1>
            </div>
          </div>
          <img alt="no image" src={Grid2} className="left-img" />
        </div>
        <div className="grid-section1" id="evolvee">
          <img alt="no image" src={Grid3} className="left-img" />
          <div className="grid-text-sec">
            <div className="grid-text">
              <h2>
                Evolve your human potential horizon beyond your expectations
              </h2>
              <p>
                With the guidance of the Sanctuary, custom generated
                Recommendation Schedule; work with a structured program and time
                frame to achieve your desires in an achievable step by step
                process.
              </p>
              <p>
                Tap into the right combination of Sanctuary resources for your
                optimum development: Personal Guides, Workshops, Courses,
                Advanced Exercise Programs, Health Protocols, Food Science
                Foods, Natural Highs Drinks, Therapeutic Massage and Treatments,
                Natural Lifestyle Products, other Advancement Apps and our
                in-house Health Professionals.
              </p>
              <h1 className="per1">Evolve</h1>
            </div>
          </div>
        </div>
        <div className="grid-section1" id="createe">
          <div className="grid-text-sec">
            <div className="grid-text">
              <h2>Create your desired being</h2>
              <p>
                Create your Desired Being using all of the Sanctuaries services
                and experience your physical, mental, emotional and spiritual
                magnificence.
              </p>
              <p>
                Whether online or at the Sanctuary, activate your Recommendation
                Schedule in a highly conducive environment for personal
                advancement. Be a powerful creator and chisel out the destiny
                you have determined.
              </p>
              <h1 className="org1">Create</h1>
            </div>
          </div>
          <img alt="no image" src={Grid4} className="left-img" />
        </div>
      </div>
      <div className="grid-slider">
        <h2>A Look in to the MetaWheel App</h2>
        <div
        style={{ width: "80%", height: "300px", margin: "0 auto" }}
        
      >
        
        <Carousel slides={slides}
        // goToSlide={1}
        offsetRadius={3}
        showNavigation={2}
        animationConfig={ config.gentle} />
        </div>
        <marquee>DiscoveR Shift Evolve Create</marquee>
      </div>
      <div className="img-gri" id="SelfEvolutionary">
        <div className="grid-img-sec">
          <div className="text-conatiner">
            <h2>
              You are <br />
              Self Evolutionary
            </h2>
            <p>
              In the context of evolution, living things develop over millions
              of years through a gradual process of adaptation and advancement.
              As a human being you have a mind that enables SELF-EVOLUTION,
              which by definition is to develop by inherent quality or power.
              That is, through Your inherent quality or power.
            </p>
            <p>
              In nature we witness the caterpillar become the butterfly in a
              matter of weeks, as evidence of complete transformation. This is
              evolution at its finest, which over millions of years has
              perfected a second tier, highly accelerated, transformational
              process. How remarkable !
            </p>
            <p>
              As human beings we also possess remarkable abilities to create or
              to transform at a rapid rate. This is possible through the proper
              utilisation of the mind.
            </p>
            <span>
              “The mind holds the creation powers of magination, planning & self
              determination.”
            </span>
            <p>
              Once you are conscious of your own self evolution, you can utilise
              the mind to direct and design your life. Become a master creator
              with the Metawheel.
            </p>
            <p>
              As a master creator you continuously Discover, Shift, Evolve and
              Create. As you are creating you are discovering, shifting and
              evolving effecting new creation concepts and roads to then journey
              upon. This cycle is what makes you human, powerful and
              evolutionary.
            </p>
            <p>
              In this cycle you have the ability to continually Increase; to
              experience greater joy, vibrancy and success now, but to also
              forge a life signature – in planning long term to leave a legacy.
            </p>
            <p>
              The Sanctuary Metawheel has been designed to accelerate this
              incredible self-evolutionary process, to help you become the
              architect of your own life by providing four corner stone learning
              arenas essential to human development: Vitality and Longevity,
              Wisdom and Success, Empowerment and Significance, Clarity and
              Beauty. These arenas holistically interlock to bring out your full
              Life Force, Holistic Human Vibrancy, significance and Purpose.
              Your inherent magnificence!
            </p>
            <p>
              The Metawheel provides the human architecture that enables you to;
              Discover who you are and take stock of your life, reflect upon
              what Shifts you wish to make in your life, to Evolve the insights,
              knowledge, emotional ability, spiritual understanding and beliefs
              to enable these changes to seed, and then provides the tools to
              orchestrate exactly how to manifest these changes in your life.
            </p>
            <h3>Kim Larking</h3>
            <h5>Human Advancement Architect</h5>
          </div>
          {/* <img alt="no image" src={bannerImg} className="left-img" /> */}
        </div>
      </div>
      <div className="grid-plan-sec">
        <h2>Select your plan</h2>
        <Row>
          <Col sm={12} md={4}>
            <div className="plan-sec top-space">
              <div className="plan-sec1">
                <h3>Explore</h3>
                <p>
                  Use our Metawheel as a free guide to improving your current
                  life state. Receive a system-generated, artificial
                  intelligence Recommendation Schedule (RS). Use this RS to
                  explore the pathways to greater well-being; physically,
                  mentally, emotionally, and spiritually.{" "}
                </p>
                <div className="price-sec">
                  <span>
                    <h2>
                      $0<sub>/Free</sub>
                    </h2>
                    <label></label>
                  </span>

                  <button>Download</button>
                </div>
              </div>
              <div className="dis-sec">
                <ul>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>System-generated personal report</span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      Self-discovery, holistic life architecture mapping
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      Physical, mental, emotional, and spiritual well-being
                      evaluation
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      System generated life advancement Recommendation Schedule
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      Priority settings for your ‘Desired Being’ advancement
                      programs
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      Quick purchase link for all identified beneficial products
                      and services
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>Complimentary repeat Metawheel Analysis</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className="plan-sec plan-se2">
              <h1>MOST POPULAR</h1>
              <div className="plan-sec1 ">
                <h3>Premium</h3>
                <p>
                  For a more personalised and accurate appraisal and customised
                  self-advancement plan, sign up as a Premium User to receive a
                  Recommendation Schedule generated with the help of our Health
                  Facilitators. Our Premium service is the first step to
                  personal care advancement.
                </p>
                <div className="price-sec">
                  <span>
                    <h2>
                      $150<sub>/Year</sub>
                    </h2>
                    <label>includes up to an hour of guidance</label>
                  </span>

                  <button>Download</button>
                </div>
              </div>
              <div className="dis-sec">
                <label>All Explore features plus </label>
                <ul>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      One time remote questionnaire guidance assisted by a
                      Health Facilitator{" "}
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      Annual subscription to repopulate Metawheel and obtain
                      questionnaire history
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      More accurate and personalised holistic life architecture
                      mapping
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      Customised life advancement Recommendation Schedule
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      Guidance of structured ‘Desired Being’ advancement
                      programs and time frames{" "}
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      Metawheel App Integration with other paid Sanctuary Apps
                      and facilities
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className="plan-sec top-space plan-se3">
              <div className="plan-sec1 ">
                <h3>Precision</h3>
                <p>
                  For the most personalised and accurate appraisal and
                  customised self-advancement plan, sign up as a Precision User
                  to receive a final Recommendation Schedule generated with the
                  help of one of our Health Facilitators and aided by specific
                  health tests based on your needs.{" "}
                </p>
                <div className="price-sec">
                  <span>
                    <h2>
                      $250<sub>/Year + tests</sub>
                    </h2>
                    <label>
                      Price upon request dependent upon tests required.
                    </label>
                  </span>

                  <button>Download</button>
                </div>
              </div>
              <div className="dis-sec">
                <label>All Premium features plus </label>
                <ul>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      One time In-person (video/in-house) questionnaire guidance
                      personally managed by a Health Facilitator
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>Age and gender-related questionnaires</span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      Most accurate and personalised holistic life architecture
                      mapping and RS for greatest self-advancement
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      Clearest results to identify ideal Desired Being and
                      personal Advancement Programs
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      Use the Precision process to pinpoint health tests most
                      needed
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    <span>
                      Dashboard Integration of specialised health tests and
                      micro app data
                    </span>
                  </li>
                </ul>
                <div className="steps-sec">
                  <h5>The Precision Process</h5>
                  <p>
                    Step 1. Use our Metawheel App, in order to obtain the
                    information our health professionals initially require, to
                    best advise the particular precision tests you need for your
                    greatest advancement.
                  </p>
                  <p>
                    Step 2. Undergo those tests and let us use the results to
                    more thoroughly evaluate your current overall well-being in
                    order for our team to best define your optimum
                    Recommendation Schedule and Advancement Protocols.
                  </p>
                  <p>
                    Step 3. Our health professionals are then available to work
                    with you to determine your Desired Being and personalised
                    plan giving you the options, and helping integrate other
                    Sanctuary Apps and facilities, to best prioritise and plan
                    your own program.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="back-sec3">
        <h1>Something Greater</h1>
        <div className="content-sec3">
          <div className="container-img">
            <img alt="no image" src={metawheel} className="left-img" />
            <div className="meta">
              <p>Global Metawheel</p>
              <a href="">View full architecture</a>
            </div>
          </div>
          <div className="container-text">
            <h2>Global Metawheel</h2>
            <p>Global Advancement Architecture</p>
            <p>
              Your magnificence can help others. The Global Metawheel
              illustrates how your growth ultimately benefits the planet.
              Further, there is significant research revealing how helping
              others, or contributing to something meaningful, can provide you
              even greater happiness and wellbeing. Through little effort, but
              greater understanding, your advancement seamlessly Elevates Global
              Consciousness bringing more Increase to others.
            </p>
            <p>
              We believe in first helping the individual in order that they may,
              from a position of strength and compassion, help others. The
              second ring of the Global Metawheel focuses on global advancement.
              The VFH Visions, Initiatives and Charter surrounds the wheel
              supporting and interlocking the blueprint of a 2nd Genome of
              Humanity.
            </p>
            <p>
              The Sanctuary interlocks your development and advancement into
              planetary wellbeing. Through its greater purpose and ethos, the
              Sanctuary shows the path of greater people for better planet.
            </p>
            <p>
              This may be something you wish to explore at a later date, or if
              of interest, take a look now and discover how, at the right time,
              you can engage a higher purpose to participate in creating
              something much greater even than your magnificent self: “A
              Successful Planet.”
            </p>
          </div>
        </div>
      </div>
      <FooterBanner />
      <Footer />
    </div>
  );
};
export default DiscoverMetawheel;
