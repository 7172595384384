import React from "react";
import { useHistory } from 'react-router-dom';
import Sphere from "../../views/Sphere"
import './index.scss';

const Index = ({ active = false }) => {
    const history = useHistory();
    const dbLinks = (link) => {
        history.push(link)
    }
    return (
        <React.Fragment>
        <div className="right_icon_mobile">
            <div onClick={() => dbLinks('/service-health')}>
            <Sphere type={0} sphereRad={30} width={60} height={60}  />
            </div>
            <div>
            <Sphere type={1} sphereRad={30} width={60} height={60} />
            </div>
            <div>
            <Sphere type={2} sphereRad={30} width={60} height={60}/>
            </div>
            <div>
            <Sphere type={3} sphereRad={30} width={60} height={60} />
            </div>
        </div>
        <div className="right_icon">
            <div onClick={() => dbLinks('/service-health')}>
            <Sphere type={0} sphereRad={60} width={120} height={120}  />
            </div>
            <div>
            <Sphere type={1} sphereRad={60} width={120} height={120} />
            </div>
            <div>
            <Sphere type={2} sphereRad={60} width={120} height={120}/>
            </div>
            <div>
            <Sphere type={3} sphereRad={60} width={120} height={120} />
            </div>
        </div>
        </React.Fragment>
    )
}

export default Index;