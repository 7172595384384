import React, { useState } from "react";
import "./index.css";
import playerIcon from "../../assets/images/page/account/pro-icon.png";
import profileImg from "../../assets/images/page/account/profile.png";
import home from "../../assets/images/page/account/home.png";
import person from "../../assets/images/page/account/person.png";
import giftcard from "../../assets/images/page/account/giftcard.png";
import share from "../../assets/images/page/account/share.png";
import lockOpen from "../../assets/images/page/account/lock_open.png";
import satisfied from "../../assets/images/page/account/very_satisfied.png";
import list from "../../assets/images/page/account/list.png";
import deliveryBox from "../../assets/images/page/account/delivery-box.png";
import network from "../../assets/images/page/account/network.png";
import addCircle from "../../assets/images/page/account/add_circle.png";
import star from "../../assets/images/page/account/star.png";
import { Edit } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { useFetchCustomer, useFetchOrderHistory } from "shared/react-query/hooks";
import CustomerOrdersTable from "./CustomerOrdersTable";
import Header from "~/components/Header/Header";
import bannerImg from "../../assets/images/account/banner.png";
import { Row, Col } from "react-bootstrap";
import VisionaireNetworkTable from "./VisionaireNetworkTable";
import Footer from "../../components/Footer/Footer";
import VisionaireNetworkTable2 from "./VisionaireNetworkTable2";
import AccordionDetails from "./AccountDetails.js";
import { useAuth } from 'shared/auth';
import { useQuery } from 'react-query';
import { fetchVisionaireProfile } from 'shared/react-query/strapiAPI';
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { isNil } from 'lodash';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { StrapiVideo } from "shared/ui/StrapiVideo"
import {getStrapiAssetURL} from "shared/utils/strapiUtils";
import { ArrowBack, PlayCircleOutline } from '@material-ui/icons';
import { Button, Card, CardContent, Typography } from '@material-ui/core';


const Account = () => {
  const {id: slug} = useParams();
  const [playVideo, setPlayVideo] = useState(false);
  const {auth, profileData} = useAuth();
  const { data, error, isLoading } = useQuery(['visionaireProfile', slug, auth.token], () => fetchVisionaireProfile(slug ?? profileData?.slug, auth.token), { enabled: !isNil(slug ?? profileData?.slug)});
  const isLoggedinUserProfile = !isNil(data?.data?.attributes?.email)
  console.log(isLoggedinUserProfile,data?.data?.attributes?.email,'data?.email')

  const { data: orderHistoryData } = useFetchOrderHistory({
    token: auth.token,
  });
  let component;
  const [componentToDisplay, setComponentToDisplay] =
    useState("Account Detail");

  const handleListItemClick = (listItem) => {
    console.log(listItem);
    setComponentToDisplay(listItem);
  };

  const renderSwitch = (componentToDisplay) => {
    switch (componentToDisplay) {
      case "Account Detail":
        return <AccordionDetails />;

      case "Advance Order":
        return <CustomerOrdersTable data={orderHistoryData ?? []} />;
      case "My Order":
        return <CustomerOrdersTable data={orderHistoryData ?? []} />;

      case "Visionaire Network":
      case "wheel":
        return <VisionaireNetworkTable display={handleListItemClick} />;

      case "grid":
        return <VisionaireNetworkTable2 display={handleListItemClick} />;
      default:
        return <AccordionDetails />;
    }
  };
  if(!data){
    return <></>
    }
  const images = data?.data.attributes?.displayPhotosAndVideos?.data?.map((e, i) => {
    if (e?.attributes?.mime == "video/mp4") {
      return <div style={{ position: "relative", width: "100%", height: "100%" }} onClick={() => { setPlayVideo(e?.attributes?.url) }}> <StrapiVideo segment={e?.attributes?.url} controls={false} />
        <PlayCircleOutline style={{ position: "absolute", bottom: "35%", left: "50%", transform: "translateX(-50%)", height: "60px", width: "60px" }} />
      </div>
    }
    return (
      <img key={i} src={getStrapiAssetURL(e?.attributes?.url)} className="dynamicimg" />
    )
  })
  return (
    <div className="account-detail">
      <Header />
      <Row>
        <Col sm={12} md={12}>
          <div className="banner-secss">
            <div className="content-sec">
              <h3>ACCOUNT</h3>
            </div>
            <img alt="not found" src={bannerImg} />
          </div>
        </Col>
      </Row>
      <div className="profile-wrap">
        <Grid container spacing={2}>
          <Grid item md={6} style={{ width: "100%" }}>
            <div className="profile-sec">
              <div className="pro">
              <Carousel showArrows={true} showThumbs={false}>
                {images}
              </Carousel>
                <div className="btn-pro">
                  <button>UPLOAD Image</button>
                  <button>SET DEFAULT</button>
                </div>
              </div>
              <div className="pro2">
              <Typography className="userName">{data.data.attributes.firstName} {data.data.attributes?.lastName ?? ""}</Typography>
                <p> <img alt="not found" src={home} />{data.data.attributes.location}</p>
                <p> <img alt="not found" src={star} />{data.data.attributes.title}</p>
                {/* <button>MY SCREEN SAVER</button> */}
              </div>
            </div>
          </Grid>
          <Grid item md={2} style={{ width: "100%" }}>
            <div className="profile-sec2">
              <p>METAWHEEL</p>
              <img alt="not found" src={playerIcon} />
            </div>
          </Grid>
          <Grid item md={4} style={{ width: "100%" }}>
            <div className="profile-sec3">
              <button>Progress account profile</button>
              <button>set public profile</button>
            </div>
          </Grid>
        </Grid>
        <div className="tab-wraper">
          <Grid container>
            <Grid item md={3} className="no-pad">
              <div className="profile-tab">
                <ul>
                  <li
                    className={
                      componentToDisplay === "Account Detail"
                        ? "tab-active"
                        : ""
                    }
                    onClick={() => handleListItemClick("Account Detail")}
                  >
                    <img src={person} />
                    <span>Account Detail</span>
                  </li>
                  <li>
                    <img alt="not found" src={giftcard} />
                    <span>Visionaire Rewards</span>
                  </li>
                  <li
                    className={
                      componentToDisplay === "Visionaire Network"
                        ? "tab-active"
                        : ""
                    }
                    onClick={() => handleListItemClick("Visionaire Network")}
                  >
                    <img alt="not found" src={share} />
                    <span>Visionaire Network</span>
                  </li>

                  <li>
                    <img alt="not found" src={lockOpen} />
                    <span>Change Password</span>
                  </li>
                  <li>
                    <Edit />
                    <span>Create Detail</span>
                  </li>
                  <li>
                    <img alt="not found" src={satisfied} />
                    <span>About Me</span>
                  </li>
                  <li
                    className={
                      componentToDisplay === "My Order" ? "tab-active" : ""
                    }
                    onClick={() => handleListItemClick("My Order")}
                  >
                    <img alt="not found" src={list} />
                    <span>My Order</span>
                  </li>
                  <li
                    className={
                      componentToDisplay === "Advance Order" ? "tab-active" : ""
                    }
                    onClick={() => handleListItemClick("Advance Order")}
                  >
                    <img alt="not found" src={deliveryBox} />
                    <span>Advance Order</span>
                  </li>
                  <li
                    className={
                      componentToDisplay === "participate" ? "tab-active" : ""
                    }
                    onClick={() => handleListItemClick("participate")}
                  >
                    <img alt="not found" src={network} />
                    <span>Participate</span>
                  </li>
                  <li>
                    <img alt="not found" src={addCircle} />
                    <span>Create Profile</span>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item md={9} className="no-pad">
              <div className="profile-content">
                <h2>{(componentToDisplay === 'wheel' || componentToDisplay === 'grid') ? 'VISIONAIRE NETWORK' : componentToDisplay.toUpperCase()}</h2>
                <Grid container>{renderSwitch(componentToDisplay)}</Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Account;
