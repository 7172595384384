import React, { useEffect, useState } from "react";
import { Row,Col } from 'react-bootstrap';
import barrow from "../../assets/images/b-arrow.png";
import advexerBanner from "../../assets/images/home-img/card2.png";
import './AdvanceBannerSlider.scss';
import HealthInfoWrapper from '../../components/health-info-wrapper';

const AdvanceBannerSlider = () => {
    const [scrollPage, setOffset] = useState(0);
    const [dotGlobal, changeImg] = useState(1);
    const textDot = ['HEALTH', 'Prosperity', 'Inspiration', 'Joy'];
    useEffect(() => {
      window.onscroll = () => {
        setOffset(window.pageYOffset);
      };
    }, []);
  
    
  return (
    <>
    <div className="advance-Exercise-slider">
        <Row>
          <Col sm={12} md={12}>
              <div className="slider-conatiner">
                <div className="cente-text">
                  <h1>Advancement exercises</h1>
                  <p>Can be done anywhere- no specialised equipment required</p>
                  <button>START TODAY</button>
                  <div className="arrow-icon">
                    <img alt="no image" src={barrow} />
                  </div>
                </div>
                <img alt="no image" src={advexerBanner} className="slider-img"/>
              </div>
          </Col>
        </Row>
        <HealthInfoWrapper dotGlobal={dotGlobal} />
      </div>

    </>
  );
}

export default AdvanceBannerSlider;