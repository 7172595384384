import * as React from "react";
import Header from "../../components/Header/Header";
import "./index.scss";
import FooterBanner from "../../components/footer-banner";
import OptimiseReport from "../../components/optimise-report";
import FiveMen from "../../components/five-men";
import SanctuaryServices from "../../components/sanctuary-services";
import ph_ppbw from "../../assets/images/reviews/placeholder_pp_bg-removed-bw.png";
import Mari from "../../assets/images/reviews/Mari.jpg";
import Graeme from "../../assets/images/reviews/Graeme.png";
import Mike from "../../assets/images/reviews/Mike.png";
import kimmcw from "../../assets/images/reviews/kimmcw.png";
import IvanM from "../../assets/images/reviews/IvanM.jpg";
import GaryJ from "../../assets/images/reviews/GaryJ.jpg";
import CustomerReviews from "../../components/customer-reviews";
import cluster1 from "../../assets/images/Cluster-1.png";
import cluster2 from "../../assets/images/Cluster-2.png";
import cluster3 from "../../assets/images/Cluster-3.png";
import cluster4 from "../../assets/images/Cluster-4.png";
import Disclaimer from "../../components/disclaimer";
import Star from "../../assets/images/health-package/star.png";
import avatar1 from "../../assets/images/health-specialists/a1.png";
import avatar2 from "../../assets/images/health-specialists/a2.png";
import avatar3 from "../../assets/images/health-specialists/a3.png";
import avatar4 from "../../assets/images/health-specialists/a4.png";
import avatar5 from "../../assets/images/health-specialists/a5.png";
import downArrow from "../../assets/images/health-specialists/downArrow.png";
import tile1 from "../../assets/images/health-specialists/t1.png";
import tile2 from "../../assets/images/health-specialists/t2.png";
import ClusterIcons from "../../components/cluster-icons";
import Specialist1 from "../../assets/images/health-specialists/p1.png";
import Specialist2 from "../../assets/images/health-specialists/p2.png";
import Specialist3 from "../../assets/images/health-specialists/p3.png";
import Specialist4 from "../../assets/images/health-specialists/p4.png";
import Specialist5 from "../../assets/images/health-specialists/p5.png";
import Specialist6 from "../../assets/images/health-specialists/p6.png";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Footer from "../../components/Footer/Footer";
import { Tab, Tabs, Box, ThemeProvider, createTheme } from "@material-ui/core";

// Tab, Tab, TabPane, ThemeProvider, createTheme
const Index = () => {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = createTheme({
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            // Default tab styles
            color: "#595959",
            "&.Mui-selected": {
              // Selected tab styles
              fontWeight: 600,
              outline: "none",
              color: "#8DBF4C",
              borderBottom: "2px solid #8DBF4C", // Replace with your desired background color
            },
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            // Custom TabPanel styles
            padding: "20px",
            backgroundColor: "#f5f5f5",
            color: "rgba(0, 0, 0, 0.8)",
          },
        },
      },
    },
  });

  // Custom TabPanel component
  const TabPanel = ({ value, index, children }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };
  const reviews = [
    {
      ppic: Graeme,
      caption:
        "Kim is great at breaking it all down into easy-to-understand concepts",
      stars: 5,
      customer: "Graeme Lamond",
      customerTitle: "Business owner",
      tab: "Health Facilitators – Kim Larking",
      review:
        "Exceptional communicator with broad-based well-being knowledge: “Kim has a great depth of knowledge in this field. The data and parameters used for testing and results are very comprehensive but Kim is great at breaking it all down into easy-to-understand concepts. Great recommendations on different foods to eat and supplements to take.”",
      date: "13/12/2022",
    },
    {
      ppic: Mari,
      caption: "The one-on-one engagement with Kim",
      stars: 5,
      customer: "Mari Gordon",
      tab: "Health Facilitators – Kim Larking",
      review:
        "He is very passionate about getting you well and the energy he brings to the table is infectious and inspiring. You learn something new every time. Yes, Kim is very passionate about well-being and I can tell he is always trying to get deeper and deeper into how we can all lead fulfilling, healthy lives. Kim, you are a big-hearted, genuine, passionate, person who really cares for people’s well-being. You look at the individual and put all your heart and soul to help people get to a better space.",
      date: "13/07/2023",
    },
    {
      ppic: GaryJ,
      caption: "If you have the will, they have the way",
      stars: 5,
      customer: "Gary Jamieson",
      customerTitle: "Insurance Brokers Limited",
      tab: "Health Facilitators – Kim Larking",
      review:
        "Exceptional communicator with broad-based well-being knowledge: “If you have the will, they (Kim) have the way.”",
      date: "13/12/2023",
    },
    {
      ppic: kimmcw,
      caption: "…inspiring and motivating",
      stars: 5,
      customer: "Kim McWilliam’s",
      customerTitle: "Director NZ PWN",
      tab: "Health Facilitators – Kim Larking",
      review:
        "An exceptional communicator with broad-based well-being knowledge - exceptional theory focus and each models behaviour and is inspiring and motivating.",
      date: "16/12/2022",
    },
    {
      ppic: IvanM,
      caption: "Delivering exceptional, well researched and presented material",
      stars: 5,
      customer: "Ivan Middlemost",
      customerTitle: "General accountant",
      tab: "Health Facilitators – Kim Larking",
      review:
        "Exceptional communicator with broad-based well-being knowledge: Delivering exceptional, well researched and presented material, month one I was lost but by month three I was focused; I understood each discussion; I was engaged.",
      date: "27/01/2023",
    },
    {
      ppic: Mike,
      caption: "Solid research",
      stars: 5,
      customer: "Mike Lough",
      customerTitle: "Business owner",
      tab: "Health Facilitators – Kim Larking",
      review:
        "Knowledge is key. Get the data and use that knowledge to your own personal gain.",
      date: "05/07/2023",
    },
  ];

  return (
    <div className="health_specialists">
      <Header />
      <div className="health_specialists_banner">
        <div className="main">
          <div className="main_content">
            <div className="main_content_head">
              <div className="main_content_head_border"></div>
              {`Health Facilitators`}
              <p>
                As the Sanctuary expands operations, we will be taking on a
                range of Health Professionals, all with unique skill sets. Our
                intention is to offer you a diverse skill base, which when
                combined together, offers the most comprehensive expertise in
                assisting you to greater health span.
              </p>
            </div>
            <div className="main_content_specialists">
              <div className="main_content_specialists_border"></div>
              <div className="main_content_specialists_title">
                Our Facilitators
              </div>
              <div className="main_content_specialists_list">
                <div
                  className="avatar"
                  style={{ backgroundImage: `url(${Specialist1})` }}
                ></div>
                <div
                  className="avatar"
                  style={{ backgroundImage: `url(${ph_ppbw})` }}
                ></div>
                <div
                  className="avatar"
                  style={{ backgroundImage: `url(${ph_ppbw})` }}
                ></div>
                <div
                  className="avatar"
                  style={{ backgroundImage: `url(${ph_ppbw})` }}
                ></div>
                <div
                  className="avatar"
                  style={{ backgroundImage: `url(${ph_ppbw})` }}
                ></div>
                <div className="more">
                  <a href="#facilitators">
                    {" "}
                    SEE OUR FACILITATORS
                    <img src={downArrow} alt="down" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tiles">
          <div className="tiles_item">
            <a href="#facilitators">
              <div
                className="tiles_item_bg"
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${tile1})`,
                }}
              >
                consultancy
              </div>
            </a>
            <p>
              Consult which one of our health facilitators or professionals to
              see how we can assist you
            </p>
          </div>
          <div className="tiles_item item2">
            <a href="#facilitators">
              <div
                className="tiles_item_bg"
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${tile2})`,
                }}
              >
                protocols
              </div>
            </a>
            <p>
              Allow one of our team to help guide you through a health protocol
              specific to your needs
            </p>
          </div>
        </div>
        <ClusterIcons />
      </div>
      <FiveMen />
      <div className="section1">
        <div className="section1_content">
          <p>
            <b>All</b>
          </p>
          <div className="section1_content_item">
            <img src={cluster1} alt="tag" />
            <p>Health</p>
          </div>
          <div className="section1_content_item">
            <img src={cluster2} alt="tag" />
            <p>Prosperity </p>
          </div>
          <div className="section1_content_item">
            <img src={cluster3} alt="tag" />
            <p>Inspiration</p>
          </div>
          <div className="section1_content_item">
            <img src={cluster4} alt="tag" />
            <p>Joy</p>
          </div>
        </div>
      </div>
      <div className="section_pills">
        <div className="section_pills_item">Bone and Cartilage</div>
        <div className="section_pills_item">Immune System </div>
        <div className="section_pills_item">Brain Health</div>
        <div className="section_pills_item">Heart Health</div>
        <div className="section_pills_item">Cellular Health</div>
        <div className="section_pills_item">Harmonious Relationship</div>
        <div className="section_pills_item">Holistic Vibrancy</div>
        <div className="section_pills_item">Prosperity</div>
        <div className="section_pills_item">Joyology</div>
        <div className="section_pills_item">Ant-Aging</div>
        <div className="section_pills_item">Holistic Advancement</div>
        <div className="section_pills_item">Sports Performance</div>
        <div className="section_pills_item">Business Excellence</div>
      </div>
      <div id="facilitators" className="specialists">
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 500: 1, 800: 2, 1400: 3 }}
        >
          <Masonry gutter="40px">
            <div className="specialists_card">
              <div
                className="specialists_card_avatar"
                style={{ backgroundImage: `url(${Specialist1})` }}
              />
              <div style={{ marginTop: "-50px" }}>
                <div className="specialists_card_head">
                  Kim larking
                  <div className="specialists_card_head_dots">
                    Human Advancement Architect
                    <div
                      className="dot"
                      style={{ backgroundColor: "green" }}
                    ></div>
                    <div
                      className="dot"
                      style={{ backgroundColor: "blue" }}
                    ></div>
                    <div
                      className="dot"
                      style={{ backgroundColor: "pink" }}
                    ></div>
                    <div
                      className="dot"
                      style={{ backgroundColor: "yellow" }}
                    ></div>
                  </div>
                </div>
                <ThemeProvider theme={theme}>
                  <Box
                    sx={{ borderBottom: 1, borderColor: "#8DBF4C" }}
                    display="flex"
                    justifyContent="center"
                  >
                    <Tabs value={value} onChange={handleChange}>
                      <Tab label="consultancy" />
                      <Tab label="protocols" />
                    </Tabs>
                  </Box>

                  <TabPanel value={value} index={0}>
                    <div className="specialists_card_details">
                      <p>
                        The imaginal cells within the caterpillar, intrinsic to
                        the miraculous transformation, which creates the
                        butterfly, are also within us. Our imaginal cells are
                        represented by the human mind, which holds the creation
                        powers of imagination, planning and self-determination.
                        <br />
                        <br />
                        As like the process of metamorphous, the mind harbours
                        the ability to transform our lives as we wish. By
                        understanding and governing the Mind-Body system, and
                        ultimately by amplifying the processors of the body
                        through the advanced application of the mind, we are
                        able to take hold of evolutions best kept secrets to
                        achieve greater holistic health span, and lock in our
                        own Health Evolution process by mastering a Self-
                        Advancing Life Style. In doing so we enable a higher
                        echelon of satisfaction and life success, while also
                        contributing to a better planet and humanity.
                        <br />
                        <br />
                        We are the architect of our own Self, and hence we can
                        define the person we wish to be, and with focus and
                        belief materialise our dreams. Be the architect of your
                        life and create an abundance of love, wealth, health and
                        happiness.
                      </p>
                      <span style={{ color: "black" }}>
                        <b>Training and Experience: </b>
                      </span>
                      <ul>
                        <li>Creator of Mind X Body Fusion Exercise</li>
                        <li>Author of the book Health Psychology</li>
                        <li>6th degree Kiaido Ryu black belt</li>
                        <li>Aikido and yoga enthusiast</li>
                        <li>Anti-aging and health span educator</li>
                      </ul>
                      <br />

                      <p>
                        <span style={{ color: "black" }}>
                          <b>Duration: </b>
                        </span>
                        30min / 45min / 1hr
                      </p>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <ul className="TabPanel-content">
                      <li>
                        <a
                          target="_blank"
                          href="https://sanctuarystore.co.nz/shop-by-health-interest"
                        >
                          Health protocols
                        </a>
                      </li>

                      <li>Wisdom and Success Prosperity Protocol</li>
                      <li>Harmonious relations Protocol</li>
                      <li>Holistic Human Vibrancy - Happiness Protocol</li>
                    </ul>
                  </TabPanel>
                   {/* </Box> */}
                </ThemeProvider>
                <div className="specialists_card_pills">
                  <div className="specialists_card_pills_pill">
                    <a href="mailto:kim@visionproducts.co.nz?subject=Health%20Facilitator%20-%20Enquire%20Now">
                      Enquire now
                    </a>
                  </div>
                  <div className="specialists_card_pills_pill">
                    GROUP COACHING
                  </div>
                </div>
              </div>
            </div>
            <div className="specialists_card">
              <div
                className="specialists_card_avatar"
                style={{ backgroundImage: `url(${ph_ppbw})` }}
              />
              <div style={{ marginTop: "-50px" }}>
                <div className="specialists_card_head">
                  Join our Team
                  <div className="specialists_card_head_dots">
                    Dietitian / Nutritionist
                    <div
                      className="dot"
                      style={{ backgroundColor: "green" }}
                    ></div>
                    <div
                      className="dot"
                      style={{ backgroundColor: "blue" }}
                    ></div>
                  </div>
                </div>
                <div className="specialists_card_tabs">
                  <li className="active">consultancy</li>
                  <li>protocols</li>
                </div>
                <div className="specialists_card_details">
                  <p>
                    Phasellus dignissim, tellus in pellentesque mollis, mauris
                    orci dignissim nisl, id gravida nunc enim quis nibh.
                    Maecenas convallis eros a ante dignissim, vitae elementum
                    metus facilisis. Cras in maximus sem. Praesent libero augue,
                    ornare eget quam sed, volutpat suscipit arcu. Duis ut urna
                    commodo, commodo tellus ac.
                  </p>
                  <span style={{ color: "black" }}>
                    <b>Training and Experience: </b>
                  </span>
                  <ul>
                    <li>Cras in maximus sem. </li>
                    <li>
                      Maecenas convallis eros a ante dignissim, vitae elementum
                      metus facilisis.
                    </li>
                    <li>Arcu. Duis ut urna commodo, commodo tellus ac.</li>
                    <li>Duis ut urna commodo, commodo tellus ac.</li>
                    <li>Gravida nunc enim quis nibh. </li>
                  </ul>
                  <br />
                  <p>
                    <span style={{ color: "black" }}>
                      <b>Duration: </b>
                    </span>
                    30min / 45min / 1hr
                  </p>
                </div>
                <div className="specialists_card_pills">
                  <div className="specialists_card_pills_pill"><a href="mailto:kim@visionproducts.co.nz?subject=Health%20Facilitator%20-%20Enquire%20Now">
                      Enquire now
                    </a></div>
                </div>
              </div>
            </div>
            <div className="specialists_card">
              <div
                className="specialists_card_avatar"
                style={{ backgroundImage: `url(${ph_ppbw})` }}
              />
              <div style={{ marginTop: "-50px" }}>
                <div className="specialists_card_head">
                  Join our Team
                  <div className="specialists_card_head_dots">
                    Chiropractic
                    <div
                      className="dot"
                      style={{ backgroundColor: "green" }}
                    ></div>
                  </div>
                </div>
                <div className="specialists_card_tabs">
                  <li className="active">consultancy</li>
                  <li>protocols</li>
                </div>
                <div className="specialists_card_details">
                  <p>
                    Phasellus dignissim, tellus in pellentesque mollis, mauris
                    orci dignissim nisl, id gravida nunc enim quis nibh.
                    Maecenas convallis eros a ante dignissim, vitae elementum
                    metus facilisis. Cras in maximus sem. Praesent libero augue,
                    ornare eget quam sed, volutpat suscipit arcu. Duis ut urna
                    commodo, commodo tellus ac.
                  </p>
                  <span style={{ color: "black" }}>
                    <b>Training and Experience: </b>
                  </span>
                  <ul>
                    <li>Cras in maximus sem. </li>
                    <li>
                      Maecenas convallis eros a ante dignissim, vitae elementum
                      metus facilisis.
                    </li>
                    <li>Arcu. Duis ut urna commodo, commodo tellus ac.</li>
                    <li>Duis ut urna commodo, commodo tellus ac.</li>
                    <li>Gravida nunc enim quis nibh. </li>
                  </ul>
                  <br />
                  <p>
                    <span style={{ color: "black" }}>
                      <b>Duration: </b>
                    </span>
                    30min / 45min / 1hr
                  </p>
                </div>
                <div className="specialists_card_pills">
                  <div className="specialists_card_pills_pill"><a href="mailto:kim@visionproducts.co.nz?subject=Health%20Facilitator%20-%20Enquire%20Now">
                      Enquire now
                    </a></div>
                </div>
              </div>
            </div>
            <div className="specialists_card">
              <div
                className="specialists_card_avatar"
                style={{ backgroundImage: `url(${ph_ppbw})` }}
              />
              <div style={{ marginTop: "-50px" }}>
                <div className="specialists_card_head">
                  Join our Team
                  <div className="specialists_card_head_dots">
                    Counsellor
                    <div
                      className="dot"
                      style={{ backgroundColor: "pink" }}
                    ></div>
                    <div
                      className="dot"
                      style={{ backgroundColor: "yellow" }}
                    ></div>
                  </div>
                </div>
                <div className="specialists_card_tabs">
                  <li className="active">consultancy</li>
                  <li>protocols</li>
                </div>
                <div className="specialists_card_details">
                  <p>
                    Phasellus dignissim, tellus in pellentesque mollis, mauris
                    orci dignissim nisl, id gravida nunc enim quis nibh.
                    Maecenas convallis eros a ante dignissim, vitae elementum
                    metus facilisis. Cras in maximus sem. Praesent libero augue,
                    ornare eget quam sed, volutpat suscipit arcu. Duis ut urna
                    commodo, commodo tellus ac.
                  </p>
                  <span style={{ color: "black" }}>
                    <b>Training and Experience: </b>
                  </span>
                  <ul>
                    <li>Cras in maximus sem. </li>
                    <li>
                      Maecenas convallis eros a ante dignissim, vitae elementum
                      metus facilisis.
                    </li>
                    <li>Arcu. Duis ut urna commodo, commodo tellus ac.</li>
                    <li>Duis ut urna commodo, commodo tellus ac.</li>
                    <li>Gravida nunc enim quis nibh. </li>
                  </ul>
                  <br />
                  <p>
                    <span style={{ color: "black" }}>
                      <b>Duration: </b>
                    </span>
                    30min / 45min / 1hr
                  </p>
                </div>
                <div className="specialists_card_pills">
                  <div className="specialists_card_pills_pill"><a href="mailto:kim@visionproducts.co.nz?subject=Health%20Facilitator%20-%20Enquire%20Now">
                      Enquire now
                    </a></div>
                </div>
              </div>
            </div>
            <div className="specialists_card">
              <div
                className="specialists_card_avatar"
                style={{ backgroundImage: `url(${ph_ppbw})` }}
              />
              <div style={{ marginTop: "-50px" }}>
                <div className="specialists_card_head">
                  Join our Team
                  <div className="specialists_card_head_dots">
                    Massage Therapist
                    <div
                      className="dot"
                      style={{ backgroundColor: "green" }}
                    ></div>
                    <div
                      className="dot"
                      style={{ backgroundColor: "blue" }}
                    ></div>
                  </div>
                </div>
                <div className="specialists_card_tabs">
                  <li className="active">consultancy</li>
                  <li>protocols</li>
                </div>
                <div className="specialists_card_details">
                  <p>
                    Phasellus dignissim, tellus in pellentesque mollis, mauris
                    orci dignissim nisl, id gravida nunc enim quis nibh.
                    Maecenas convallis eros a ante dignissim, vitae elementum
                    metus facilisis. Cras in maximus sem. Praesent libero augue,
                    ornare eget quam sed, volutpat suscipit arcu. Duis ut urna
                    commodo, commodo tellus ac.
                  </p>
                  <span style={{ color: "black" }}>
                    <b>Training and Experience: </b>
                  </span>
                  <ul>
                    <li>Cras in maximus sem. </li>
                    <li>
                      Maecenas convallis eros a ante dignissim, vitae elementum
                      metus facilisis.
                    </li>
                    <li>Arcu. Duis ut urna commodo, commodo tellus ac.</li>
                    <li>Duis ut urna commodo, commodo tellus ac.</li>
                    <li>Gravida nunc enim quis nibh. </li>
                  </ul>
                  <br />
                  <p>
                    <span style={{ color: "black" }}>
                      <b>Duration: </b>
                    </span>
                    30min / 45min / 1hr
                  </p>
                </div>
                <div className="specialists_card_pills">
                  <div className="specialists_card_pills_pill"><a href="mailto:kim@visionproducts.co.nz?subject=Health%20Facilitator%20-%20Enquire%20Now">
                      Enquire now
                    </a></div>
                </div>
              </div>
            </div>
            <div className="specialists_card">
              <div
                className="specialists_card_avatar"
                style={{ backgroundImage: `url(${ph_ppbw})` }}
              />
              <div style={{ marginTop: "-50px" }}>
                <div className="specialists_card_head">
                  Join our Team
                  <div className="specialists_card_head_dots">
                    Integrative / Functional Medicine Doctor
                    <div
                      className="dot"
                      style={{ backgroundColor: "green" }}
                    ></div>
                  </div>
                </div>
                <div className="specialists_card_tabs">
                  <li className="active">consultancy</li>
                  <li>protocols</li>
                </div>
                <div className="specialists_card_details">
                  <p>
                    Phasellus dignissim, tellus in pellentesque mollis, mauris
                    orci dignissim nisl, id gravida nunc enim quis nibh.
                    Maecenas convallis eros a ante dignissim, vitae elementum
                    metus facilisis. Cras in maximus sem. Praesent libero augue,
                    ornare eget quam sed, volutpat suscipit arcu. Duis ut urna
                    commodo, commodo tellus ac.
                  </p>
                  <span style={{ color: "black" }}>
                    <b>Training and Experience: </b>
                  </span>
                  <ul>
                    <li>Cras in maximus sem. </li>
                    <li>
                      Maecenas convallis eros a ante dignissim, vitae elementum
                      metus facilisis.
                    </li>
                    <li>Arcu. Duis ut urna commodo, commodo tellus ac.</li>
                    <li>Duis ut urna commodo, commodo tellus ac.</li>
                    <li>Gravida nunc enim quis nibh. </li>
                  </ul>
                  <br />
                  <p>
                    <span style={{ color: "black" }}>
                      <b>Duration: </b>
                    </span>
                    30min / 45min / 1hr
                  </p>
                </div>
                <div className="specialists_card_pills">
                  <div className="specialists_card_pills_pill"><a href="mailto:kim@visionproducts.co.nz?subject=Health%20Facilitator%20-%20Enquire%20Now">
                      Enquire now
                    </a></div>
                </div>
              </div>
            </div>
          </Masonry>
        </ResponsiveMasonry>
      </div>
      <div className="customers">
        <div className="customers_content">
          <div className="customers_content_star">
            <div className="customers_content_star_title">
              <img alt="not found" src={Star} title="WRITE REVIEWS" />
              <div className="customers_content_head">
                <h1>Customer Reviews</h1>
              </div>
            </div>
          </div>

          <CustomerReviews reviews={reviews} />
        </div>
      </div>
      <OptimiseReport showHead />
      <SanctuaryServices />
      <Disclaimer />
      <FooterBanner />
      <Footer />
      {/* <Modal fullscreen="lg-down" show={show} onHide={modalClose}>  
            <Modal.Header>  
                <Modal.Title>Reviews</Modal.Title>  
                <CancelRoundedIcon onClick={() => modalClose()}/>
            </Modal.Header>  
                <Modal.Body>
                    <CustomerReviews  reviews={reviews}/>
                </Modal.Body>  
            </Modal> */}
    </div>
  );
};

export default Index;
