import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer';
import FooterBanner from '../../components/footer-banner';
import AppLogo1 from '../../assets/images/vision/app-logo1.png'
import AppLogo2 from '../../assets/images/vision/app-logo2.png'
import AppLogo3 from '../../assets/images/vision/app-logo3.png'
import AppLogo4 from '../../assets/images/vision/app-logo4.png'
import AppLogo5 from '../../assets/images/vision/app-logo5.png'
import AppLogo6 from '../../assets/images/vision/app-logo6.png'
import AppMetaWheel1 from '../../assets/images/vision/metawheel-logo1.png'
import AppMetaWheel2 from '../../assets/images/vision/metawheel-logo2.png'
import Edit from '../../assets/images/vision/edit.png'
import "./index.scss";

const VisionarieProfile = () => {
  return (
    <div className="visionarie_profile page-w">
      <Header />
      <div className='visionarie_profile_banner'>
        <div className='visionarie_profile_banner_content'>
          <div className="visionarie_profile_banner_content_cont">
            <h2>Jane Adams</h2>
            <p>I am so grateful and pleased now that I am in a truly rewarding role helping others while becoming more prosperous through Right Livelihood.</p>
          </div>
        </div>
        <div className="visionarie_profile_banner_content_rightImg">
          <div className="app-sec">
            <h2>VFH sanctuaries</h2>
            <div className="app-con-sec">
              <div className="app-cont">
                <img alt="not found" src={AppLogo1} />
                <p>Sanctuary<br />
                  Store</p>
              </div>

              <div className="app-cont">
                <img alt="not found" src={AppLogo2} />
                <p>Urban<br />
                  Sanctuary</p>
              </div>
              <div className="app-cont">
                <img alt="not found" src={AppLogo3} />
                <p>Master<br />
                  Sanctuary</p>
              </div>
            </div>

          </div>
          <div className="app-sec">
            <h2>apps</h2>
            <div className="app-con-sec">
              <div className="app-cont">
                <img alt="not found" src={AppMetaWheel1} />
                <p>Meta
                  <br />
                  wheel</p>
              </div>

              <div className="app-cont">
                <img alt="not found" src={AppMetaWheel2} />
                <p>Health
                  <br />
                  Creator</p>
              </div>
              <div className="app-cont">
                <img alt="not found" src={AppMetaWheel2} />
                <p>Weight
                  <br />
                  Shifter</p>
              </div>
            </div>

          </div>
          <div className="app-sec">
            <h2>Networks</h2>
            <div className="app-con-sec">
              <div className="app-cont">
                <img alt="not found" src={AppLogo4} />
                <p>Vision For
                  <br />
                  Humanity</p>
              </div>

              <div className="app-cont">
                <img alt="not found" src={AppLogo5} />
                <p>Biospherically
                  <br />
                  Correct</p>
              </div>
              <div className="app-cont">
                <img alt="not found" src={AppLogo6} />
                <p>Vision
                  <br />
                  Products</p>
              </div>
            </div>
            <button>Screen saver</button>
          </div>
        </div>
        <div className="edit-sec">
        <p>personalize your page <img alt="not found" src={Edit} /></p>
        </div>
      </div>

      <FooterBanner />
      <Footer />
    </div>
  );
};
export default VisionarieProfile;
