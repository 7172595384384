import React from "react";
import { Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import { useLocation, useHistory } from 'react-router-dom';
import barrow from "../../assets/images/b-arrow.png";
import slide1 from "../../assets/images/home-img/acro-acro-pose-acro-yoga.png";
import Path from "../../assets/images/home-img/Path.png";
import BannerHome2 from "../../assets/images/home-img/Banner-home2.png";
import ClusterIcons from '../cluster-icons';
import './HomeSlider.scss';
import { Box, Button, makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  btnRoot: {
    [theme.breakpoints.down(431)]: {
      fontSize: '11px',
      padding: "4px 4px 4px 4px"
    },
  },
  text: {
    [theme.breakpoints.down(431)]: {
      whiteSpace: 'normal !important',
      fontSize: '14px !important'
    },
    whiteSpace: 'break-spaces !important'
  },
}));

const HomeSlider = ({ slide }) => {
  const history = useHistory();
  const classes = useStyles();

  const { pathname } = useLocation();
  const dbLinks = (link) => {
    history.push(link)
  }
  const handleDotClick = (dot) => {
    if (dot === 0) {
      window.location.href = 'https://sanctuarystore.co.nz/'
    }
    else if ((dot === 2) && pathname !== '/master-sanctuary') {
      dbLinks('/master-sanctuary')
    }
    else if ((dot === 1) && pathname === '/master-sanctuary') {
      dbLinks('/home')
    }

  }

  const textDot = ['SANCTUARY STORE', 'URBAN SANCTUARY', 'MASTER SANCTUARY'];
  var settings = {
    dots: true,
    infinite: true,
    currentSlide: slide,
    speed: 500,
    slidesToShow: 1,
    dotsClass: "slick-dots slick-thumb",
    slidesToScroll: 1,
    maxWidth: 50,
    customPaging: function (i) {
      return (
        <a href="#!">
          <div className="tb-info" >
            <div className={`dotbtns dot-tex${i + 1}`}>
              <span className={`hide-text dot-text${i + 1}`}>{textDot[i]}</span>
              <input type="radio" name={i} checked={i + 1} onChange={(e) => handleDotClick(i)} />
            </div>
            {/* <img alt="not found" src={`${'Cluster-'}${i + 1}.png`} /> */}
          </div>
        </a>
      );
    },
  };

  return (
    <>
      {(pathname === '/home') || (pathname === '/') ? (
        <Row>
          <ClusterIcons active />
          <Col sm={12} md={12}>
            <Slider {...settings}>
              <div className="slider-conatiner">
                <div className=" slider-txt">

                  <h1 className="large-txt">
                    URBAN SANCTUARIES FOR CONSCIOUS CREATURES
                  </h1>
                  <h1 className="italic-txt">Discover your Magnificence</h1>
                  <p>
                    Human Well-being & Advancement Centre.
                    Develop your full Life Force, Holistic Human Vibrancy,
                    Significance and Purpose.
                  </p>

                </div>
                <img alt="not found" className="banner-img" src={slide1} />
              </div>
              <div className="slider-conatiner">
                <div className=" slider-txt">

                  <h1 className="large-txt">
                    URBAN SANCTUARIES FOR {"\n"} CONSCIOUS CREATURES
                  </h1>
                  <h1 className="italic-txt">Discover your Magnificence</h1>
                  <p className={classes.text}>
                    Human Well-being & Advancement Centre. {"\n"}
                    Develop your full Life Force, Holistic Human Vibrancy, {"\n"}
                    Significance and Purpose.
                  </p>
                  <Box sx={{ display: "flex", gap: 10, justifyContent: "center", marginTop: 10, fontSize: { sm: "10px !important" } }} >
                    <Button className={classes.btnRoot} onClick={() => { history.push("/guide-landing-page") }} variant="contained">Health Psychology Book</Button>
                    <Button className={classes.btnRoot} onClick={() => { history.push("/package-health") }} variant="contained">Health Directive Program</Button>
                  </Box>
                </div>
                <img alt="not found" className="banner-img" src={slide1} />
              </div>
              <div className="slider-conatiner">
                <div className=" slider-txt">

                  <h1 className="large-txt">
                    URBAN SANCTUARIES FOR<br></br>CONSCIOUS CREATURES
                  </h1>
                  <h1 className="italic-txt">Discover your Magnificence</h1>
                  <p>Human Well-being & Advancement Centre.</p>
                  <p>
                    Develop your full Life Force, Holistic Human Vibrancy,
                    Significance<br /> and Purpose.
                  </p>

                </div>
                <img alt="not found" className="banner-img" src={slide1} />
              </div>
            </Slider>
          </Col>
        </Row>
      ) : (
        <Row>
          <ClusterIcons active />
          <Col sm={12} md={12}>
            <Slider {...settings}>
              <div className="slider-conatiner">
                <div className=" slider-txt">
                  <h1 className="slider-lrg-txt">Our Long Term Plan</h1>
                  <p className="slider-small-txt">
                    Biospherically Correct services for our Sanctuaries -
                    organic food
                    <br /> production, organic waste recycling and composting /
                    rewilding &<br /> reforestation{" "}
                  </p>
                  <h1 className="slider-middle-txt">
                    MASTER RETREAT &<br /> CONSCIOUS LIFESTYLE EXPERIENCE
                  </h1>
                  <p className="slider-desc-txt">
                    Experience Tranquility, Permaculture food production,
                    <br />
                    and a Mejestic natural lifestyle of Self Sufficiency
                  </p>
                </div>
                <img alt="not found" className="banner-img" src={BannerHome2} />
              </div>
              <div className="slider-conatiner">
                <div className=" slider-txt">
                  <h1 className="slider-lrg-txt">Our Long Term Plan</h1>
                  <p className="slider-small-txt">
                    Biospherically Correct services for our Sanctuaries -
                    organic food
                    <br /> production, organic waste recycling and composting /
                    rewilding &<br /> reforestation{" "}
                  </p>
                  <h1 className="slider-middle-txt">
                    MASTER RETREAT &<br /> CONSCIOUS LIFESTYLE EXPERIENCE
                  </h1>
                  <p className="slider-desc-txt">
                    Experience Tranquility, Permaculture food production,
                    <br />
                    and a Mejestic natural lifestyle of Self Sufficiency
                  </p>
                </div>
                <img alt="not found" className="banner-img" src={BannerHome2} />
              </div>
              <div className="slider-conatiner">
                <div className=" slider-txt">
                  <h1 className="slider-lrg-txt">Our Long Term Plan</h1>
                  <p className="slider-small-txt">
                    Biospherically Correct services for our Sanctuaries -
                    organic food
                    <br /> production, organic waste recycling and composting /
                    rewilding &<br /> reforestation{" "}
                  </p>
                  <h1 className="slider-middle-txt">
                    MASTER RETREAT &<br /> CONSCIOUS LIFESTYLE EXPERIENCE
                  </h1>
                  <p className="slider-desc-txt">
                    Experience Tranquility, Permaculture food production,
                    <br />
                    and a Mejestic natural lifestyle of Self Sufficiency
                  </p>
                </div>
                <img alt="not found" className="banner-img" src={BannerHome2} />
              </div>

            </Slider>
          </Col>
        </Row>
      )}
    </>
  );
}

export default HomeSlider;