import Register from "../views/Register/Register";
import Home from "../views/Home/Home";
import AdvanceExercises from "../views/AdvanceExercises/AdvanceExercises";
import BookingPlan from "../views/BookingPlan/BookingPlan";
import FusionExercise from "../views/FusionExerciseAir/FusionExerciseAir";
import FusionExerciseAirDetail from "../views/FusionExerciseAirDetail/FusionExerciseAirDetail";
import Home2 from "../views/Home2/Home2";
import StoreSkinAnalysis from "../views/StoreSkinAnalysis/StoreSkinAnalysis";
import StoreInbodyScanning from "../views/StoreInbodyScanning/StoreInbodyScanning";
import AboutUs from "../views/about-us";
import TechHairTesting from "../views/tech-hair-testing";
import HolisticSystem from "../views/holistic-system";
import HealthSpecialists from "../views/health-specialists";
import ServiceHealth from "../views/service-health";
import Packages from "../views/packages";
import PackageHealth from "../views/package-health";
import GuideLandingPage from "../views/guide-landing-page";
import FusionExerciseWaterDetail from "../views/fusion-exercise-water";
import FusionExerciseStretchingDetail from "../views/fusion-exercise-stretching";
import FusionExerciseCalisthenicsDetail from "../views/fusion-exercise-calisthenics";
import FusionExerciseHiitDetail from "../views/fusion-exercise-hiit";
import FusionExerciseIsometericDetail from "../views/fusion-exercise-isometric";
import HomeWorkouts from "../views/home-workouts";
import ExercisesHealthMinds from "../views/ExercisesHealthMinds";
import FusionExerciseDetail1 from "../views/fusion-exercise-detail1";
import FusionExerciseDetail2 from "../views/fusion-exercise-detail2";
import FusionExerciseDetail3 from "../views/fusion-exercise-detail3";
import FusionExerciseDetail4 from "../views/fusion-exercise-detail4";
import FusionExerciseDetail5 from "../views/fusion-exercise-detail5";
import FusionExerciseDetail6 from "../views/fusion-exercise-detail6";
import FusionExerciseDetail7 from "../views/fusion-exercise-detail7";
import Registration from "../views/Registration";
import Registration2 from "../views/Registration2";
import Registration3 from "../views/Registration3";
import VisionProducts from "../views/VisionProducts";
import VisionarieProfile from "../views/VisionarieProfile";
import Account from "../views/Account";
import bannerImg from '~/assets/images/page/account/banner.png';
// import Account1 from '../views/Account1';
// import Account2 from '../views/Account2';
// import Account3 from '../views/Account3';
// import Account4 from '../views/Account4';
// import Account5 from '../views/Account5';
import AdvanceExercisesGuide from "../views/AdvanceExercisesGuide/AdvanceExercisesGuide";
import Discover from "../views/Discover";
import DiscoverMetawheel from "../views/DiscoverMetawheel";
export const RouteLinks = {
  RootHome: {
    component: Home,
    path: "/",
  },
  Home: {
    component: Home,
    path: "/home",
  },
  Register: {
    component: Register,
    path: "/get-started",
  },
  BookingPlan: {
    component: BookingPlan,
    path: "/booking-plans",
  },
  Home2: {
    component: Home2,
    path: "/master-sanctuary",
  },
  StoreSkinAnalysis: {
    component: StoreSkinAnalysis,
    path: "/store-service-skin-analysis",
  },
  StoreInbodyScanning: {
    component: StoreInbodyScanning,
    path: "/store-service-inbody-scanning",
  },
  AboutUs: {
    component: AboutUs,
    path: "/about-us",
  },
  TechHairTesting: {
    component: TechHairTesting,
    path: "/tech-hair-testing",
  },
  HolisticSystem: {
    component: HolisticSystem,
    path: "/holistic-system",
  },
  HealthSpecialists: {
    component: HealthSpecialists,
    path: "/health-facilitators",
  },
  ServiceHealth: {
    component: ServiceHealth,
    path: "/service-health",
  },
  Packages: {
    component: Packages,
    path: "/packages",
  },
  PackageHealth: {
    component: PackageHealth,
    path: "/package-health",
  },
  GuideLandingPage: {
    component: GuideLandingPage,
    path: "/guide-landing-page",
  },
  AdvanceExercises: {
    component: AdvanceExercises,
    path: "/advance-Exercises",
  },
  FusionExerciseAir: {
    component: FusionExercise,
    path: "/fusion-exercise",
  },
  FusionExerciseAirDetail: {
    component: FusionExerciseAirDetail,
    path: "/exercise-air-detail",
  },
  FusionExerciseWaterDetail: {
    component: FusionExerciseWaterDetail,
    path: "/exercise-water-detail",
  },
  FusionExerciseStretchingDetail: {
    component: FusionExerciseStretchingDetail,
    path: "/home-workouts/:id",
  },
  // FusionExerciseCalisthenicsDetail: {
  //   component: FusionExerciseCalisthenicsDetail,
  //   path: "/exercise-calisthenics-detail",
  // },
  // FusionExerciseHiitDetail: {
  //   component: FusionExerciseHiitDetail,
  //   path: "/exercise-hiit-detail",
  // },
  // FusionExerciseIsometericDetail: {
  //   component: FusionExerciseIsometericDetail,
  //   path: "/home-workouts/isometrics",
  // },
  HomeWorkouts: {
    component: HomeWorkouts,
    path: "/home-workouts",
  },
  ExercisesHealthMinds: {
    component: ExercisesHealthMinds,
    path: "/exercises-health-mind",
  },
  FusionExerciseDetail1: {
    component: FusionExerciseDetail1,
    path: "/fusion-exercise-detail1",
  },
  FusionExerciseDetail2: {
    component: FusionExerciseDetail2,
    path: "/fusion-exercise-detail2",
  },
  FusionExerciseDetail3: {
    component: FusionExerciseDetail3,
    path: "/fusion-exercise-detail3",
  },
  FusionExerciseDetail4: {
    component: FusionExerciseDetail4,
    path: "/fusion-exercise-detail4",
  },
  FusionExerciseDetail5: {
    component: FusionExerciseDetail5,
    path: "/fusion-exercise-detail5",
  },
  FusionExerciseDetail6: {
    component: FusionExerciseDetail6,
    path: "/fusion-exercise-detail6",
  },
  FusionExerciseDetail7: {
    component: FusionExerciseDetail7,
    path: "/fusion-exercise-detail7",
  },
  Registration: {
    component: Registration,
    path: "/registration",
  },
  Registration2: {
    component: Registration2,
    path: "/registration2",
  },
  Registration3: {
    component: Registration3,
    path: "/registration3",
  },
  Account: {
    name: "Account",
    path: "/account",
    component: Account,
    head: false,
    footer: false,
    side: false,
    notloggedinRedirect: true,
    breadcrumbs: bannerImg,
  },
  // Account1: {
  //   component: Account1, path: '/account1',
  // },
  // Account2: {
  //   component: Account2, path: '/account2',
  // },
  // Account3: {
  //   component: Account3, path: '/account3',
  // },
  // Account4: {
  //   component: Account4, path: '/account4',
  // },
  // Account5: {
  //   component: Account5, path: '/account5',
  // },
  AdvanceExercisesGuide: {
    component: AdvanceExercisesGuide,
    path: "/advance-exercises-guide",
  },
  Discover: {
    component: Discover,
    path: "/discover",
  },
  DiscoverMetawheel: {
    component: DiscoverMetawheel,
    path: "/discover-metawheel",
  },
  VisionProducts: {
    component: VisionProducts,
    path: "/vision-products",
  },
  VisionarieProfile: {
    component: VisionarieProfile,
    path: "/visionarie-profile",
  },
};

export const sidemenuRoute = [
  {
    path: "/",
    name: "Homepage",
    component: Home,
  },
  {
    name: "About us",
    head: true,
    footer: true,
    side: true,
    component: AboutUs,
    path: "/about-us",
  },
  {
    name: "Discover",
    component: Discover,
    head: true,
    footer: true,
    side: true,
    path: "/discover",
  },

  {
    name: "Services",
    head: true,
    footer: true,
    side: true,
    noPath: true,
    path: "/service-health",
    submenu: [
      {
        name: "Shop by Products & Services",

        head: false,
        footer: false,
        side: true,
        noPath: true,
        submenu: [
          {
            name: "Lifeforce Food",

            head: false,
            side: true,
            url: "https://sanctuarystore.co.nz/shop-by-product-and-services/raw-organic-ingredients",
          },
          {
            name: "Vision Products",

            head: false,
            side: true,
            url: "https://visionproductsdirect.com/",
          },
          {
            name: "Holistic Health Testing",

            head: false,
            side: true,
            path: "/holistic-system",
          },
          {
            name: "Advancement Exercises",

            head: false,
            side: true,
            path: "/advance-Exercises",
          },
          {
            name: "Advanced Learning",

            head: false,
            side: true,
            path: "/advance-exercises-guide",
          },
          {
            name: "Health Facilitators",

            head: false,
            side: true,
            path: "/health-facilitators",
          },
        ],
      },
      {
        name: "Shop by Advancement Interest",

        head: false,
        footer: false,
        side: true,
        noPath: true,
        submenu: [
          {
            name: "Health",

            head: false,
            side: true,
            noPath: true,
            submenu: [
              {
                name: "Guides",

                head: false,
                side: true,
                path: "/advance-exercises-guide",
              },
              {
                name: "Workshops",

                head: false,
                side: true,
                path: "/advance-exercises-guide",
              },
              {
                name: "Courses",

                head: false,
                side: true,
                path: "/advance-exercises-guide",
              },
              {
                name: "Advancement Exercises",

                head: false,
                side: true,
                path: "/advance-Exercises",
              },
              {
                name: "LIFE FORCE FOOD",

                head: false,
                side: true,
                url: "https://sanctuarystore.co.nz/shop-by-product-and-services/raw-organic-ingredients",
              },
              {
                name: "Natural Highs Bar",

                head: false,
                side: true,
              },

              {
                name: "Vision Products",

                head: false,
                side: true,
                url: "https://visionproductsdirect.com/",
              },
              {
                name: "Spa & Salon",

                head: false,
                side: true,
              },
              {
                name: "Rooftop Health Spa",

                head: false,
                side: true,
              },
              {
                name: "Health Protocols",

                head: false,
                side: true,
                url: "https://sanctuarystore.co.nz/shop-by-health-interest/health-protocols",
              },
              {
                name: "Health Facilitators",

                head: false,
                side: true,
                path: "/health-facilitators",
              },
              {
                name: "Health Creator App",

                head: false,
                side: true,
              },
              {
                name: "Weight Shifter App",

                head: false,
                side: true,
              },
              {
                name: "Health Packages",

                head: false,
                side: true,
                path: "/package-health",
              },
            ],
          },
          {
            name: "Prosperity",

            head: false,
            side: true,
          },
          {
            name: "Inspiration",

            head: false,
            side: true,
          },
          {
            name: "Joy",

            head: false,
            side: true,
          },
        ],
      },
    ],
  },
  {
    name: "Participate",
    head: true,
    footer: true,
    side: true,
    loggedinRedirect: true,
    url: "https://sanctuarystore.co.nz/participate",
  },
  {
    name: "Prosper",
    head: true,
    footer: true,
    side: true,
    loggedinRedirect: true,
    url: "https://sanctuarystore.co.nz/prosper",
  },
  {
    name: "Get Started",
    component: Register,
    head: true,
    footer: true,
    side: true,
    loggedinRedirect: true,
    path: "/get-started",
  },
];
