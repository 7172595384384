import React, { useState, useEffect } from "react";
import { useHistory, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import HiitTab from '../../assets/images/third-set/hiit-tile.png';
import HiitBg from '../../assets/images/third-set/hiit-bg.png';
import IsoTab from '../../assets/images/third-set/isometrics-tile.png';
import IsoBg from '../../assets/images/third-set/iso-bg.png';
import CalisTab from '../../assets/images/third-set/calis-tile.png';
import Calisthenics from '../../assets/images/third-set/Calisthenics.png';
import StretchTab from '../../assets/images/third-set/stretch-tile.png';
import Stretching from '../../assets/images/third-set/stretching.png';
import checkIcon from '../../assets/images/check-icon.png';
import smalPlayIcon from '../../assets/images/smal-play-icon.png';
import mediumPlayIcon from '../../assets/images/medium-play-icon.png';
import HomeBg from '../../assets/images/third-set/home-workout-bg.png';
import './index.scss';

const HomeWorkoutTabs = () => {
    const [showTabs, tabChange] = useState(0);
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    const bookHandler = () => {
        return history.push('/booking-plans')
    }
    const dbLinks = (link) => {
        history.push(link)
    }

    return (
        <>
            <Row>
                <Col sm={12} md={12}>
                    <div className="fusion-exe">
                        <h2>Home workouts series</h2>
                    </div>
                </Col>
                <div className="fusion-gal">
                    <Row>
                        <Col xs={3} sm={3} md={3}>
                            <div className="fusion-exe-gl" onClick={() => tabChange(1)}>
                                <img alt="not found" onClick={() => dbLinks('/home-workouts/stretching')} src={StretchTab} />
                                <h5>stretching</h5>
                                <div className={`${showTabs === 1 ? 'fu-active-gal' : ''}`}>
                                    <div className ="triangle-wrapper-100">
                                        <div className ="triangle-left"></div>
                                        <div className ="triangle-right"></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} sm={3} md={3} onClick={() => tabChange(2)}>
                            <div className="fusion-exe-gl">
                                <img alt="not found" onClick={() => dbLinks('/home-workouts/calisthenics')}  src={CalisTab} />
                                <h5>Calisthenics</h5>
                                <div className={`${showTabs === 2 ? 'fu-active-gal' : ''}`}>
                                    <div className ="triangle-wrapper-100">
                                        <div className ="triangle-left"></div>
                                        <div className ="triangle-right"></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} sm={3} md={3} onClick={() => tabChange(3)}>
                            <div className="fusion-exe-gl">
                                <img alt="not found" onClick={() => dbLinks('/home-workouts/isometrics')} src={IsoTab} />
                                <h5>isometrics</h5>
                                <div className={`${showTabs === 3 ? 'fu-active-gal' : ''}`}>
                                    <div className ="triangle-wrapper-100">
                                        <div className ="triangle-left"></div>
                                        <div className ="triangle-right"></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} sm={3} md={3}>
                            <div className="fusion-exe-gl" onClick={() => tabChange(4)}>
                                <img alt="not found" onClick={() => dbLinks('/home-workouts/hiit')} src={HiitTab} />
                                <h5>Hiit</h5>
                                <div className={`${showTabs === 4 ? 'fu-active-gal' : ''}`}>
                                    <div className ="triangle-wrapper-100">
                                        <div className ="triangle-left"></div>
                                        <div className ="triangle-right"></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={`benefits-container ${showTabs === 0 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1 style={{ paddingLeft: '20px' }}>Home workout BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>Become your own trainer over time by learning the skills you need to work out effectively at home, the office, or the park. This home workout series is highly versatile and designed to be done anywhere, anytime, with quick and simple to follow exercises consisting of manageable segments you can fit in anywhere you go.</p>
                            <ul>
                                <li> <img alt="not found" src={checkIcon} />Improve flexibility and range of motion to assist any sporting activity and general participation in life, and reduce the chance of muscle or ligament injury</li>
                                <li> <img alt="not found" src={checkIcon} />Improve all round – full body strength, body tone, definition and sculpting</li>
                                <li> <img alt="not found" src={checkIcon} />Improve cardiovascular and respiratory health through gradually intensifying aerobic fitness</li>
                                <li> <img alt="not found" src={checkIcon} />Gain greater explosive strength, and improve your power to weight ratio, while maintaining flexibility and speed</li>
                                <li> <img alt="not found" src={checkIcon} />Improve heart rate recovery, metabolic flexibility, reduce excess weight, and most importantly visceral fat</li>
                                <li> <img alt="not found" src={checkIcon} />Look and feel dynamic and vibrant - to enjoy like to the full!</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${HomeBg})`}}>
                    <div className="fu-dis">
                                        <div className="fu-head">
                                            <h2>Home workout BENEFITS</h2>
                                        </div>
                                        <div className="fu-dis-con" style={{ paddingBottom: '50px' }}>
                                            <div className="fu-dis-txt">

                                                <h4>Description: </h4>

                                                <p><b>Work out with Kim and get the push you need to gain the results you are looking for. Progressive intensity classes that anyone can do, and build upon. The key is habitually working out every day, no matter how brief or demanding – do it with this easy to follow series. </b></p>
                                            </div>
                                        </div>
                                    </div>
                    </div>
                </div>
                <div className={`benefits-container ${showTabs === 1 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1>Stretching BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>Stretching is vital to life in general. To maintain motion and activity all the years of your life – all the way through to old age – you need to flex! Stretching done properly feels great -during and especially afterwards.</p>
                            <ul>
                                <li> <img alt="not found" src={checkIcon} />Improve flexibility and range of motion to assist any sporting activity and participation in life in general</li>
                                <li> <img alt="not found" src={checkIcon} />Reduce the chance of muscle, joint or ligament injury by properly warming up and cooling down with the right stretches</li>
                                <li> <img alt="not found" src={checkIcon} />Aid your aerobic or resistance routines with a comprehensive stretching regimen</li>
                                <li> <img alt="not found" src={checkIcon} />Gain an edge to greater explosive strength, improving your power to weight ratio, and building speed and reaction time</li>
                                <li> <img alt="not found" src={checkIcon} />Improve vagal tone by activating the sympathetic nervous system, through certain stretches, for greater stress management and relaxation</li>
                                <li> <img alt="not found" src={checkIcon} />Feel great, and enjoy that tingle through your body that stretching provides, by simply engaging in a regular stretching routine to enjoy life more</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${Stretching})`}}>
                    <div className="fu-dis">
                                        <div className="fu-head">
                                            <h2>Stretching</h2>
                                        </div>
                                        <div className="fu-dis-con">
                                            <div className="fu-dis-txt">

                                                <h4>Description: </h4>

                                                <p>Use these dynamic and static stretching exercises by themselves as an enjoyable flexibility session, to warm up prior to your main exercise, and to properly warm down and improve flexibility after exercise - releasing lactic acid from muscles and joints.</p>
                                            </div>
                                            <div className="fu-dis-time">
                                                <p><span>DURATION</span>：10mins / 20mins</p>
                                                <p><span>MASTER</span>：Patricia Hart</p>
                                            </div>
                                            <div className="tab-btn">
                                                <button>PREVIEW<img alt="not found" src={smalPlayIcon} /></button>
                                                <button onClick={() => history.push('/exercise-stretching-detail')}>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                                <button onClick={bookHandler}>BOOK THIS</button>
                                            </div>
                                        </div>
                                    </div>
                    </div>
                </div>
                <div className={`benefits-container ${showTabs === 2 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1>Calisthenics BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>Use your own body weight to increase your ‘power to weight ratio’- the universal measure of health, fitness and anti-aging. No matter what body frame you are – ectomorph (smaller frame), mesomorph (medium frame), or endomorph (larger frame) callisthenics provide the idea resistance workout.</p>
                            <ul>
                                            <li style={{ marginTop: '10px', marginBottom: '20px' }}> <img alt="not found" src={checkIcon} />Full upper and lower body exercises to target all muscles groups</li>
                                            <li style={{ marginBottom: '20px' }}> <img alt="not found" src={checkIcon} />Improve all round – full body strength, body tone, definition and sculpting</li>
                                            <li style={{ marginBottom: '20px' }}> <img alt="not found" src={checkIcon} />Improve cardiovascular and respiratory health through gradually intensifying reaps</li>
                                            <li style={{ marginBottom: '20px' }}> <img alt="not found" src={checkIcon} />Gain greater explosive strength, and improve your power to weight ratio, while maintaining flexibility and speed by not over bulking.</li>
                                            <li style={{ marginBottom: '20px' }}> <img alt="not found" src={checkIcon} />Improve heart rate recovery, metabolic flexibility, reduce excess weight, and most importantly visceral fat</li>
                                            <li style={{ marginBottom: '20px' }}> <img alt="not found" src={checkIcon} />Look and feel dynamic and vibrant to enjoy life to the full</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${Calisthenics})`}}>
                    <div className="fu-dis">
                                        <div className="fu-head">
                                            <h2>Calisthenics</h2>
                                        </div>
                                        <div className="fu-dis-con">
                                            <div className="fu-dis-txt">

                                                <h4>Description: </h4>

                                                <p>Use these callisthenics exercises to improve all round fitness and strength, decrease body fat, tone up and feel more invigorated. Make sure to warm up first and stretch post workout.</p>
                                            </div>
                                            <div className="fu-dis-time">
                                                <p><span>DURATION</span>：10mins / 20mins</p>
                                                <p><span>MASTER</span>：Patricia Hart</p>
                                            </div>
                                            <div className="tab-btn">
                                                <button>PREVIEW<img alt="not found" src={smalPlayIcon} /></button>
                                                <button onClick={() => history.push('/home-workouts/calisthenics')}>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                                <button onClick={bookHandler}>BOOK THIS</button>
                                            </div>
                                        </div>
                                    </div>
                    </div>
                </div>
                <div className={`benefits-container ${showTabs === 3 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1>Isometrics BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>Integrate isometrics into your workouts to stimulate muscle groups to enable greater innate strength without bulk. Simple and innovative steps to follow that can be done almost anywhere.</p>
                            <ul>
                                <li> <img alt="not found" src={checkIcon} style={{ marginTop: '10px', marginBottom: '20px' }}/>Improve innate and core strength to assist any sporting activity and life participation in general</li>
                                <li> <img alt="not found" src={checkIcon} style={{ marginBottom: '20px' }}/>Awaken muscle groups through the isometric process for better strength results.</li>
                                <li> <img alt="not found" src={checkIcon} style={{ marginBottom: '20px' }} />Ideal to break up your usual workouts and challenge the body with alternatives exercises </li>
                                <li> <img alt="not found" src={checkIcon} style={{ marginBottom: '20px' }}/>Improve all round body tone, definition and sculpting</li>
                                <li> <img alt="not found" src={checkIcon} style={{ marginBottom: '20px' }}/>Improve mental focus and determination by immersing yourself in building mind and body resilience</li>
                                <li> <img alt="not found" src={checkIcon} style={{ marginBottom: '20px' }}/>Look and feel dynamic to enjoy life to the full!</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${IsoBg})`}}>
                    <div className="fu-dis">
                                        <div className="fu-head">
                                            <h2>Isometrics</h2>
                                        </div>
                                        <div className="fu-dis-con">
                                            <div className="fu-dis-txt">

                                                <h4>Description: </h4>

                                                <p>Use this range of isometric exercises to break up your workouts and challenge your muscles (and mind) in different ways. Muscles respond well to new routines, incorporate Isometrics to gain greater strength and low bulk power. Make sure to warm up first and stretch post workout.</p>
                                            </div>
                                            <div className="fu-dis-time">
                                                <p><span>DURATION</span>：10mins / 20mins</p>
                                                <p><span>MASTER</span>：Patricia Hart</p>
                                            </div>
                                            <div className="tab-btn">
                                                <button>PREVIEW<img alt="not found" src={smalPlayIcon} /></button>
                                                <button onClick={() => history.push('/home-workouts/isometrics')}>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                                <button onClick={bookHandler}>BOOK THIS</button>
                                            </div>
                                        </div>
                                    </div>
                    </div>
                </div>
                <div className={`benefits-container ${showTabs === 4 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1>HIIT BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>Intense yet manageable exercise segments you can fit in anytime of the day. Once you have become confident, and more physically and mentally conditioned with our Stretching, Callisthenics and Isometrics routines, challenge yourself with High Intensity Interval Training a few times per week. </p>
                            <ul>
                                <li> <img alt="not found" src={checkIcon} />Improve mental discipline with physically demanding, yet short, low impact, HIIT workouts.</li>
                                <li> <img alt="not found" src={checkIcon} />Improve cardiovascular and respiratory health through gradually intensifying and lengthening your HIIT sessions</li>
                                <li> <img alt="not found" src={checkIcon} />Improve all round – full body strength, body tone, definition and sculpting</li>
                                <li> <img alt="not found" src={checkIcon} />Gain greater explosive strength, and improve your power to weight ratio, while maintaining flexibility and speed through high intensity movements sustained in short bursts to activate fast-twitch muscle fibres - associated with HIIT</li>
                                <li> <img alt="not found" src={checkIcon} />Improve heart rate recovery, metabolic flexibility, reduce excess weight, and most importantly conquer visceral fat deposits</li>
                                <li> <img alt="not found" src={checkIcon} />Look and feel vibrant, and have the energy to enjoy life to the full with HIIT!</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${HiitBg})`}}>
                    <div className="fu-dis">
                                        <div className="fu-head">
                                            <h2>HIIT</h2>
                                        </div>
                                        <div className="fu-dis-con">
                                            <div className="fu-dis-txt">

                                                <h4>Description: </h4>

                                                <p>Use this sequence of HIIT exercises to fully challenge yourself physically and mentally with short, sharp, highly intense, yet low impact workouts. Make sure to warm up first and stretch post workout.</p>
                                            </div>
                                            <div className="fu-dis-time">
                                                <p><span>DURATION</span>：10mins / 20mins</p>
                                                <p><span>MASTER</span>：Patricia Hart</p>
                                            </div>
                                            <div className="tab-btn">
                                                <button>PREVIEW<img alt="not found" src={smalPlayIcon} /></button>
                                                <button onClick={() => history.push('/home-workouts/hiit')}>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                                <button onClick={bookHandler}>BOOK THIS</button>
                                            </div>
                                        </div>
                                    </div>
                    </div>
                </div>
            </Row>
        </>
    );
}

export default HomeWorkoutTabs;