import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Header from "../../components/Header/Header";
import AdvanceBannerSlider from "../../components/AdvanceBannerSlider/AdvanceBannerSlider";
import PlanFormsBottom from "../../components/PlanFormsBottom/PlanFormsBottom";
import ClusterIcons from "../../components/cluster-icons";
import Cluster1 from "../../assets/images/Cluster-1.png";
import Cluster2 from "../../assets/images/Cluster-2.png";
import Cluster3 from "../../assets/images/Cluster-3.png";
import Cluster4 from "../../assets/images/Cluster-4.png";
import playerIcon from "../../assets/images/player-icon.png";
import personHealth from "../../assets/images/personHealth.png";
import FooterBanner from "../../components/footer-banner";
import "./AdvanceExercises.scss";
import Footer from "../../components/Footer/Footer";

const AdvanceExercises = () => {
  const [scrollPage, setOffset] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dbLinks = (link) => {
    history.push(link);
  };

  return (
    <div className="advance-Exercise page-w">
      <Header />
      <ClusterIcons active />
      <AdvanceBannerSlider />

      <Row>
        <Col sm={12} md={12}>
          <div className="botom-icon">
            <div className="setion-name">
              <img src={Cluster1} alt="Cluster1" />
              <p>Health</p>
            </div>
            <span>|</span>
            <div className="setion-name">
              <img src={Cluster4} alt="Cluster2" />
              <p>Prosperity</p>
            </div>
            <span>|</span>
            <div className="setion-name">
              <img src={Cluster2} alt="Cluster3" />
              <p>Inspiration</p>
            </div>
            <span>|</span>
            <div className="setion-name">
              <img src={Cluster3} alt="Cluster4" />
              <p>Joy</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
      <Col sm={12} md={12}>
          <div className="exercise-secion-right">
            <div className="section-right">
              <h1>Home and outdoor workouts</h1>
              <div className="exe-inner exe-bg2">
                <div className="exe-inner-sp">
                  <Row>
                    <Col sm={2} md={2}>
                      <img
                        src={personHealth}
                        className="person-health-img"
                        alt="personHealth2"
                      />
                    </Col>
                    <Col sm={6} md={7}>
                      <div className="content-inner">
                        <h3>Self-sufficient exercise programs</h3>
                        <p>
                          Save time and money and work out effectively at home,
                          the office, or the park. The home workout series is
                          designed to be done anywhere, any time with quick and
                          easy to follow exercise sequences consisting of a
                          comprehensive range of; Stretches, Callisthenic,
                          Isometric and High Intensity Interval training
                          (H.I.I.T) sessions.
                          <br />
                          In most cases you do not need any equipment, and where
                          necessary we demonstrate how to utilise household
                          items to enable you to workout anytime, anywhere as
                          you like - after which you’ll realise you don’t
                          actually need a gym. In this way your workouts are not
                          reliant on the limitations of needing special
                          facilities. Become resourceful, innovative and
                          empowered as you gain greater strength, fitness and
                          flexibility with the Sanctuary home workout program.
                        </p>

                        <div className="btn-section">
                          <button onClick={() => dbLinks("/home-workouts")}>
                            READ MORE
                          </button>
                          <img src={playerIcon} alt="playerIcon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm={4} md={3}>
                      <div className="line-inner ">
                        <ul className="v-text">
                          <li>Stretching</li>
                          <li>Calisthenics</li>
                          <li>Isometrics</li>
                          <li>Hiit</li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <h2>DiscoveR Shift Evolve Create</h2>
            </div>
          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className="weight-shifter-plan"></div>

          <div className="exercise-secion-left">
            <div className="section-right">
              <h1>FUSION Exercise Series </h1>
              <div className="exe-inner exe-bg1">
                <div className="exe-inner-sp">
                  <Row>
                    <Col sm={4} md={3}>
                      <div className="line-inner line-inner1">
                        <ul className="v-text">
                          <li>AIR</li>
                          <li>WATER</li>
                          <li>Crystal</li>
                          <li>Diamond</li>
                        </ul>
                      </div>
                    </Col>
                    <Col sm={6} md={7}>
                      <div className="content-inner">
                        <h3>Sanctuary Mind X Body Exercise</h3>
                        <p>
                          Amplify the abilities of the body through the power of
                          the mind with our Fusion Exercise series.
                          <br />A multi-faceted exercise program that
                          encompasses the key Health Psychology aspects to
                          fortify and enhance one’s mental, emotional state
                          while also providing all of the very best physical
                          components for complete all round holistic health –
                          firstly to aid and promote preventative health,
                          healing and anti-aging, and secondly to enhance the
                          body’s abilities for exceptional physical performance.
                          <br />
                          This workout program involves four progressive
                          mental-emotional fitness, physical fitness, and
                          flexibility and strength levels to create a more fully
                          dynamic you
                        </p>
                        <div className="btn-section">
                          <button onClick={() => dbLinks("/fusion-exercise")}>
                            READ MORE
                          </button>
                          <img src={playerIcon} alt="playerIcon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm={2} md={2}>
                      <img
                        src={personHealth}
                        className="person-health-img"
                        alt="personHealth"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <h2>DiscoveR Shift Evolve Create</h2>
            </div>
          </div>
        </Col>
        
        <Col sm={12} md={12}>
          <div className="exercise-secion-right">
            <div className="section-right">
              <h1>Yoga & Qigong</h1>
              <div className="exe-inner exe-bg3">
                <div className="exe-inner-sp">
                  <Row>
                    <Col sm={4} md={4}>
                      <div className="line-inner line-inner3">
                        <ul className="v-text">
                          <li>Vanyasa FLOW 1</li>
                          <li>Vanyasa FLOW 2</li>
                          <li>Vanyasa FLOW 3</li>
                          <li>Qigong</li>
                          <li>Martial arts</li>
                        </ul>
                      </div>
                    </Col>
                    <Col sm={6} md={6}>
                      <div className="content-inner">
                        <h3>Orci tincidunt </h3>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores
                          et ea rebum. Stet clita kasd gubergren,
                          <br />
                          <br />
                          At vero eos et accusam et justo duo dolores et ea
                          rebum. Stet clita kasd gubergren, no sea takimata
                          sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                          dolor sit amet, consetetur sadipscing elitr, sed diam
                          nonumy eirmod tempor invidunt ut
                        </p>
                        <div className="btn-section">
                          <button>READ MORE</button>
                          <img src={playerIcon} alt="playerIcon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm={2} md={2}>
                      <img
                        src={personHealth}
                        className="person-health-img"
                        alt="personHealth"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <h2>Discover Shift Evolve Create</h2>
            </div>
          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className="exercise-secion-left">
            <div className="section-right">
              <h1>dance</h1>
              <div className="exe-inner exe-bg4">
                <div className="exe-inner-sp">
                  <Row>
                    <Col sm={2} md={2}>
                      <img
                        src={personHealth}
                        className="person-health-img"
                        alt="personHealth"
                      />
                    </Col>
                    <Col sm={6} md={7}>
                      <div className="content-inner">
                        <h3>Porta aliquam metus</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores
                          et ea rebum. Stet clita kasd gubergren, no sea
                          takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                          ipsum dolor sit amet, consetetur sadipscing elitr, sed
                          diam nonumy eirmod tempor invidunt ut
                        </p>
                        <p>
                          {" "}
                          At vero eos et accusam et justo duo dolores et ea
                          rebum. Stet clita kasd gubergren, no sea takimata
                          sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                          dolor sit amet, consetetur sadipscing elitr, sed diam
                          nonumy eirmod tempor invidunt ut
                        </p>

                        <div className="btn-section">
                          <button>READ MORE</button>
                          <img src={playerIcon} alt="playerIcon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm={4} md={3}>
                      <div className="line-inner line-4">
                        <ul className="v-text">
                          <li>modern</li>
                          <li>salsa</li>
                          <li>tango</li>
                          <li>Cha cha</li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <h2>DiscoveR Shift Evolve Create</h2>
            </div>
          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className="exercise-secion-right">
            <div className="section-right">
              <h1>Mind-health </h1>
              <div className="exe-inner exe-bg5">
                <div className="exe-inner-sp">
                  <Row>
                    <Col sm={4} md={4}>
                      <div className="line-inner">
                        <ul className="v-text">
                          <li>Affirmations</li>
                          <li>Visualisations</li>
                          <li>GUIDED meditations</li>
                          <li>Healing readings</li>
                          <li>Breathing Exercise</li>
                        </ul>
                      </div>
                    </Col>
                    <Col sm={6} md={6}>
                      <div className="content-inner">
                        <h3>Porta pharetra nec</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores
                          et ea rebum. Stet clita kasd gubergren,
                          <br />
                          <br />
                          At vero eos et accusam et justo duo dolores et ea
                          rebum. Stet clita kasd gubergren, no sea takimata
                          sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                          dolor sit amet, consetetur sadipscing elitr, sed diam
                          nonumy eirmod tempor invidunt ut
                        </p>
                        <div className="btn-section">
                          <button
                            onClick={() => dbLinks("/exercises-health-mind")}
                          >
                            READ MORE
                          </button>
                          <img src={playerIcon} alt="playerIcon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm={2} md={2}>
                      <img
                        src={personHealth}
                        className="person-health-img"
                        alt="personHealth"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <h2>DiscoveR Shift Evolve Create</h2>
            </div>
          </div>
        </Col>
      </Row>
      <FooterBanner />
      <div className={`shifter-plan ${scrollPage ? "showForm" : "hideForm"}`}>
        <h3>WEIGHT SHIFTER PLAN</h3>
        <PlanFormsBottom />
      </div>
      <Footer />
      <div id="weight-shift-plan">Weight Shifter Exercise Plan</div>
    </div>
  );
};

export default AdvanceExercises;
