import React from 'react';
import './index.scss';
import doc from '../../assets/images/advance-guide/doc.png';
import globe from '../../assets/images/advance-guide/globe.png';
import daller from '../../assets/images/advance-guide/daller.png';

const GuideModal = ({ title, imgPath, closeModal }) => {

    //const handleClose = () => setShow(false);
    return (
<div className="modal-continer">
          <div  className="closem"  onClick={closeModal} >
            X
          </div>
          <div className="body-section">
            <div className="baner-section">
              <img alt="not found" src={imgPath} />
              <p>{title}</p>
            </div>
            <div className="detail-section1">
              <h2><del>$39.99</del></h2>
              <button>PRE-ORDER NOW</button>
            </div>
            <div className="detail-section2">
              <h2>$19.99</h2>
              <button>see promo page</button>
            </div>
            <div className="dis-section">

              <p>Half price for first 100 Advance Orders
                of this guide</p>
              <div className="dis-section2">
                <ul>
                  <li><img alt="not found" src={doc} /> <p>Length :</p><span>Approx. 25,000 words</span></li>
                  <li><img alt="not found" src={globe} /> <p>Language :</p><span>English</span></li>
                  <li><img alt="not found" src={daller} /> <p>Money-Back Guarantee</p></li>
                </ul>
              </div>
              <div className="dis-section3">
                <p>Funds paid will be registered in your account under
                  Advance Purchases. Support the evolution of the
                  Sanctuary Project and save on valuable literature</p>
              </div>
            </div>
          </div>
        </div>
 );
};
export default GuideModal;