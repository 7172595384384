import React from "react";
import { useLocation } from 'react-router-dom';
import FooterLogo from '../../assets/images/aboutUs/footer_logo.png';
import './index.scss';

const Index = () => {
    const { pathname } = useLocation();
    return (
        <>
        {(pathname === '/master-sanctuary') ? (
        
        <div className='footer_banner' style={{ backgroundColor: '#7E489C'}}>
            <img src={FooterLogo} alt='logo'/>

            <div className='title'>Master Sanctuary<span className='registered'>&reg;</span></div>
            <div className='coloredText'>Discover your Magnificence</div>
            <div className='desc'>Sanctuaries for human wellbeing and advancement, Elevating Global Consciousness, and helping create “A Successful Planet.”</div>
        </div>
        ) : (
            <div className='footer_banner'>
                <img src={FooterLogo} alt='logo'/>
                <div className='title'>Urban Sanctuary<span className='registered'>&reg;</span></div>
                <div className='coloredText'>Discover your Magnificence</div>
                <div className='desc'>Sanctuaries for human wellbeing and advancement, Elevating Global Consciousness, and helping create “A Successful Planet.”</div>
            </div>
        )}
        </>
    )
}

export default Index;