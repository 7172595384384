import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import fusionexc1 from '../../assets/images/exercise-air/fusionexc1.png';
import fusionexc2 from '../../assets/images/exercise-air/fusionexc2.png';
import fusionexc3 from '../../assets/images/exercise-air/fusionexc3.png';
import fusionexc4 from '../../assets/images/exercise-air/fusionexc4.png';
import checkIcon from '../../assets/images/check-icon.png';
import smalPlayIcon from '../../assets/images/smal-play-icon.png';
import mediumPlayIcon from '../../assets/images/medium-play-icon.png';
import WaterBg from '../../assets/images/third-set/water-bg.png';
import './FusionExerciseAirTab.scss';

const FusionExerciseAirTab = () => {
    const [showTabs, tabChange] = useState(1);
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const dbLinks = (link) => {
        history.push(link)
    }

    return (
        <>
            <Row>
                <Col sm={12} md={12}>
                    <div className="fusion-exe">
                        <h1>Fusion Exercise Series</h1>
                    </div>
                </Col>
                <div className="fusion-gal">
                    <Row>
                        <Col xs={3} sm={3} md={3}>
                            <div className="fusion-exe-gl" onClick={() => tabChange(1)}>
                                <img alt="not found" onClick={() => dbLinks('/exercise-air-detail')} src={fusionexc1} />
                                <h4>AIR</h4>
                                <div className={`${showTabs === 1 ? 'fu-active-gal' : ''}`}>
                                    <div className="triangle-wrapper-100">
                                        <div className="triangle-left"></div>
                                        <div className="triangle-right"></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} sm={3} md={3} onClick={() => tabChange(2)}>
                            <div className="fusion-exe-gl">
                                <img alt="not found" onClick={() => dbLinks('/exercise-water-detail')} src={fusionexc2} />
                                <h4>WATER</h4>
                                <div className={`${showTabs === 2 ? 'fu-active-gal' : ''}`}>
                                    <div className="triangle-wrapper-100">
                                        <div className="triangle-left"></div>
                                        <div className="triangle-right"></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} sm={3} md={3} onClick={() => tabChange(3)}>
                            <div className="fusion-exe-gl">
                                <img alt="not found" src={fusionexc3} />
                                <h4>CRYSTAL</h4>
                                <div className={`${showTabs === 3 ? 'fu-active-gal' : ''}`}>
                                    <div className="triangle-wrapper-100">
                                        <div className="triangle-left"></div>
                                        <div className="triangle-right"></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} sm={3} md={3}>
                            <div className="fusion-exe-gl" onClick={() => tabChange(4)}>
                                <img alt="not found" src={fusionexc4} />
                                <h4>DIAMOND</h4>
                                <div className={`${showTabs === 4 ? 'fu-active-gal' : ''}`}>
                                    <div className="triangle-wrapper-100">
                                        <div className="triangle-left"></div>
                                        <div className="triangle-right"></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={`benefits-container ${showTabs === 1 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1>AIR BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>A confidence building, well balanced invigorating class for beginners, or middle-aged to <br />elderly wishing to begin their journey back to greater health and well-being. Restorative, oxygenating, <br />esteem building, up lifting, healing, empowering.</p>
                            <ul>
                                <li> <img alt="not found" src={checkIcon} />Restorative - as you age from approx. 40 years onward, you begin to lose muscle - counter<br /> age related muscle loss, and enhance muscle rehabilitation, mobility and strength.</li>
                                <li> <img alt="not found" src={checkIcon} />Oxygenating - deep breathing exercisers to stimulate the cardiorespiratory system, alkalise and <br />oxygenate the body. </li>
                                <li> <img alt="not found" src={checkIcon} />Self-esteem building - visualisation processes to mentally ready yourself to achieve the health<br /> and life goals you are looking for. </li>
                                <li> <img alt="not found" src={checkIcon} />Up lifting - finish our Mind X Body classes happier than when you started, our classes have a positive<br /> emotional effect on mind, body and spirit enhancing the endocrine system.</li>
                                <li> <img alt="not found" src={checkIcon} />Healing - specially crafted guided mediations to identify and release emotional pain associated with negative<br /> and limiting emotions. Reduce stress, create positive feeling states and <br />enhance emotional well-being to free up the mind-body to enable its own healing processes.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${fusionexc1})`}}>
                    <div className="fu-dis">
                                        <div className="fu-head">
                                            <h2>AIR</h2>
                                        </div>
                                        <div className="fu-dis-con">
                                            <div className="fu-dis-txt">

                                                <h4>Description: </h4>

                                                <p>A low impact and intensity class to get you accustomed to the wide ranging benefits of Fusion and pave the way to greater holistic health. Ideal for all age groups. From as little as $1.00 per day</p>
                                            </div>
                                            <div className="fu-dis-time">
                                                <p><span>DURATION</span>：60 Minutes</p>
                                                <p><span>MASTER</span>：Kim Larking</p>
                                            </div>
                                            <div className="fu-dis-btn">
                                                <button>PREVIEW<img alt="not found" src={smalPlayIcon} /></button>
                                                <button>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                                <button>BOOK THIS</button>
                                            </div>
                                        </div>
                                    </div>
                    </div>
                </div>
                <div className={`benefits-container ${showTabs === 2 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1>WATER BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>A confidence building, well balanced invigorating class for beginners, or middle-aged to <br />elderly wishing to begin their journey back to greater health and well-being. Restorative, oxygenating, <br />esteem building, up lifting, healing, empowering.</p>
                            <ul>
                            <li> <img alt="not found" src={checkIcon} /><b>Increasing</b> - rapid learning and dual reward skill and health advancement program incorporating ancient arts</li>
                                            <li style={{ marginBottom: '25px' }}> <img alt="not found" src={checkIcon} /><b>Opening</b> - partnered and couple exercises</li>
                                            <li style={{ marginBottom: '25px' }}> <img alt="not found" src={checkIcon} /><b>Stretching</b> - whole body flexibility focus</li>
                                            <li style={{ marginBottom: '25px' }}> <img alt="not found" src={checkIcon} /><b>Toning</b> - higher cardio workouts with more challenging H.I.I.T </li>
                                            <li style={{ marginBottom: '25px' }}> <img alt="not found" src={checkIcon} /><b>Strengthening</b> - isometric and resistance strengthening for all muscle groups</li>
                                            <li style={{ marginBottom: '25px' }}> <img alt="not found" src={checkIcon} /><b>Fortifying</b> - increased mental, emotional, spiritual health focus</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${WaterBg})`}}>
                    <div className="fu-dis">
                                        <div className="fu-head">
                                            <h2>WATER</h2>
                                        </div>
                                        <div className="fu-dis-con">
                                            <div className="fu-dis-txt">

                                                <h4>Description: </h4>

                                                <p>A low impact and intensity class to get you accustomed to the wide ranging benefits of Fusion and pave the way to greater holistic health. Ideal for all age groups. From as little as $1.00 per day</p>
                                            </div>
                                            <div className="fu-dis-time">
                                                <p><span>DURATION</span>：60 Minutes</p>
                                                <p><span>MASTER</span>：Kim Larking</p>
                                            </div>
                                            <div className="fu-dis-btn">
                                                <button>PREVIEW<img alt="not found" src={smalPlayIcon} /></button>
                                                <button>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                                <button>BOOK THIS</button>
                                            </div>
                                        </div>
                                    </div>
                    </div>
                </div>
                <div className={`benefits-container ${showTabs === 3 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1>CRYSTAL BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>A confidence building, well balanced invigorating class for beginners, or middle-aged to <br />elderly wishing to begin their journey back to greater health and well-being. Restorative, oxygenating, <br />esteem building, up lifting, healing, empowering.</p>
                            <ul>
                                <li> <img alt="not found" src={checkIcon} />Restorative - as you age from approx. 40 years onward, you begin to lose muscle - counter<br /> age related muscle loss, and enhance muscle rehabilitation, mobility and strength.</li>
                                <li> <img alt="not found" src={checkIcon} />Oxygenating - deep breathing exercisers to stimulate the cardiorespiratory system, alkalise and <br />oxygenate the body. </li>
                                <li> <img alt="not found" src={checkIcon} />Self-esteem building - visualisation processes to mentally ready yourself to achieve the health<br /> and life goals you are looking for. </li>
                                <li> <img alt="not found" src={checkIcon} />Up lifting - finish our Mind X Body classes happier than when you started, our classes have a positive<br /> emotional effect on mind, body and spirit enhancing the endocrine system.</li>
                                <li> <img alt="not found" src={checkIcon} />Healing - specially crafted guided mediations to identify and release emotional pain associated with negative<br /> and limiting emotions. Reduce stress, create positive feeling states and <br />enhance emotional well-being to free up the mind-body to enable its own healing processes.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${fusionexc1})`}}>
                    <div className="fu-dis">
                                        <div className="fu-head">
                                            <h2>CRYSTAL</h2>
                                        </div>
                                        <div className="fu-dis-con">
                                            <div className="fu-dis-txt">

                                                <h4>Description: </h4>

                                                <p>A low impact and intensity class to get you accustomed to the wide ranging benefits of Fusion and pave the way to greater holistic health. Ideal for all age groups. From as little as $1.00 per day</p>
                                            </div>
                                            <div className="fu-dis-time">
                                                <p><span>DURATION</span>：60 Minutes</p>
                                                <p><span>MASTER</span>：Kim Larking</p>
                                            </div>
                                            <div className="fu-dis-btn">
                                                <button>PREVIEW<img alt="not found" src={smalPlayIcon} /></button>
                                                <button>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                                <button>BOOK THIS</button>
                                            </div>
                                        </div>
                                    </div>
                    </div>
                </div>
                <div className={`benefits-container ${showTabs === 4 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1>DIAMOND BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>A confidence building, well balanced invigorating class for beginners, or middle-aged to <br />elderly wishing to begin their journey back to greater health and well-being. Restorative, oxygenating, <br />esteem building, up lifting, healing, empowering.</p>
                            <ul>
                                <li> <img alt="not found" src={checkIcon} />Restorative - as you age from approx. 40 years onward, you begin to lose muscle - counter<br /> age related muscle loss, and enhance muscle rehabilitation, mobility and strength.</li>
                                <li> <img alt="not found" src={checkIcon} />Oxygenating - deep breathing exercisers to stimulate the cardiorespiratory system, alkalise and <br />oxygenate the body. </li>
                                <li> <img alt="not found" src={checkIcon} />Self-esteem building - visualisation processes to mentally ready yourself to achieve the health<br /> and life goals you are looking for. </li>
                                <li> <img alt="not found" src={checkIcon} />Up lifting - finish our Mind X Body classes happier than when you started, our classes have a positive<br /> emotional effect on mind, body and spirit enhancing the endocrine system.</li>
                                <li> <img alt="not found" src={checkIcon} />Healing - specially crafted guided mediations to identify and release emotional pain associated with negative<br /> and limiting emotions. Reduce stress, create positive feeling states and <br />enhance emotional well-being to free up the mind-body to enable its own healing processes.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${fusionexc1})`}}>
                    <div className="fu-dis">
                                        <div className="fu-head">
                                            <h2>DIAMOND</h2>
                                        </div>
                                        <div className="fu-dis-con">
                                            <div className="fu-dis-txt">

                                                <h4>Description: </h4>

                                                <p>A low impact and intensity class to get you accustomed to the wide ranging benefits of Fusion and pave the way to greater holistic health. Ideal for all age groups. From as little as $1.00 per day</p>
                                            </div>
                                            <div className="fu-dis-time">
                                                <p><span>DURATION</span>：60 Minutes</p>
                                                <p><span>MASTER</span>：Kim Larking</p>
                                            </div>
                                            <div className="fu-dis-btn">
                                                <button>PREVIEW<img alt="not found" src={smalPlayIcon} /></button>
                                                <button>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                                <button>BOOK THIS</button>
                                            </div>
                                        </div>
                                    </div>
                    </div>
                </div>
            </Row>
        </>
    );
}

export default FusionExerciseAirTab;