import React from 'react';
import './index.scss';
import circleIon from "../../assets/images/circle-ion.png";
import editIcons from "../../assets/images/edit-icons.png";
import { Row, Col } from "react-bootstrap";

const Index = (props) => {
    const { dotGlobal } = props;
    
    return (
        <div className="info-wraper">
        <Row>
          <Col sm={12} md={5}>
            <div className="info-sec">
              <span className="info-left">
                <img alt="not found" src={circleIon} />
                <p>Health</p>
              </span>
              <p>When phasellus dignissim, in pellentesuqe?</p>
              <img alt="not found" src={editIcons} />
            </div>
          </Col>
          <Col sm={12} md={2}>
            <div className="tb-info">
            <img alt="not found" className="activeimg" src={`${'Cluster-'}${dotGlobal}.png`} />
            </div>
          </Col>
          <Col sm={12} md={5}>
            <div className="app-btn">
              <button>
                {/* <img alt="not found" src={shifterApp} /> */}
                 WEIGHT SHIFTER APP
              </button>
              <button>
                {/* <img alt="not found" src={creatorApp} /> */}
                 HEALTH CREATOR APP
              </button>
            </div>
          </Col>
        </Row>
      </div>
    )
}

export default Index;