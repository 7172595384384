import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
// import Header from "../../components/Header/Header";
import DatePicker from 'sassy-datepicker';
import './index.scss';


const Index = () => {
    const onChange = (date) => {
        console.log(date.toString());
    };
    const selectTime=(val)=>{
        console.log(val)
      }
    return (
        <div className='registration'>
            {/* <Header /> */}
            <Row>
                <Col sm={12} md={12}>
                    <button className='back-btn'><i className='fas fa-caret-left'>&nbsp;</i>Back to web page</button>
                </Col>
                <Col sm={12} md={12} lg={7}>
                    <div className='form-sec'>
                        <h2>Registration form for Health Testing</h2>
                        <p>Please fill the correct details as per you Passport/License. Please make sure you provide your current correct medical details.</p>
                        <div className='frm-sec'>

                            <h3>1) General details</h3>

                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label className='label2'>First name</label>
                                        <input type="text" className="form-control" placeholder='Enter First name' />
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label className='label2'>Email Id</label>
                                        <input type="text" className="form-control" placeholder='Enter Email Id' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="form-group">
                                        <label className='label2'>Email Id</label>
                                        <input type="text" className="form-control" placeholder='Enter Email Id' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="form-group">
                                        <label className='label2'>Contact number</label>
                                        <input type="text" className="form-control" placeholder='Enter Contact number' />
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label className='label2' >Residental Address</label>
                                        <input type="text" className="form-control" placeholder='Enter Address' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="form-group">
                                        <label className='label2' >City</label>
                                        <input type="text" className="form-control" placeholder='Enter City' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="form-group">
                                        <label className='label2' >Suburb / State</label>
                                        <input type="text" className="form-control" placeholder='Enter State' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="form-group">
                                        <label className='label2'>Post code / area code</label>
                                        <input type="text" className="form-control" placeholder='Enter Post code' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="form-group">
                                        <label className='label2'>Country</label>
                                        <input type="text" className="form-control" placeholder='Enter Country' />
                                    </div>
                                </Col>
                            </Row>
                            <h3>2) Emergency Contact details</h3>
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label >First name</label>
                                        <input type="text" className="form-control" placeholder='Enter First name' />
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label >Email Id</label>
                                        <input type="text" className="form-control" placeholder='Enter Email Id' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="form-group">
                                        <label >Who is s/he for you</label>
                                        <input type="text" className="form-control" placeholder='' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="form-group">
                                        <label >Contact number</label>
                                        <input type="text" className="form-control" placeholder='Enter Contact number' />
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label >Email Id</label>
                                        <input type="text" className="form-control" placeholder='Enter Address' />
                                    </div>
                                </Col>

                            </Row>
                            <h3>3) Please detail the main purpose for your consultation, testing,
                                and/or goals that you wish to achieve.</h3>
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <input type="text" className="form-control" />
                                        <input type="text" className="form-control" />
                                       <input type="text" className="form-control" />

                                    </div>
                                </Col>


                            </Row>
                            <h3>4) Medical details</h3>
                            <p className='text-col'>Please provide your correct medical details as per your current health state as background context in order to effectively work with you.</p>
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label className='label2'>Usual GP name & Clinic</label>
                                        <input type="text" className="form-control" />
                                      
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label className='label2' >Have you ever had adverse reaction (side effects or allergy) to any
                                            medication, supplements, herbs or foods? If so, please describe</label>
                                        <input type="text" className="form-control" />

                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label className='label2'>Do you have any medical condition that you are currently under
                                            treatment for?</label>
                                        <input type="text" className="form-control" />

                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label className='label2'>Are you currently using medication? If so, please list</label>
                                        <input type="text" className="form-control" />

                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label className='label2'>Are you taking any supplements, herbs or specialised food? If so,
                                            please list</label>
                                        <input type="text" className="form-control" />

                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label className='label2'>Please list any previous health condition you think we should be
                                            aware of</label>
                                        <input type="text" className="form-control" />
                                        <input type="text" className="form-control" />

                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <label className='label2' >Are you taking any supplements, herbs or specialised food? If so,
                                            please list</label>
                                        <input type="text" className="form-control" />
                                        <input type="text" className="form-control" />

                                    </div>
                                </Col>
                            </Row>
                            <h3>5) Acknowledgements</h3>
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <div className="custom-control form-control-lg custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                            <label className="custom-control-label text-col" for="customCheck1" >

                                                The service provided to you is with the best intention to aid your health and well-being. Your consultation or session, depending on the services you have chosen, will be with a doctor, health professional, health or lifestyle coach, health facilitator or a knowledgeable practitioner. Hence the consultant may or may not have an academic or medical back ground but experience in the related field.</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <div className="custom-control form-control-lg custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customCheck2" />
                                            <label className="custom-control-label text-col" for="customCheck2" >
                                                I confirm the details I give are true and correct.  I have disclosed my true identity and the true purpose of my consultation and I am acting in my capacity as a private citizen. I realise that if I give false information before or during this consultation then no information from before or during this consultation can be used in any way.  I understand that my doctor/ health professional/ health facilitator will keep the contents of my consultation confidential and that I agree to do the same.</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="form-group">
                                        <div className="custom-control form-control-lg custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customCheck3" />
                                            <label className="custom-control-label text-col2" for="customCheck3" >

                                                I have read and agree to the <span className='text-col1'>Right, Privacy & Consent commitments</span></label>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className='btm-btn'>
                                        <button>Email request</button>
                                        <button>Request to book</button>
                                        <button>Request to pay</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                </Col>
                <Col sm={12} md={12} lg={5}>
                    <div className='colender-wraper'>
                        <div className='colender-sec'>
                            <p className='txt-head'>Please select the time & date perference</p>
                            <DatePicker onChange={onChange} />
                            <p>Preferable time</p>
                            <div className='time-sec'>
                                <button onClick={()=>selectTime('9:00 AM to 10:00 AM')}>9:00 AM to 10:00 AM</button>
                                <button onClick={()=>selectTime('10:00 AM to 11:00 AM')}>10:00 AM to 11:00 AM</button>
                                <button onClick={()=>selectTime('11:00 AM to 12:00 AM')}>11:00 AM to 12:00 PM</button>
                                <button onClick={()=>selectTime('12:00 PM to 1:00 PM')}>12:00 PM to 1:00 PM</button>
                                <button onClick={()=>selectTime('1:00 PM to 2:00 PM')}>1:00 PM to 2:00 PM</button>
                                <button onClick={()=>selectTime('2:00 PM to 3:00 PM')}>2:00 PM to 3:00 PM</button>
                                <button onClick={()=>selectTime('3:00 PM to 4:00 PM')}>3:00 PM to 4:00 PM</button>
                                <button onClick={()=>selectTime('4:00 PM to 5:00 PM')}>4:00 PM to 5:00 PM</button>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input" name="optradio" />Specialised time:6:30 PM Tuesdays</label>
                            </div>
                            <div className='consul'>
                                <p>Consultant:&nbsp;&nbsp;<span>Kim Larking</span></p>
                            </div>
                            <div className='price-lbl'>
                                <p>Session</p>
                                <p>Price</p>
                            </div>
                            <div className='price price-de'>
                                <p>Health & Wellness testing by Kim Larking</p>
                                <p>$100.00 <sub>per hour</sub></p>
                            </div>
                            <div className='package-war'>
                                <p>Inbody 770 scanner</p>
                                <p>Warning: <span>No pace maker</span></p>
                            </div>
                            <div className='price-totl'>
                                <p>Total Cost</p>
                                <p>$100.00</p>
                                
                            </div>
                            {/* <p className='term'>$6.99 per week / $83.88 for a 3 month term / $179.98 annually</p> */}
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={12} className="botm-msg">
                    <h3>HEALTH DISCLAIMER</h3>
                    <span>If you or any other person has a medical concern, you should consult with your health care provider or seek other professional medical treatment. Never disregard professional medical advice or delay in seeking it because of something that you have read on this website or in any linked or related materials. If you think you have medical emergency, call your doctor or emergency services immediately. The opinions and views expressed herein have no relation to those of any academic, hospital, health practice or other institution. The Sanctuary services, Guides, protocols, program, blogs and newsletters, and all other such content are provided as general information and discussions about health and realted subjects. The information and content provided in this website, or in any linked materials, are not intended and should not be constructed as medical advice, nor is the information a substitute for professional medical expertise or treatment.</span>

                </Col>
            </Row>



            {/* <Footer /> */}


        </div>
    )
}

export default Index;