import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Row, Col } from 'react-bootstrap';
import './index.scss';

const Index = (props) => {
    return (
        <div className='calender marginsLast mb-5'>
        <h1>More Information</h1>
        <Row >
          <Col sm={12} md={3}>
            <div className="left-chart">
              <ul>
                <li>On Demand</li>
                <li className="cal-active">Live Session</li>
                <li>Calories</li>
                <li>Calender </li>
                <li>Guides</li>
                <li>Workshops</li>
                <li>Courses</li>
              </ul>
            </div>
          </Col>
          <Col sm={12} md={9}>
            <div className="char-setion">
              <Tabs defaultActiveKey="first">
                <Tab eventKey="first" title="View 1">
                  <div className="table-data table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Exercise</th>
                          <th>125 pounds/56,7kg</th>
                          <th>155 pounds/70,7kg</th>
                          <th>185 pounds/83,9kg</th>
                          <th>250 pounds/113,4kg</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><i className="fas fa-circle"></i>Fusion Air</td>
                          <td>240 Calories</td>
                          <td>220 Calories</td>
                          <td>250 Calories</td>
                          <td>260 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Fusion Water</td>
                          <td>340 Calories</td>
                          <td>350 Calories</td>
                          <td>320 Calories</td>
                          <td>310 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Fusion Crystal</td>
                          <td>240 Calories</td>
                          <td>220 Calories</td>
                          <td>250 Calories</td>
                          <td>260 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Fusion Diamond</td>
                          <td>240 Calories</td>
                          <td>220 Calories</td>
                          <td>250 Calories</td>
                          <td>260 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Vinyasa 1</td>
                          <td>340 Calories</td>
                          <td>350 Calories</td>
                          <td>320 Calories</td>
                          <td>310 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Vinyasa 2</td>
                          <td>240 Calories</td>
                          <td>220 Calories</td>
                          <td>250 Calories</td>
                          <td>260 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Modern Dance</td>
                          <td>340 Calories</td>
                          <td>350 Calories</td>
                          <td>320 Calories</td>
                          <td>310 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Classic Dance</td>
                          <td>240 Calories</td>
                          <td>240 Calories</td>
                          <td>240 Calories</td>
                          <td>240 Calories</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab>
                <Tab eventKey="second" title="View 2">
                <div className="table-data table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Exercise</th>
                          <th>125 pounds/56,7kg</th>
                          <th>155 pounds/70,7kg</th>
                          <th>185 pounds/83,9kg</th>
                          <th>250 pounds/113,4kg</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><i className="fas fa-circle"></i>Fusion Air</td>
                          <td>240 Calories</td>
                          <td>220 Calories</td>
                          <td>250 Calories</td>
                          <td>260 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Fusion Water</td>
                          <td>340 Calories</td>
                          <td>350 Calories</td>
                          <td>320 Calories</td>
                          <td>310 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Fusion Crystal</td>
                          <td>240 Calories</td>
                          <td>220 Calories</td>
                          <td>250 Calories</td>
                          <td>260 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Fusion Diamond</td>
                          <td>240 Calories</td>
                          <td>220 Calories</td>
                          <td>250 Calories</td>
                          <td>260 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Vinyasa 1</td>
                          <td>340 Calories</td>
                          <td>350 Calories</td>
                          <td>320 Calories</td>
                          <td>310 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Vinyasa 2</td>
                          <td>240 Calories</td>
                          <td>220 Calories</td>
                          <td>250 Calories</td>
                          <td>260 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Modern Dance</td>
                          <td>340 Calories</td>
                          <td>350 Calories</td>
                          <td>320 Calories</td>
                          <td>310 Calories</td>
                        </tr>
                        <tr>
                          <td><i className="fas fa-circle"></i>Classic Dance</td>
                          <td>240 Calories</td>
                          <td>240 Calories</td>
                          <td>240 Calories</td>
                          <td>240 Calories</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                    
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </div>
    )
}

export default Index;