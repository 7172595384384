import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {login as loginStructure} from 'shared/formStructures/login';
import FormBuilder from 'shared/formBuilder';
import {useLoginCustomer} from 'shared/react-query/hooks';
import {useAuth} from 'shared/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(67,67,67,0.64)',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  contentBox: {
    padding: '20px 0',
    width: '33%',
    background: 'white',
    borderRadius: '15px',
    border: '1px solid #707070',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  content: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  title: {
    color: '#9AA200',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  inputItem: {
    marginTop: '15px',
    position: 'relative',
  },
  inputLabel: {
    fontSize: '13px',
    color: 'rgba(82,82,82,0.98)',
  },
  input: {
    height: '45px',
    width: '100%',
    marginTop: '10px',
    boxSizing: 'border-box',
    border: '1px solid #9AA200',
    borderRadius: '5px',
    padding: '0 5px',
    '&:focus': {
      outline: '#9AA200',
    },
  },
  iconVisibility: {
    height: '15px',
    position: 'absolute',
    right: '15px',
    bottom: '15px',
  },
  registerLabel: {
    fontSize: '13px',
    cursor: 'pointer',
    color: '#9AA200',
    textAlign: 'center',
  },
  registerLink: {
    textDecoration: 'none',
  },
  btnLogin: {
    width: '15ch',
    alignSelf: 'center',
    marginTop: '10px',
    height: '45px',
    cursor: 'pointer',
    textAlign: 'center',
    lineHeight: '45px',
    background: '#9AA200',
    borderRadius: '45px',
    color: 'white',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorLabel: {
    fontSize: '13px',
    color: 'red',
  },
  message: {
    fontSize: '13px',
    color: 'green',
  },
  blueprint: {
    margin: 0,
  },
  class1: {
    background: 'white',
    width: 400,
    minWidth: 300,
    borderRadius: '15px',
    margin: '10px',
  },
}));

const Login = (props) => {
  const {onHide, closeLoginModal} = props;
  const classes = useStyles();
  const loginCustomer = useLoginCustomer();

  const {setAuthToken} = useAuth();

  const handleLogin = (accessToken, expiresAt) => {
    // Now your app is in a logged-in state
    setAuthToken(accessToken, expiresAt);
  };

  return (
    <Grid container spacing={4} className={clsx(classes.blueprint, classes.class1)}>
      <Grid item xs={12} className={classes.blueprint}>
        <Typography variant="h4" className={classes.title}>
          SIGN IN
        </Typography>
      </Grid>
      <FormBuilder
        spacing={2}
        justify="center"
        structure={loginStructure}
        onSubmit={(values) => {
          return loginCustomer.mutateAsync({input: values}).then((res) => {
            if (res?.customerAccessTokenCreate?.userErrors?.[0]?.message?.length) {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve({error: res?.customerAccessTokenCreate?.userErrors?.[0]?.message});
                }, 300);

              });
            } else {
              handleLogin(
                res.customerAccessTokenCreate.customerAccessToken.accessToken,
                res.customerAccessTokenCreate.customerAccessToken.expiresAt
              );
              return new Promise((resolve, reject) => {
                resolve(true);
                closeLoginModal();
              });
            }
          });
        }}
      />
      <Grid item xs={12} className={classes.blueprint}>
        <Link to="/get-started" onClick={onHide} className={classes.registerLink}>
          <Typography className={classes.registerLabel}>Forgot password?</Typography>
        </Link>
      </Grid>
      <Grid item xs={12} className={classes.blueprint}>
        <Link to="/get-started" onClick={onHide} className={classes.registerLink}>
          <Typography className={classes.registerLabel}>
            Haven’t registered yet?
            <br />
            Become a visionaire and sign up now.
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default Login;
