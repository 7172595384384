import React from "react";
import { Row, Col } from "react-bootstrap";

import { AiFillPicture } from "react-icons/ai";
import { BsFillGrid3X3GapFill } from "react-icons/bs";

import sanctuary_store from "../../assets/images/visionaire-network/sanctuary_store.png";
import master_sanctuary from "../../assets/images/visionaire-network/master_sanctuary.jpeg";
import urban_sanctuary from "../../assets/images/visionaire-network/urban_sanctuary.png";
import metawheel from "../../assets/images/visionaire-network/metawheel.jpg";
import visionforhumanity from "../../assets/images/visionaire-network/visionforhumanity.jpg";
import biosphericallycorrect from "../../assets/images/visionaire-network/biosphericallycorrrect.png";
import visionproducts from "../../assets/images/visionaire-network/visionproducts.jpeg";
import "./visionairenetwork2.css";

const VisionaireNetworkTable2 = ({ display }) => {
  return (
    <>

      <Col sm={12} md={12} className="no-pad">
        <div className="form-wrapper">
          <div className="viewOptCont">
            <AiFillPicture
              className="optIcon"
              onClick={() => display("wheel")}
              style={{ color: "#999" }}
            />
            <BsFillGrid3X3GapFill
              className="optIcon"
              onClick={() => display("wheel")}
            />
          </div>

          <div className="meta-cont">
            <form className="form-cont">
              <div className="form-header">
                <img
                  className="form-logos"
                  src={sanctuary_store}
                  alt="sanctuary_store"
                />
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Visionaire</span>
                </label>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Receive Marketing</span>
                </label>
              </div>

              <div className="review-btn list">
                <a href="#">Review Visionare Details {">>"}</a>
              </div>
            </form>

            <form className="form-cont">
              <div className="form-header">
                <img
                  className="form-logos"
                  src={urban_sanctuary}
                  alt="urban_sanctuary"
                />
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Visionaire</span>
                </label>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Receive Marketing</span>
                </label>
              </div>

              <div className="review-btn list">
                <a href="#">Review Visionare Details {">>"}</a>
              </div>
            </form>

            <form className="form-cont">
              <div className="form-header">
                <img
                  className="form-logos"
                  src={master_sanctuary}
                  alt="master_sanctuary"
                />
                <h4 className="form-heading">Master Sanctuary</h4>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Visionaire</span>
                </label>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Receive Marketing</span>
                </label>
              </div>

              <div className="review-btn list">
                <a href="#">Review Visionare Details {">>"}</a>
              </div>
            </form>

            <form className="form-cont">
              <div className="form-header">
                <img
                  className="form-logos"
                  src={metawheel}
                  alt="sanctuary_store"
                />
                <h4 className="form-heading">Meta Wheel</h4>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Visionaire</span>
                </label>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Receive Marketing</span>
                </label>
              </div>

              <div className="review-btn list">
                <a href="#">Review Visionare Details {">>"}</a>
              </div>
            </form>

            <form className="form-cont">
              <div className="form-header">
                <img
                  className="form-logos"
                  src={metawheel}
                  alt="sanctuary_store"
                />
                <h4 className="form-heading">Health Creator</h4>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Visionaire</span>
                </label>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Receive Marketing</span>
                </label>
              </div>

              <div className="review-btn list">
                <a href="#">Review Visionare Details {">>"}</a>
              </div>
            </form>

            <form className="form-cont">
              <div className="form-header">
                <img
                  className="form-logos"
                  src={visionforhumanity}
                  alt="master_sanctuary"
                />
                <h4 className="form-heading">Vision For Humanity</h4>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Visionaire</span>
                </label>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Receive Marketing</span>
                </label>
              </div>

              <div className="review-btn list">
                <a href="#">Review Visionare Details {">>"}</a>
              </div>
            </form>

            <form className="form-cont">
              <div className="form-header">
                <img
                  className="form-logos"
                  src={biosphericallycorrect}
                  alt="urban_sanctuary"
                />
                <h4 className="form-heading">Biospherically Correct</h4>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Visionaire</span>
                </label>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Receive Marketing</span>
                </label>
              </div>

              <div className="review-btn list">
                <a href="#">Review Visionare Details {">>"}</a>
              </div>
            </form>

            <form className="form-cont">
              <div className="form-header">
                <img
                  className="form-logos"
                  src={visionproducts}
                  alt="sanctuary_store"
                />
                {/* <h4 className="form-heading">Sanctuary Store</h4> */}
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Visionaire</span>
                </label>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Receive Marketing</span>
                </label>
              </div>

              <div className="review-btn list">
                <a href="#">Review Visionare Details {">>"}</a>
              </div>
            </form>

            <form className="form-cont">
              <div className="form-header">
                <img
                  className="form-logos"
                  src={metawheel}
                  alt="sanctuary_store"
                />
                <h4 className="form-heading">2nd Genome</h4>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Become a Visionaire</span>
                </label>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Receive Marketing</span>
                </label>
              </div>

              <div className="review-btn list">
                <a href="#">Review Visionare Details {">>"}</a>
              </div>
            </form>

            <form className="form-cont">
              <div className="form-header">
                <img
                  className="form-logos"
                  src={metawheel}
                  alt="sanctuary_store"
                />
                <h4 className="form-heading">H C N</h4>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Become a Visionaire</span>
                </label>
              </div>

              <div className="list">
                <label class="custom-checkbox">
                  <input type="checkbox" />
                  <span class="helping-el"></span>
                  <span class="label-text">Receive Marketing</span>
                </label>
              </div>

              <div className="review-btn list">
                <a href="#">Review Visionare Details {">>"}</a>
              </div>
            </form>
          </div>
        </div>
      </Col>
    </>
  );
};

export default VisionaireNetworkTable2;
