import React, { useState } from "react";
import { useHistory, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReadingsTab from '../../assets/images/mind-health/readings.png';
import ReadingBanner from '../../assets/images/mind-health/reading-banner.png';
import VisualisationsTab from '../../assets/images/mind-health/visualisations.png';
import VisualisationsBanner from '../../assets/images/mind-health/visualisations-banner.png';
import BreathingTab from '../../assets/images/mind-health/breathing.png';
import AffirmationsBanner from '../../assets/images/mind-health/affirmations-banner.png';
import AffirmationsTab from '../../assets/images/mind-health/affirmations.png';
import BreathingBanner from '../../assets/images/mind-health/breathing-banner.png';
import checkIcon from '../../assets/images/check-icon.png';
import mediumPlayIcon from '../../assets/images/medium-play-icon.png';
import MindTab1 from '../../assets/images/mind-health/mind-tab1.png';
import './index.scss';
import { eventClick } from "@syncfusion/ej2-react-schedule";

const MindWorkoutTab = () => {
    const [showTabs, tabChange] = useState(0);
    const history = useHistory();

    const bookHandler = () => {
        return history.push('/booking-plans')
    }
    const dbLinks = (link) => {
        history.push(link)
    }

    return (
        <>
            <Row>
                <Col sm={12} md={12}>
                    <div className="fusion-exe">
                        <h1>Mind Health series</h1>
                    </div>
                </Col>
                <div className="fusion-gal">
                    <Row>
                        <Col xs={3} sm={3} md={3} >
                            <div className="fusion-exe-gl"  onClick={() => tabChange(1)}>
                                <img alt="not found"  onClick={() => dbLinks('/fusion-exercise-detail1')} src={AffirmationsTab} />
                                <h4>Affirmations</h4>
                                <div className={`${showTabs === 1 ? 'fu-active-gal' : ''}`}>
                                    <div className="triangle-wrapper-100">
                                        <div className="triangle-left"></div>
                                        <div className="triangle-right"></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} sm={3} md={3} onClick={() => tabChange(2)}>
                            <div className="fusion-exe-gl">
                                <img alt="not found"   onClick={() => dbLinks('/fusion-exercise-detail3')}  src={BreathingTab} />
                                <h4>Breathing Exercises & <br /> GUIDED meditations </h4>
                                <div className={`${showTabs === 2 ? 'fu-active-gal' : ''}`}>
                                    <div className="triangle-wrapper-100">
                                        <div className="triangle-left"></div>
                                        <div className="triangle-right"></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} sm={3} md={3} onClick={() => tabChange(3)}>
                            <div className="fusion-exe-gl">
                                <img alt="not found"  onClick={() => dbLinks('/fusion-exercise-detail5')} src={VisualisationsTab} />
                                <h4>Visualisations</h4>
                                <div className={`${showTabs === 3 ? 'fu-active-gal' : ''}`}>
                                    <div className="triangle-wrapper-100">
                                        <div className="triangle-left"></div>
                                        <div className="triangle-right"></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} sm={3} md={3}>
                            <div className="fusion-exe-gl" onClick={() => tabChange(4)}>
                                <img alt="not found" onClick={() => dbLinks('/fusion-exercise-detail6')} src={ReadingsTab} />
                                <h4>healing<br />readings</h4>
                                <div className={`${showTabs === 4 ? 'fu-active-gal' : ''}`}>
                                    <div className="triangle-wrapper-100">
                                        <div className="triangle-left"></div>
                                        <div className="triangle-right"></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={`benefits-container ${showTabs === 0 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1 style={{ paddingLeft: '20px', fontSize: '48px' }}>BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>Fundamental Tools and Techniques to help enable personal sovereignty over your vitality and longevity. This series may assist you in the following areas:</p>
                            <ul>
                                <li> <img alt="not found" src={checkIcon} />Establish a psychological platform to create, alter and maintain nutritional optimisation, exercise regimens, and stay the course of an advancement program.</li>
                                <li> <img alt="not found" src={checkIcon} />Reprogram the conscious and subconscious to establish new reward mechanisms and make habit forming initiatives easier and more successful.</li>
                                <li> <img alt="not found" src={checkIcon} />Aid greater physiological efficiencies for improved immunity, healing and health span, relaxing the sympathetic nervous system, activating the placebo effect and influencing gene expression.</li>
                                <li> <img alt="not found" src={checkIcon} />Greater toxin release, better circulation, appearance, radiance, state of mind, and energy levels through simple exercises and routines.</li>
                                <li> <img alt="not found" src={checkIcon} />Better mental fitness, clarity and focus to engage and enjoy life more from a balanced, progressive and positive perspective.</li>
                                <li> <img alt="not found" src={checkIcon} />Stress and depression management, better sleep, vagal tone, emotional vibrancy and more authentic happiness from within.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${MindTab1})` }}>
                        <div className="fu-dis">
                            <div className="fu-head">
                                <h2>Mind Health Series</h2>
                            </div>
                            <div className="fu-dis-con">
                                <div className="fu-dis-txt">

                                    <h4>Description: </h4>

                                    <p><b>A positive mindset is an accelerator to greater health, healing, self-advancement and general success in life. The Mind Health series is presented in an easy to follow format which is highly enjoyable and inspirational for beginners through to experienced mindfulness practitioners alike. The Mind Health series can be used alone to re-orientate the mind towards greater happiness and wellbeing, or as the ideal positive psychology series to achieve greater sports performance, weight shifting, health and healing in co-ordination with the other Sanctuary health services. </b></p>
                                </div>
                                <div className="fu-dis-time">
                                    {/* <p><span>DURATION</span>：10mins / 20mins</p>
                                    <p><span>MASTER</span>：Patricia Hart</p> 
                                */}
                                </div>
                                <div className="tab-btn">
                                    {/* <button onClick={() => history.push('/fusion-exercise-detail1')}>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                    <button onClick={() => history.push('/fusion-exercise-detail2')}>BOOK THIS</button>
                               */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`benefits-container ${showTabs === 1 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1 style={{ fontSize: '46px' }}>affirmation BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>Affirmations reinforce positive thinking and can also be used as internal dialogue prompts to re-orientate thinking and support wise decision making. Affirmations may assist you in the following areas:</p>
                            <ul>
                                <li> <img alt="not found" src={checkIcon} />Aid the reprograming of thinking to reflect what you are truly striving towards.</li>
                                <li> <img alt="not found" src={checkIcon} />Assist positive psychology regimens when used with other Techniques.</li>
                                <li> <img alt="not found" src={checkIcon} />Become more empowered by engaging specific ‘word Tools’ into Techniques to more quickly effect positive change.</li>
                                <li> <img alt="not found" src={checkIcon} />Make you feel more self-assured, confident and hence focused - which leads to greater action towards set goals.</li>
                                <li> <img alt="not found" src={checkIcon} />Interlock with positive feedback loops that match consequential real world action and events to evidence advancement – reinforcing continued action and advancement.</li>
                                <li> <img alt="not found" src={checkIcon} />Can be done almost anywhere to up lift oneself and help build a more positive state of mind and hence quality of life.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${AffirmationsBanner})` }}>
                        <div className="fu-dis">
                            <div className="fu-head">
                                <h2>affirmations</h2>
                            </div>
                            <div className="fu-dis-con">
                                <div className="fu-dis-txt">
                                    <h4>Description: </h4>
                                    <p>Gain access to dozens of well-articulated and correctly crafted affirmations, which contain the most impact, to instil the right message to the subconscious. Delivered with inspiration by various individuals and facilitators to listen, participate and retain to memory oneself for future use. Ideal to assist you in achieving any health initiative.</p>
                                </div>
                                <div className="fu-dis-time">
                                    <p><span>DURATION</span>：10mins / 20mins</p>
                                    <p><span>MASTER</span>：Patricia Hart</p>
                                </div>
                                <div className="tab-btn">
                                    {/* <button>PREVIEW<img alt="not found" src={smalPlayIcon} /></button> */}
                                    <button onClick={() => history.push('/fusion-exercise-detail1')}>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                    <button onClick={() => history.push('/fusion-exercise-detail2')}>BOOK THIS</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`benefits-container ${showTabs === 2 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1 style={{ fontSize: '38px' }}>Breathing Exercise &<br /> guided meditation<br /> BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>Deep breathing is the core of meditation, relaxation, oxygenation and energisation of the mind - body system. Meditation is both the stillness and focus of the mind to refresh and restore mental wellbeing. Deep breathing and meditation exercises may assist you in the following areas: </p>
                            <ul>
                                <li style={{ marginTop: '10px', marginBottom: '20px' }}> <img alt="not found" src={checkIcon} />Deep breathing is one of the quickest and most effective methods to relax the mind-body by triggering the parasympathetic nervous system- instructing the body to become calm once again, relieving anxiety.</li>
                                <li style={{ marginBottom: '20px' }}> <img alt="not found" src={checkIcon} />Deep breathing assists blood oxygen levels, alkalisation, cardio respiratory and mitochondria function, while allowing the immune system to fully function once again.</li>
                                <li style={{ marginBottom: '20px' }}> <img alt="not found" src={checkIcon} />Meditation hinged off deep breathing provides the perfect mind-body state to practise stillness and more easily focus and strengthen the mind to process and transcend anxiety.</li>
                                <li style={{ marginBottom: '20px' }}> <img alt="not found" src={checkIcon} />Meditation provides the perfect state to introduce any proactive messaging for the subconscious to pick up on. Guided meditations based around forgiveness, blessing, gratitude and love can potentially aid health and healing. </li>
                                <li style={{ marginBottom: '20px' }}> <img alt="not found" src={checkIcon} />Learn how to use other powerful Tools to interlock with any meditation practise. Deep breathing and mediation enables better sleep, vagal tone, mental clarity, and sense of; self, higher self, and oneness.</li>
                                <li style={{ marginBottom: '20px' }}> <img alt="not found" src={checkIcon} /> Deep breathing and meditation are the ideal forerunner practises, to the Creative Visualisation process, which requires the practise of these mental disciplines for the best uptake of positive messaging to the subconscious.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${BreathingBanner})`, height: '900px' }}>
                        <div className="fu-dis">
                            <div className="fu-head">
                                <h2>guided meditations & Breathing Exercise</h2>
                            </div>
                            <div className="fu-dis-con">
                                <div className="fu-dis-txt">
                                    <h4>Description: </h4>
                                    <p>Deep meditative breathing is instrumental to your holistic health, while guided meditation is the extension application. Simple yet highly effective this series is beneficial in reconnecting you to you, and that which you are a part of: nature, the universe, God.</p>
                                    <p>Become master at relaxing and reasserting calm and wellness as a health state, regardless of exterior circumstances. This series emphasises four guided meditation themes of forgiveness, blessing, gratitude and love, which are associated with better healing, health and vibrancy.</p>
                                </div>
                                <div className="fu-dis-time">
                                    <p><span>DURATION</span>：10mins / 20mins</p>
                                    <p><span>MASTER</span>：Patricia Hart</p>
                                </div>
                                <div className="tab-btn">
                                    <button onClick={() => history.push('/fusion-exercise-detail3')}>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                    <button onClick={() => history.push('/fusion-exercise-detail4')}>BOOK THIS</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`benefits-container ${showTabs === 3 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1>Visualisation BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>Used in current times by top athletes, Nasa, entrepreneurs, designers and by inventors, visionaries and the spiritually aware for centuries. Visualisation is the creation process that evolves through a powerfully focused and trouble free mind that has been given insight and licence to manifest. Visualisation may assist you in the following areas:</p>
                            <ul>
                                <li> <img alt="not found" src={checkIcon} style={{ marginTop: '10px', marginBottom: '20px' }} />Learn how to engage all senses and focus the mind intently to manifest that which you most desire for your biological form. </li>
                                <li> <img alt="not found" src={checkIcon} style={{ marginBottom: '20px' }} />Use the creation process to heal, gain better sports performance, and achieve more extraordinary things – you were perhaps told you could not. </li>
                                <li> <img alt="not found" src={checkIcon} style={{ marginBottom: '20px' }} />A valuable aid to assist you in very long term health ambitions, where personal resolve needs to be held on an on-going basis in the face of opposing opinion, current information or perception.</li>
                                <li> <img alt="not found" src={checkIcon} style={{ marginBottom: '20px' }} />Assists in energising that what you seek to manifest, while also bringing greater joy and fulfilment to the now - or current day life experiences.</li>
                                <li> <img alt="not found" src={checkIcon} style={{ marginBottom: '20px' }} />Exciting to do with a partner to manifest a shared health outcome that you can both benefit from. Powerfully instils hope, belief and purpose.</li>
                                <li> <img alt="not found" src={checkIcon} style={{ marginBottom: '20px' }} />A creative process that exercises your self-ability and enlightens you to your own magnificence, held within the workings of the Self Protecting and Healing Mind - Body System. </li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${VisualisationsBanner})` }}>
                        <div className="fu-dis">
                            <div className="fu-head">
                                <h2>visualisations</h2>
                            </div>
                            <div className="fu-dis-con">
                                <div className="fu-dis-txt">

                                    <h4>Description: </h4>

                                    <p>Creative visualisation is the pinnacle of the minds ability to manifest that which is seemingly impossible or far from reach. It is only through such a process that all things become possible. This series delivers the core mechanisms for you to practise, to energise and draw into your life the very aspects you need to advance your health, wrapped within set visualisations to spring board off.</p>
                                </div>
                                <div className="fu-dis-time">
                                    <p><span>DURATION</span>：10mins / 20mins</p>
                                    <p><span>MASTER</span>：Patricia Hart</p>
                                </div>
                                <div className="tab-btn">
                                    <button onClick={() => history.push('/fusion-exercise-detail5')}>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                    <button onClick={() => history.push('/fusion-exercise-detail5')}>BOOK THIS</button>
                                </div>
                                <div className="fu-dis-time">
                                    <a>Sculpt an Articulate Health Visualisation (AHV) especially for your needs</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={`benefits-container ${showTabs === 4 ? 'show' : 'hide'}`}>
                    <div className="benefits-container_points">
                        <h1>healing <br />readings BENEFITS</h1>
                        <div className="gal-con-text">
                            <p>Miracles do occur in life, they happen every day if we are open to them, they are gifts of love, forgiveness, compassion, and joy. By being reminded of these miracles we entertain the possibility of their acting in our own lives, as opposed to purely entertaining a narrative that hinders our health and healing. Healing readings appease the endocrine and hormonal systems, relax the nervous system, positively aid neurotransmitters such as serotonin and dopamine and release the powerful love hormone and neurotransmitter - oxytocin. Healing readings may assist you in the following areas:</p>
                            <ul>
                                <li> <img alt="not found" src={checkIcon} />Provide an uplift in ambiance, mindset, and energy to enhance ones perception of their health potential through a powerful piece of literature. </li>
                                <li> <img alt="not found" src={checkIcon} />Allows your mind to recognise and become open to supportive beliefs that work with the mechanisms of the creative process, so that you work more effectively towards healing yourself.</li>
                                <li> <img alt="not found" src={checkIcon} />Draws upon the shared practice of healer – patient relationship instigative to an amplified placebo effect.</li>
                                <li> <img alt="not found" src={checkIcon} />Builds emotional states of forgiveness, gratitude, blessing and love associated with effecting the endocrine, nervous and immune systems for greater healing potential.</li>
                                <li> <img alt="not found" src={checkIcon} />Allows the mind to become open to new possibilities, and the science associated with miracle and faith healings, which works in parallel with the Self Protecting and Healing Mind - Body System. </li>
                                <li> <img alt="not found" src={checkIcon} />A highly enjoyable experience which is soothing and comforting in its own right, regardless of health outcome, which contributes to better quality of life.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="benefits-container_img-container" style={{ backgroundImage: `url(${ReadingBanner})`, height: '850px' }}>
                        <div className="fu-dis">
                            <div className="fu-head">
                                <h2>healing readings</h2>
                            </div>
                            <div className="fu-dis-con">
                                <div className="fu-dis-txt">

                                    <h4>Description: </h4>

                                    <p>Healing readings are an innovative way to inspirationally assist you with what you are going through. Sometimes it’s hard to even practise the skills that we know will benefit us, and we need an extra lift to get going and start our own healing process. Healing readings are beautiful, soulful passages told by one of our facilitators, utilising ‘shared belief placebo’ and ‘ritual placebo’, to lift your spirits and open your mind to; the potential of the mind-body’s ability to heal, the miracles that do occur in everyday life, and the general awe and wonder of nature and God. Sit back and soak in the positive energy and ambiance of a healing reading.</p>
                                </div>
                                <div className="fu-dis-time">
                                    <p><span>DURATION</span>：10mins / 20mins</p>
                                    <p><span>MASTER</span>：Patricia Hart</p>
                                </div>
                                <div className="tab-btn">
                                    <button onClick={() => history.push('/fusion-exercise-detail6')}>WATCH <img alt="not found" src={mediumPlayIcon} /></button>
                                    <button onClick={() => history.push('/fusion-exercise-detail7')}>BOOK THIS</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </>
    );
}

export default MindWorkoutTab;