import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import "./index.scss";
import FooterBanner from "../../components/footer-banner";
import OptimiseReport from "../../components/optimise-report";
import B1 from "../../assets/images/holistic-system/bg1.png";
import B2 from "../../assets/images/holistic-system/bg2.png";
import B3 from "../../assets/images/holistic-system/bg3.png";
import ClusterIcons from "../../components/cluster-icons";
import FiveMen from "../../components/five-men";
import CommonImage1 from "../../assets/images/holistic-system/s1-1.png";
import MainImage1 from "../../assets/images/holistic-system/s1-2.png";
import CommonImage2 from "../../assets/images/holistic-system/s2-1.png";
import MainImage2 from "../../assets/images/holistic-system/s2-2.png";
import CommonImage3 from "../../assets/images/holistic-system/s3-1.png";
import MainImage3 from "../../assets/images/holistic-system/s3-2.png";
import CommonImage4 from "../../assets/images/holistic-system/s4-1.png";
import MainImage4 from "../../assets/images/holistic-system/s4-2.png";
import CommonImage5 from "../../assets/images/holistic-system/s5-1.png";
import MainImage5 from "../../assets/images/holistic-system/s5-2.png";
import TestTube from "../../assets/images/holistic-system/testTube.png";
import WhiteEarth from "../../assets/images/holistic-system/whiteEarth.png";
import CustomerReviews from "../../components/customer-reviews";
import Star from "../../assets/images/health-package/star.png";
import IvanM from "../../assets/images/reviews/IvanM.jpg";
import GaryJ from "../../assets/images/reviews/GaryJ.jpg";
//import ph_ppbw from "../../assets/images/reviews/placeholder_pp_bg-removed-bw.png";
import Mari from "../../assets/images/reviews/Mari.jpg";
import Graeme from "../../assets/images/reviews/Graeme.png";
import Mike from "../../assets/images/reviews/Mike.png";
import Anthea from "../../assets/images/reviews/Anthea.jpeg";
import kimmcw from "../../assets/images/reviews/kimmcw.png";
import SanctuaryServices from "../../components/sanctuary-services";
import Disclaimer from "../../components/disclaimer";
import Footer from "../../components/Footer/Footer";

const Index = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dbLinks = (link) => {
    history.push(link);
  };
  const reviews = [
    {
      ppic: Anthea,
      stars: 5,
      customer: "Anthea Baker",
      tab: "Inhouse Health Testing",
      review:
        "With advanced and comprehensive program systems and health testing equipment.",
      date: "20/03/2023",
    },
    {
      ppic: Graeme,
      caption: "Incredible equipment. Information obtained quite amazing",
      stars: 5,
      customer: "Graeme Lamond",
      customerTitle: "Business owner",
      tab: "Inhouse Health Testing",
      review:
        "Well researched and great use of equipment for testing. The data and parameters used for testing and results are very comprehensive but Kim is great at breaking it all down into easy-to-understand concepts.",
      date: "13/12/2022",
    },
    {
      ppic: Mike,
      caption: "The technology is world class…",
      stars: 5,
      customer: "Mike Lough",
      customerTitle: "Business owner",
      tab: "Inhouse Health Testing",
      review:
        "The technology is world class and extremely beneficial to gain knowledge about my body and get exact data on my personal progress. Knowledge is key. Get the data and use that knowledge to your own personal gain.",
      date: "05/07/2023",
    },
    {
      ppic: Mari,
      caption: "The testing equipment completed the picture…",
      stars: 5,
      customer: "Mari Gordon",
      tab: "Inhouse Health Testing",
      review:
        "The testing equipment completed the picture of how we can optimise our health by showing what areas we are doing well and areas that need improvement.",
      date: "13/07/2023",
    },
    {
      ppic: IvanM,
      caption: "Advanced and comprehensive",
      stars: 5,
      customerTitle: "Chartered accountant",
      customer: "Ivan Middlemost",
      tab: "Inhouse Health Testing",
      review:
        "Advanced and comprehensive (the programs systems and health testing equipment).",
      date: "27/01/2023",
    },
    {
      ppic: kimmcw,
      caption: "“Enjoy the testing”",
      stars: 5,
      customerTitle: "Director NZ PWN",
      customer: "Kim McWilliam’s",
      tab: "Inhouse Health Testing",
      review:
        "Advanced and comprehensive (the programs systems and health testing equipment).",
      date: "16/12/2022",
    },

    {
      ppic: GaryJ,
      caption: "Above expectation",
      stars: 5,
      customer: "Gary Jamieson",
      customerTitle: "Insurance Brokers Limited",
      tab: "Inhouse Health Testing",
      review:
        "Above expectation (the program systems and health testing equipment).",
      date: "13/12/2023",
    },
  ];
  return (
    <div className="holistic_system">
      <Header />
      <div className="section1">
        <div className="section1_banner">
          <div className="section1_banner_head">
            <div className="section1_banner_head_border"></div>
            {`Advanced Holistic Health Testing Systems & Technology`}
          </div>
          <div>
            <div className="section1_banner_side side1">
              <a href="#Sanctuary Inhouse Testing">
                <img src={B1} alt="img" />
                Sanctuary Inhouse Testing
              </a>
            </div>
            <div className="section1_banner_side side2">
              <a href="#optimise reports">
                <img src={B2} alt="img" />
                optimise reports
              </a>
            </div>
            <div className="section1_banner_side side3">
              <a href="#Scientific Testing">
                <img src={B3} alt="img" />
                Scientific Testing
              </a>
            </div>
          </div>
        </div>
        <ClusterIcons />
      </div>
      <FiveMen />
      <div id="Sanctuary Inhouse Testing" className="section2">
        Sanctuary Inhouse Testing
        <div className="section2_desc">
          The Sanctuary offers the latest range of completely non–invasive
          health testing services to assimilate the most vital information
          required for preventative health and health evolution: Body
          Composition, Visceral Fat, Phase Angle, Extra Cellular Water,
          Nutritional Profiling, Toxin and Environmental Factors, Frequency
          Interference, Heart, Blood Pressure and Blood Oxygen, and Skin
          Condition.{" "}
        </div>
      </div>
      <div className="commonSection" style={{ backgroundColor: "#F5F5F5" }}>
        <div className="commonSection_content">
          <div
            className="commonSection_content_image"
            style={{ backgroundImage: `url(${CommonImage1})` }}
          >
            <div>
              Inbody 770 body composition scanner
              <div className="commonSection_content_image_desc">
                Highly accurate, medically approved, with visceral fat and Phase
                Angle scanning to track your physique and general health.
              </div>
              <div
                className="commonSection_content_image_more"
                onClick={() => dbLinks("/store-service-inbody-scanning")}
              >
                <img src={WhiteEarth} alt="earth" />
                READ MORE
              </div>
            </div>
          </div>
          <div className="commonSection_content_text">
            At the Sanctuary we use the top of the line Inbody Scanner, the
            Inbody 770. A highly accurate, medically approved body composition
            scanner which can accurately measure, report and track your physique
            and general health.
            <br />
            <br />
            Each scan provides a detailed report and health score of your
            physical composition including; muscle, bone and fat mass, and water
            composition analysis (ECW). Importantly this machine can measure
            visceral fat to the percentage point - so that you can rescan more
            regularly to ensure the program you are on is effective.
            <br />
            <br />
            This is also the only scanner that can provide information on Phase
            Angle for more specialised health and anti-aging information. The
            true age of a human being can be determined by changes in PhAs, a
            remarkable window into the health of the body, which decreases with
            age, however when you increase your PhAs, you slow down aging.
          </div>
          <div className="commonSection_content_main">
            <div className="commonSection_content_main_title">
              INBODY SCANNER
            </div>
            <img src={MainImage1} alt="img" style={{ width: "230px" }} />
            <div className="commonSection_content_main_desc">
              Inbody uses Bioelectrical Impedance Analysis, which is a
              completely safe method for measuring body composition. However BIA
              should not be used by anyone with an electronic medical implant,
              such as a heart pacemaker or an implantable cardioverter
              defibrillator (ICD).
            </div>
          </div>
        </div>
      </div>
      <div className="commonSection">
        <div className="commonSection_content">
          <div className="commonSection_content_main">
            <div className="commonSection_content_main_title">HAIR TESTING</div>
            <div
              style={{
                textAlign: "left",
                position: "relative",
                left: "30px",
                fontSize: "18px",
              }}
            >
              Cell Wellbeing
            </div>
            <img src={CommonImage2} alt="img" height="60px" width="auto" />
            <div className="commonSection_content_main_desc">
              {`Personalized Epigenetic Nutritional & Wellbeing Reports`}
            </div>
          </div>
          <div
            className="commonSection_content_image"
            style={{ backgroundImage: `url(${MainImage2})` }}
          >
            <div className="commonSection_content_image_darken" />
            <div>
              Nutritional and Environmental Markers
              <div className="commonSection_content_image_desc">
                German Based Digital Epigenetic Mapping Service
              </div>
            </div>
            <div
              className="commonSection_content_image_more"
              onClick={() => dbLinks("/tech-hair-testing")}
            >
              <img src={WhiteEarth} alt="earth" />
              READ MORE
            </div>
          </div>
          <div className="commonSection_content_text">
            Benefit from a full 30+ page report, which includes over 800
            nutritional and environmental markers. This personalised data is
            delivered within 20 minutes via a German Based Digital Epigenetic
            Mapping Service, all from the scan of four of your hair strands and
            roots. The Cell Wellbeing test helps to provide a piece of the
            puzzle around personalised nutritional epigenetic mapping, as well
            as personalised optimisation strategies.
            <br />
            <br />
            Your epigenetic report highlights potential functional impacts
            caused by Environmental Toxins, Electro Magnetic Impacts,
            Nutritional Diet, Life Style and Underlying Food Stressors. Each
            report also provides nutritional advice on optimising your
            wellbeing. The Sanctuary uses this specific test to help evaluate a
            wide range of nutritional information including: Vitamins, Minerals,
            Amino Acids, Fatty Acids, and Anti-Oxidants to help craft the
            nutritional optimisation of meal plans.
            <span style={{ fontSize: "12px" }}>
              Cell Wellbeing is compliant with FDA rulings
            </span>
          </div>
        </div>
      </div>
      <div className="commonSection" style={{ backgroundColor: "#F5F5F5" }}>
        <div className="commonSection_content">
          <div
            className="commonSection_content_image"
            style={{ backgroundImage: `url(${CommonImage3})` }}
          >
            <div className="commonSection_content_image_darken" />
            <div>
              Authentic beauty and radiant skin
              <div className="commonSection_content_image_desc">
                Pin point the condition of your skin and avoid long term damage
                with our state of the art skin testing service.
              </div>
            </div>
            <div
              className="commonSection_content_image_more"
              onClick={() => dbLinks("/store-service-skin-analysis")}
            >
              <img src={WhiteEarth} alt="earth" />
              READ MORE
            </div>
          </div>
          <div className="commonSection_content_text">
            At the Sanctuary we believe that great skin is a reflection of inner
            health, and that full health and hence true beauty shows itself
            through the radiance of the eyes, hair and skin. Health and Beauty
            is represented by what is going on inside of your mind-body, and by
            the way you protect, nurture and nourish your skin and hair.
            <br />
            <br />A skin test will reveal not just the condition of your skin,
            and how to externally look after it, but also contributes to an
            overview of our comprehensive heath testing services. This holistic
            health map can identify toxins, nutrient deficiencies, as well as
            other health conditions which impact appearance, all important
            information that can then be cross referenced to integrate a natural
            improvement program for you to achieve greater Vitality and
            Longevity - which will involve glowing, youthful skin.
            <br />
            <br />
            We recommend using the fully natural and nutrient rich Vision
            Products skin and hair care range, along with specialised treatments
            to compliment this authentic beauty approach.
          </div>
          <div className="commonSection_content_main">
            <div className="commonSection_content_main_title">
              Skin Analyser
            </div>
            <img src={MainImage3} alt="img" />
            <div className="commonSection_content_main_desc">
              Cross reference tests and gain a window into nutritional
              optimisation, environmental challenges and toxins,
              cardiorespiratory and cardiovascular health to achieve the very
              best beauty appearance possible.
            </div>
          </div>
        </div>
      </div>
      <div className="commonSection">
        <div className="commonSection_content">
          <div className="commonSection_content_main">
            <div className="commonSection_content_main_title">
              Finger Pulse Oximeter
            </div>
            <img src={CommonImage4} alt="img" height="60px" width="auto" />
          </div>
          <div
            className="commonSection_content_image"
            style={{ backgroundImage: `url(${MainImage4})` }}
          >
            <div className="commonSection_content_image_darken" />
            <div>
              Blood oxygen saturation
              <div className="commonSection_content_image_desc">
                Cardiorespiratory and mitochondria efficiency
              </div>
            </div>
          </div>
          <div className="commonSection_content_text">
            Oxygen is transported to the blood within the body through the
            respiratory system and blood oxygen can be potentially influenced by
            fresh air, water intake, iron rich foods, exercise, and breathing
            exercise for additional amounts of oxygen to the lungs and greater
            energy. This Finger Pulse Oximeter is a simple, yet accurate way for
            spot-checking blood oxygen saturation levels (and pulse rate)
            through a fast, and pain free process by simply attaching the device
            to the finger.
          </div>
        </div>
      </div>
      <div className="commonSection" style={{ backgroundColor: "#F5F5F5" }}>
        <div className="commonSection_content">
          <div
            className="commonSection_content_image"
            style={{ backgroundImage: `url(${CommonImage5})` }}
          >
            <div className="commonSection_content_image_darken" />
            <div>
              Blood Pressure Monitor
              <div className="commonSection_content_image_desc">
                Keeping an eye on influencing life style factors
              </div>
            </div>
          </div>
          <div className="commonSection_content_text">
            Increased blood pressure can cause health issues and primary
            hypertension is high blood pressure that develops gradually over
            time. High blood pressure is most common in adults and is influenced
            by lifestyle habits such as regular exercise, weight control,
            healthy diet, and limiting stress, alcohol and tobacco.
          </div>
          <div className="commonSection_content_main">
            <div className="commonSection_content_main_title">
              Blood Pressure Monitor
            </div>
            <img src={MainImage5} alt="img" />
          </div>
        </div>
      </div>
      <div id="optimise reports">
        <OptimiseReport showHead />
      </div>
      <div
        id="Scientific Testing"
        className="section2"
        style={{ paddingBottom: "140px" }}
      >
        Precision Functional Health Testing
        <div className="section2_desc">
          Fully understand your unique body and its perplexities, with specific
          health tests, to gain the transparency you need to advance your health
          state. Functional pathology tests are used to investigate functional,
          biochemical, nutritional, metabolic and hormonal status. Which can
          assist practitioners in the diagnosis, treatment and management of
          patients seeking a holistic approach to health.{" "}
        </div>
        <button type="button" class="btn btn-outline-secondary section2_btn">
          <a href="mailto:kim@visionproducts.co.nz?subject=Enquire%20Precision%20Functional%20Health%20Testing">
            Enquire Now
          </a>
        </button>
      </div>
      <div className="section3" style={{ backgroundColor: "#F5F5F5" }}>
        <div className="section3_content">
          <div className="section3_content_container">
            <img src={TestTube} alt="TestTube" />
            <p>
              Functional tests focus on actual bodily functions as markers of
              health, rather than merely looking at disease states and markers
              of disease, which can also assist in establishing preventive
              health measures.
            </p>
            <div></div>
          </div>
          <div className="section3_content_dropDowns">
            <div className="section3_content_dropDowns_item">
              <div>Standard blood test</div>
              <div>+</div>
            </div>
            <div className="section3_content_dropDowns_item">
              <div>Complete Microbiome Mapping</div>
              <div>+</div>
            </div>
            <div className="section3_content_dropDowns_item">
              <div>IgA/IgE/lgG Elisa Test</div>
              <div>+</div>
            </div>
            <div className="section3_content_dropDowns_item">
              <div>eve test</div>
              <div>+</div>
            </div>
            <div className="section3_content_dropDowns_item">
              <div>DUTCH Test</div>
              <div>+</div>
            </div>
            <div className="section3_content_dropDowns_item">
              <div>Organic Acids test</div>
              <div>+</div>
            </div>
            <div className="section3_content_dropDowns_item">
              <div>DNA Testing</div>
              <div>+</div>
            </div>
          </div>
        </div>
      </div>
      <div className="health-testing-common">
        <div className="health-testing">
          Health Testing Services
          <div className="health-testing_desc">
            <p>
              Inquire about these individual testing services or consider our
              $410.00 Health Testing Package, which includes a combination of
              our Inhouse Testing and a personal consult to understand your bio
              blue-print and potential health enhancements.
            </p>
          </div>
          <button
            type="button"
            class="btn btn-outline-secondary health-testing_desc_btn"
          >
            <a href="mailto:kim@visionproducts.co.nz?subject=Enquire%20Health%20Testing">
              Enquire Now
            </a>
          </button>
        </div>
      </div>

      <div className="boldText">
        Create your complete health map with Sanctuary health testing to
        establish preventative health and health evolution measures
      </div>
      <div className="customers">
        <div className="customers_content">
          <div className="customers_content_star">
            <div className="customers_content_star_title">
              <img alt="not found" src={Star} title="WRITE REVIEWS" />
              <div className="customers_content_head">
                <h1>Customer Reviews</h1>
              </div>
            </div>
          </div>

          <CustomerReviews reviews={reviews} />
        </div>
      </div>
      <SanctuaryServices />
      <Disclaimer />
      <FooterBanner />
      <Footer />
    </div>
  );
};

export default Index;
