import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Drawer, List, ListItem, ListItemText, Grid} from '@material-ui/core';
import Logo from '../../../assets/images/logo.png';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  class0: {
    width: '100%',
    margin: 0,
  },
  navLogo: {
    maxWidth: '100%',
  },
}));

const Sidemenu = (props) => {
  const {sidemenuOpen, setSidemenuOpen, routes2, historyPush} = props;
  const classes = useStyles();

  const routesMod = (routes) => {
    return routes.map((element) => {
      let obj = {...element};
      if (obj.hasOwnProperty('submenu')) {
        if (obj.name === obj.submenu[0].name) {
          obj = obj.submenu[0];
        }
        obj.submenu = routesMod(obj.submenu);
        return obj;
      } else {
        return obj;
      }
    });
  };
  const routes = routesMod(routes2);
  const collapseStateGen = (routes) => {
    return routes.reduce((acc, cv) => {
      if (cv.hasOwnProperty('submenu')) {
        return {...acc, ...collapseStateGen(cv.submenu), [cv.name]: false};
      } else {
        return acc;
      }
    }, {});
  };
  const [collapse, setCollapse] = useState({
    sanctuaryStore: false,
    urbanSanctuary: false,
    masterSanctuary: false,
    ...collapseStateGen(routes),
  });

  const clickHandle = (element) => {
    if (element.hasOwnProperty('url')) {
      window.open(element.url, '_blank');
    } else {
      historyPush(element.path);
    }
  };
  const rendermenu = (routes, paddingLeft = 16) => {
    return routes.map((element, index) => {
      if (element.hasOwnProperty('submenu')) {
        return (
          <React.Fragment key={element.name + index}>
            {element.side && (
              <ListItem
                button
                onClick={() => setCollapse({...collapse, [element.name]: !collapse[element.name]})}
                style={{paddingLeft}}
              >
                <ListItemText primary={element.name} />
                {collapse[element.name] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse in={collapse[element.name]} timeout="auto" unmountOnExit>
              {rendermenu(element.submenu, paddingLeft + 4)}
              {!element.noPath && element.side && (
                <ListItem
                  button
                  style={{paddingLeft: paddingLeft + 4}}
                  onClick={() => {
                    clickHandle(element);
                  }}
                >
                  <ListItemText primary={`View all ${element.name}`} />
                </ListItem>
              )}
            </Collapse>
          </React.Fragment>
        );
      } else {
        if (element.side) {
          return (
            <ListItem
              key={element.name}
              button
              style={{paddingLeft}}
              onClick={() => {
                clickHandle(element);
              }}
            >
              <ListItemText primary={element.name} />
            </ListItem>
          );
        } else {
          return null;
        }
      }
    });
  };

  return (
    <Drawer
      anchor="left"
      open={sidemenuOpen}
      onClose={() => setSidemenuOpen(false)}
      PaperProps={{style: {width: 276, overflowY: 'overlay'}}}
    >
      <Grid container spacing={2} className={classes.class0}>
        <Grid container item xs={12} spacing={4} justify="center" alignItems="center" className={classes.class0}>
          <Grid item xs={9}>
            <img className={classes.navLogo} src={Logo} alt="Logo" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListItem button onClick={() => setCollapse({...collapse, sanctuaryStore: !collapse.sanctuaryStore})}>
              <ListItemText primary={'Sanctuary Store'.toUpperCase()} />
            </ListItem>
            <Collapse in={collapse.sanctuaryStore} timeout="auto" unmountOnExit>
              
            </Collapse>

            <ListItem button onClick={() => setCollapse({...collapse, urbanSanctuary: !collapse.urbanSanctuary})}>
              <ListItemText primary={'Urban Sanctuary'.toUpperCase()} />
              {collapse.urbanSanctuary ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={collapse.urbanSanctuary} timeout="auto" unmountOnExit>
            {rendermenu(routes)}
            </Collapse>

            <ListItem button onClick={() => setCollapse({...collapse, masterSanctuary: !collapse.masterSanctuary})}>
              <ListItemText primary={'Master Sanctuary'.toUpperCase()} />
            </ListItem>
            <Collapse in={collapse.masterSanctuary} timeout="auto" unmountOnExit></Collapse>
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default Sidemenu;
