import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Slider from "react-slick";
import Footer from '../../components/Footer/Footer';
import FooterBanner from '../../components/footer-banner';
import sliderImg1 from '../../assets/images/vision/slider-img1.png'
import sliderImg2 from '../../assets/images/vision/slider-img2.png'
import sliderImg3 from '../../assets/images/vision/slider-img3.png'
import sliderImg4 from '../../assets/images/vision/slider-img4.png'
import sliderImg5 from '../../assets/images/vision/slider-img5.png'

import "./index.scss";



const VisionProducts = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    arrow: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="vision-products-wrapper page-w">
      <Header />
      <div className='vision-products_banner'>
        <Slider {...settings}>
          <div className="slider-sec">
            <div className="slider-text">
              <div className="txt-line"></div>
              <h1>Vision Products</h1>
              <p>Take back the power to achieve your greatest health span, experience the best natural products to enrich your personal beauty, vitality and longevity, while nurturing our planet through pure products made via
                Biospherically Correct principles</p>
              <button>Visit our vision products website </button>
            </div>
          </div>
          <div className="slider-sec">
            <div className="slider-text">
              <div className="txt-line"></div>
              <h1>Vision Products</h1>
              <p>Take back the power to achieve your greatest health span, experience the best natural products to enrich your personal beauty, vitality and longevity, while nurturing our planet through pure products made via
                Biospherically Correct principles</p>
              <button>Visit our vision products website </button>
            </div>
          </div>
          <div className="slider-sec">
            <div className="slider-text">
              <div className="txt-line"></div>
              <h1>Vision Products</h1>
              <p>Take back the power to achieve your greatest health span, experience the best natural products to enrich your personal beauty, vitality and longevity, while nurturing our planet through pure products made via
                Biospherically Correct principles</p>
              <button>Visit our vision products website </button>
            </div>
          </div>
        </Slider>
        <div className="txt-title">
          <p>Natural and ethical Biospherically Correct life style products </p>
          <h2>VISION PRODUCTS</h2>
        </div>
        <div className="img-slider">
          <div className="card-sec">
            <img alt="not found" src={sliderImg1} />
            <h3>Superfood Skin Care</h3>
          </div>
          <div className="card-sec">
            <img alt="not found" src={sliderImg2} />
            <h3>Eco Hair and Body Care</h3>
          </div>
          <div className="card-sec">
            <img alt="not found" src={sliderImg3} />
            <h3>Coconut Oil</h3>
          </div>
          <div className="card-sec">
            <img alt="not found" src={sliderImg4} />
            <h3>Super-soft Eco Apparel</h3>
          </div>
          <div className="card-sec">
            <img alt="not found" src={sliderImg5} />
            <h3>Natures Greeting Cards</h3>
          </div>
        </div>
      </div>
      <FooterBanner />
      <Footer />
    </div>
  );
};
export default VisionProducts;
