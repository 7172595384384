import React, { useEffect } from 'react';
import Header from '../../components/Header/Header';

import { Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import Footer from '../../components/Footer/Footer';
import FooterBanner from '../../components/footer-banner';
import User1 from '../../assets/images/discover/user1.png';
import User2 from '../../assets/images/discover/user2.png';
import Food from '../../assets/images/discover/food.png';
import LogoIcon from '../../assets/images/discover/logo-icon.png';
import Mob1 from '../../assets/images/discover/mob1.png';
import Icons from '../../assets/images/discover/icons.png';
import Mob2 from '../../assets/images/discover/mob2.png';
import Metawheel1 from '../../assets/images/discover/metawheel1.png';
import Metawheel2 from '../../assets/images/discover/metawheel2.png';
import Metawheel3 from '../../assets/images/discover/metawheel3.png';

import './index.scss';
const Discover = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="discover-warper">
      <Header />
      <Row>
        <Col sm={12} md={12}>
          <div className="banner-secss">
            <div className="content-sec">
              <div className='top-line'></div>
              <h1>SPECIALISED HUMAN ADVANCEMENT APPS</h1>
              <p>Discover yourself. Discover your Health, Prosperity,
                Inspiration and Joy. Discover your Magnificence!</p>
              <div className='rect-section'>
                <p>Our specialised Apps interlock all of the Urban Sanctuary and Sanctuary Store services
                  and work in unison, bringing you streamlined functionality to achieve greater holistic
                  human advancement, health span, and life force. Unlock the pathways to greater
                  significance, meaning and life purpose with Metawheel. Allow Health Creator to assist
                  with better nutritional optimisation and pure living to aid Natural Remedies, preventative
                  health, vibrancy and longevity. Engage Weight Shifter to manage physique, build muscle
                  and bone density for a more dynamic life.
                </p>
              </div>
              <ul className='bot-img'>
              <li><a href='#metawheel'><img alt="not found" src={Metawheel1} /></a></li>
                <li><a href='#healthcreator'><img alt="not found" src={Metawheel2} /></a></li>
                <li><a href='#weightshifter'><img alt="not found" src={Metawheel3} /></a></li>
              </ul>
            </div>
          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className="dis-conainer-left">
            <div id="metawheel" className='left-sec'>
              <h1>Metawheel <img alt="not found" src={Icons} /></h1>
              <div className='left-contain'>
                <div className='left-container'>
                  <div>
                    <img alt="not found" src={User1} />
                  </div>
                  <div className='mid-sec'>
                    <h3>Human advancement architecture</h3>
                    <p>Unlock the pathways to your full Life force, Holistic Human Vibrancy, Significance and Purpose.</p>
                    <div className='btns'>
                      <button>READ MORE</button>
                      <button className='b2'>DOWNLOAD</button>
                    </div>
                  </div>
                  <img alt="not found" src={Mob1} />
                </div>
                <img alt="not found" src={LogoIcon} className="right-img" />
              </div>
              <h2>DiscoveR  Shift  Evolve  Create</h2>
            </div>

          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className="dis-conainer-left">
            <div id="healthcreator" className='left-sec right-bor-sec'>
              <h1 className='second-tile'>HEALTH CREATOR <img alt="not found" src={Icons} /></h1>
              <div className='left-contain'>
                <img alt="not found" src={LogoIcon} className="left-img" />
                <div className='left-container left-bor'>
                  <div>
                    <img alt="not found" src={Mob2} />
                  </div>
                  <div className='mid-sec mid-sec2'>
                    <h3>Optimum nutritional design & Cellular Purity App</h3>
                    <p>Dynamically support performance, healing and anti-aging. Interlock our food science, Nutritional Optimisation and the purity of body and mind to create your own perfect health. Sew the physiological conditions for achieving natural fat loss, muscle gain, and a state of vibrant preventative health.</p>
                    <div className='btns'>
                      <button>READ MORE</button>
                      <button className='b2'>DOWNLOAD</button>
                    </div>
                  </div>
                  <img alt="not found" src={Food} />
                </div>

              </div>
              <h2>DiscoveR  Shift  Evolve  Create</h2>
            </div>

          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className="dis-conainer-left">
            <div id="weightshifter" className='left-sec'>
              <h1>Weight Shifter <img alt="not found" src={Icons} /></h1>
              <div className='left-contain'>
                <div className='left-container'>
                  <div>
                    <img alt="not found" src={User2} />
                  </div>
                  <div className='mid-sec'>
                    <h3>Healthy Physique Management</h3>
                    <p>Sanctuary Software to help create your optimum weight and feel alive! It’s as simple as loading in the results you want and then plotting the course of action required to fulfil your goals. Make a plan now! </p>
                    <div className='btns'>
                      <button>READ MORE</button>
                      <button className='b2'>DOWNLOAD</button>
                    </div>
                  </div>
                  <img alt="not found" src={Mob1} />
                </div>
                <img alt="not found" src={LogoIcon} className="right-img" />
              </div>
              <h2>DiscoveR  Shift  Evolve  Create</h2>
            </div>

          </div>
        </Col>
      </Row>
      <FooterBanner />
      <Footer />
    </div>
  );
}

export default Discover;