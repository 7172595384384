import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import "./index.scss";
import FooterBanner from "../../components/footer-banner";
import SanctuaryServices from "../../components/sanctuary-services";
import Disclaimer from "../../components/disclaimer";
import CustomerReviews from "../../components/customer-reviews";
//import FiveMen from "../../components/five-men";
import ClusterIcons from "../../components/cluster-icons";
import Star from "../../assets/images/health-package/star.png";
import Line from "../../assets/images/health-package/line.png";
import Kimbra from "../../assets/images/health-package/kimbra.png";
import Video1 from "../../assets/images/health-package/video1.png";
import Video2 from "../../assets/images/health-package/video2.png";
import ComingSoon from "../../assets/images/health-package/comingsoon.png";
import PlayIcon from "../../assets/images/health-package/playIcon.png";
import Banner1 from "../../assets/images/health-package/s1.png";
import Banner2 from "../../assets/images/health-package/s2.png";
import Banner3 from "../../assets/images/health-package/s3.png";
import Banner4 from "../../assets/images/health-package/s4.png";
import Banner5 from "../../assets/images/health-package/s5.png";
import Banner6 from "../../assets/images/health-package/s6.png";
import Banner7 from "../../assets/images/health-package/s7.png";
import WhiteEarth from "../../assets/images/holistic-system/whiteEarth.png";
import Quality from "../../assets/images/health-package/quality.png";
import GoldenTick from "../../assets/images/health-package/goldenTick.png";
import GreenDisk from "../../assets/images/health-package/greenDisk.png";
import InviteCard from "../../assets/images/health-package/inviteCard.png";
import Footer from "../../components/Footer/Footer";
import Anthea from "../../assets/images/reviews/Anthea.jpeg";
import Graeme from "../../assets/images/reviews/Graeme.png";
import kimmcw from "../../assets/images/reviews/kimmcw.png";
import GaryJ from "../../assets/images/reviews/GaryJ.jpg";
import IvanM from "../../assets/images/reviews/IvanM.jpg";
import IvanM_endorsement from "../../assets/images/reviews/Ivan_endorseM.jpg";
import Mike from "../../assets/images/reviews/Mike.png";
import Mari from "../../assets/images/reviews/Mari.jpg";
import ph_ppbw from "../../assets/images/reviews/placeholder_pp_bg-removed-bw.png";
import ReactPlayer from "react-player";

const Index = () => {
  const history = useHistory();
  /* useEffect(() => {
    window.scrollTo(0, 0);
  }, []); */

  const dbLinks = (link) => {
    if (link.includes("http")) {
      window.location.href = link;
    } else {
      history.push(link);
    }
  };
  const sections = [
    {
      title: "Non-invasive Health Testing",
      desc: "Assessment testing package including Inbody scan, Cell-Wellbeing hair test, blood oxygen and blood pressure tests, skin condition testing. Gain a comprehensive overview of your health status by understand your body composition, nutritional analysis, cardiovascular and cardiorespiratory state for an insight into your rate of aging. The program retests each month to chart and ensure progress. ",
      image: Banner1,
      link: "/holistic-system",
    },
    {
      title: "Specialised Health Apps",
      desc: "Custom Sanctuary health Apps designed to integrate with all Sanctuary health services to assist and co-ordinate optimal nutrition intake, weight adjustment and health span. Program and chart intermittent fasting, Sanctuary meal and exercise plans, and sleep. Self-evaluate toxin exposure, fitness and flexibility, psychological well-being, health approach, physiological efficiencies and holistic health as a whole",
      image: Banner2,
      link: "/discover",
    },
    {
      title: "Life Force Food",
      desc: "Hundreds of specialised custom recipes, only using the most beneficial ingredients for well-being representing the most fresh and nutritious food to transform your mind and body- all analysed down to the micronutrients for precise nutrition. Improve as necessary; skin and hair beauty, natural immunity, metabolic flexibility, accelerated fat loss, cholesterol and other physiological efficiencies. ",
      image: Banner3,
      link: "https://sanctuarystore.co.nz/shop-by-product-and-services/raw-organic-ingredients",
    },
    {
      title: "Longevity Exercise",
      desc: "Exercise evaluated for calorie burn based on weight status for accurate weight shifting projection. Improve as necessary metabolic function, cardiorespiratory and cardiovascular performance, muscle and bone mass and density.  ",
      image: Banner4,
      link: "/advance-Exercises",
    },
    {
      title: "Health Coaching",
      desc: "30 minutes per week of the latest anti-aging and health span information, encouragement and inspiration to keep you motivated week by week throughout the entire program. This is also an opportunity to submit questions relevant to your stage of health transformation. Click here for one on one coaching.  ",
      image: Banner5,
      link: "/booking-plans",
    },
    {
      title: "Principle Health Guide",
      desc: "The Principle Guide and Book, Health Psychology, of the Sanctuary Vitality and Longevity Series to impart the right mindset to achieve health transformation. This book with assist you in succeeding with your health and lifestyle goals and is the perfect companion book to be reading prior, and be  referring to throughout the program.",
      image: Banner6,
      link: "/guide-landing-page",
    },
  ];
  const reviews = [
    {
      ppic: Anthea,
      caption: "above expectation",
      stars: 5,
      customer: "Anthea Baker",
      tab: "Health Directive Program",
      review:
        "A very educational program that opens one’s eyes to how the human body functions. The absolute importance of real food being our medicine. New ideas of different ways of eating to improve one’s life. With advanced and comprehensive program systems and health testing equipment. I would definitely recommend this program.",
      date: "20/03/2023",
    },
    {
      ppic: Graeme,
      caption:
        "Incredible testing equipment. Information obtained quite amazing.",
      stars: 5,
      customer: "Graeme Lamond",
      customerTitle: "Business owner",
      tab: "Health Directive Program",
      review:
        "A comprehensive course based on vast yet easily understandable information. The data and parameters used for testing and results are very comprehensive but Kim is great at breaking it all down into easy-to-understand concepts. Well organised and definitely a bonus to read the course material (Health Psychology guide) prior to starting the course.",
      date: "24/04/2023",
    },
    {
      ppic: kimmcw,
      caption: "“Enjoy the testing”",
      stars: 5,
      customer: "Kim McWilliam’s",
      customerTitle: "Director NZ PWN",
      tab: "Health Directive Program",
      review:
        "A health and well-being program to put the building blocks in place for a long and fulfilling life. The little new habits have become a joy in my life! Exceptional theory focus and each models behaviour and is inspiring and motivating.",
      date: "24/04/2023",
    },
    {
      ppic: GaryJ,
      caption: "Achieved all goals / A + Extremely well-priced",
      stars: 5,
      customer: "Gary Jamieson",
      customerTitle: "Insurance Brokers Limited",
      tab: "Health Directive Program",
      review:
        "A supportive and knowledgeable program that inspires a committed person to make beneficial lifestyle changes. What I enjoyed most was the introduction, support and enthusiasm to a whole new life balance. Knowing that the behaviour change in me is permanent. Reinforced my ability to commit to a desired outcome. If you have the will, they have the way.",
      date: "25/04/2023",
    },
    {
      ppic: IvanM,
      caption: "A+. The most advanced and comprehensive health program",
      stars: 5,
      customer: "Ivan Middlemost",
      customerTitle: "General accountant",
      tab: "Health Directive Program",
      review:
        "Best decision and temporary sacrifice that I ever made. I feel dynamic, inspired to live fully, and vibrant. A program every person should do, fit or unfit. Extremely well-priced – making greater health and well-being accessible to everyone! At the start it feels high but worth it when you get to month 1, 2 +.",
      date: "25/04/2023",
    },
    {
      ppic: Mike,
      caption: "Solid research by program coach, content is great!",
      stars: 5,
      customer: "Mike Lough",
      customerTitle: "Business owner",
      tab: "Health Directive Program",
      review:
        "The technology is world class. Knowledge is key. Get the data and use that knowledge to your own personal gain. I made significant improvements; enhanced sleep, greater strength and fitness, better mood, work ethic, and personal relationships. Most definitely feel I have started a journey to a ‘New Me’, with greater intent, focus and appreciation for life and a longer term view on sustainable results. Very good value for money, would most definitely recommend to a friend.",
      date: "05/07/2023",
    },
    {
      ppic: Mari,
      caption: "The one-on-ones were very fun and so inspiring.",
      stars: 5,
      customer: "Mari Gordon",
      tab: "Health Directive Program",
      review:
        "High quality content to make a massive difference in people’s lives. Kim is very passionate about getting you well and the energy he brings to the table is infectious and inspiring. The testing equipment completed the picture of how we can optimise our health. I started that journey to a “New Me” - better mood, outlook, personal relationships, increased energy, strength and fitness, lower visceral fat and an improved sense of overall health.",
      date: "18/07/2023",
    },
  ];
  return (
    <div className="package_health">
      <Header />
      <div className="package_health_banner">
        <div className="package_health_banner_content">
          <div className="package_health_banner_content_border"></div>
          {`Health Directive Program`}
          <p>
            Healing, Prevention, Superior Health,
            <br />
            Dynamic Shape, Greater Energy, Performance, Clarity and Emotional
            Vibrancy
          </p>

          <p>
            Take control of your health by committing to a 3-month program to
            optimise your well-being via the most advanced strategies, systems,
            and tech available. If you are determined to improve your health in
            the shortest time frame then this program is for you. The Health
            Directive Program co-ordinates holistic health testing, nutritional
            optimisation, longevity exercises, health psychology and coaching
            into a dynamic program with specialised apps to record and monitor
            results and project potential outcomes. This program is designed to
            be the necessary duration to introduce and habituate positive health
            habits, in order to make a sustained impact and cement reorientation
            to; a vibrant new you, and a manageable Self-Advancing Life Style.
          </p>
          <button
            type="button"
            class="btn btn-outline-secondary package_health_banner_content_btn"
          >
            <a href="mailto:kim@visionproducts.co.nz?subject=Health%20Directive%20Program%20-%20Enquire">Enquire Now</a>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary package_health_banner_content_btn"
          >
            <a href="tel:+6421505170">
              <i class="bi bi-telephone">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-telephone"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                </svg>
              </i>{" "}
              021 505 170
            </a>
          </button>
          <ClusterIcons />
        </div>
      </div>

      <div className="package_health_lightTitle">
        Discover your Magnificence
      </div>
      <div className="quality">
        <div className="quality_content">
          <div className="quality_content_item">
            <div className="quality_content_item_title">
              Health Directive Focus
            </div>
            <div className="quality_content_item_list">
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Nutritional optimisation, healthy microbiome, metabolic
                flexibility
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Physiological efficiencies for greater immunity and health span
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Toxin reduction, better skin, appearance, and energy levels
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Stress and depression management, better sleep and vagal tone
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Detailed body composition, sculpting, healthy Weight Shifting
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Co-ordinated elements to aid genuine happiness and radiance
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Mental fitness, emotional vibrancy, cardiovascular fitness
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Reprograming new reward mechanisms and habits for life
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Increased flexibility, range of motion, muscle and bone density
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Vital fundamentals to enable sovereignty over your wellbeing
              </div>
            </div>
          </div>
          <div className="quality_content_item">
            <div className="quality_content_item_title">
              Urban Sanctuary Delivery Guarantee
              {/* <img src={InviteCard} alt='inviteCard' /> */}
            </div>
            <div className="quality_content_item_assurance">
              <img src={Quality} alt="assurance" />
              <div className="quality_content_item_assurance_list">
                <div className="quality_content_item_assurance_list_item">
                  <img src={GoldenTick} alt="tick" />
                  Comprehensive, multifaceted and integrated health program
                  capable of transforming your health horizon
                </div>
                <div className="quality_content_item_assurance_list_item">
                  <img src={GoldenTick} alt="tick" />
                  Interlocks mental – emotional health, preventative, anti-aging
                  and health span initiatives
                </div>
                <div className="quality_content_item_assurance_list_item">
                  <img src={GoldenTick} alt="tick" />
                  Advanced integrated health analysis, coaching and monitoring
                  to achieve favourable results
                </div>
              </div>
            </div>
          </div>
          <div className="quality_content_invite">
            <img src={InviteCard} alt="inviteCard" />
          </div>
        </div>
      </div>
      <div className="packages_health_list">
        {sections.map((item, index) => {
          return (
            <div className="packages_section">
              <div className="packages_section_details">
                <div className="packages_section_details_desc">
                  <div className="packages_section_details_desc_f">
                    <div className="packages_section_details_desc_f_title">
                      {item.title}
                    </div>
                    <div className="packages_section_details_desc_f_details">
                      {item.desc}
                    </div>
                  </div>
                </div>
                <div
                  className="packages_section_details_img"
                  style={{ backgroundImage: `url(${item.image})` }}
                >
                  <div
                    className="packages_section_details_img_more"
                    onClick={() => dbLinks(item.link)}
                  >
                    <img
                      src={WhiteEarth}
                      alt="earth"
                      style={{ height: "20px", width: "20px" }}
                    />
                    READ MORE
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="packages_section">
          <div className="packages_section_details">
            <div className="packages_section_details_desc">
              <div className="packages_section_details_desc_f">
                <div className="packages_section_details_desc_f_title">
                  Program
                </div>
                <div className="packages_section_details_desc_f_details">
                  <ul>
                    <li>
                      <b>Program duration:</b> 3 months{" "}
                    </li>
                    <li>
                      <b>Commencement:</b> All commencement testing data
                      assessment and consultation{" "}
                    </li>
                    <li>
                      <b>Plan:</b> Introduction of a personalised regimen for
                      the 3months and realistic goal setting.{" "}
                    </li>
                    <li>
                      <b>Inspiration:</b> Weekly health coaching group session.{" "}
                    </li>
                    <li>
                      <b>Monitoring:</b> Retesting month 1, 2 and 3 to chart
                      progress.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="packages_section_details_img"
              style={{ backgroundImage: `url(${Banner7})` }}
            />
          </div>
        </div>
      </div>
      <div className="package_health_costing">
        <div className="package_health_costing_content">
          <div className="package_health_costing_content_title">
            Program costing
          </div>
          <ul>
            <li>
              <b>
                {" "}
                Health Psychology Principle Guide and book: $39.99 digital
                copy($49.99 if a printed copy is preferred). This is valuable
                resource to prepare you with the right mindset, and all Tools
                and Techniquies, to successfully champion your 3 month action
                program and beyond.
              </b>
            </li>
            <li>
              <b>
                770 In Body scans:$400.00. $100 per scan, 4 scans at monthly
                intervals. Commencement, month 1, month 2, and at the completion
                of the program.
              </b>
            </li>
            <li>
              <b>
                Cell Wellbeing Hair Testing:$240.00 $120.00 per test, 2 tests.
                Commencement and at the completion of the program.
              </b>
            </li>
            <li>
              <b>
                Blood oxygen and blood pressure:$160.00 $40.00 per combined
                test, 4 scans at monthly intervals. Commencement, month 1, month
                2, and at the completion of the program.
              </b>
            </li>
            <li>
              <b>
                Personal regimen plan:$300.00 Covers consultation, plan creation
                and one hour execution explanation and discussion.
              </b>
            </li>
            <li>
              <b>
                Health Creator App:Free subscription. To use for all food
                analysis, meal optimisation and planning.
              </b>
            </li>
            <li>
              <b>
                Longevity Exercise on demand classes: $83.88. 12 weeks @ $6.99
                per week (annually $179.88 if preferred)
              </b>
            </li>
            <li>
              <b>Weight Shifter App:$89.97. 3 months @ $29.99 per month.</b>
            </li>
            <li>
              <b>Weekly Group Coaching:$89.97. 3 months @ $29.99 per month.</b>
            </li>
            <li>
              <b>
                Program debrief at completion: $150.00. One hour personat
                appraisal enDating your final results, levet of satisfaction,
                and most importantly the next progressive steps you can make
                going forward.
              </b>
            </li>
            <li>
              <b>
                Life Force Food meal plans: As per food preferences ordered by
                you. A $300.00 food deposit is required at commencement to
                ensure your commitment to the program.{" "}
              </b>
            </li>
            <li>
              <b>
                Total program cost excluding food:$1553.81. (based on a 3 month
                subscription and coaching plan).
              </b>
            </li>
            <li>
              <b>Food deposit:$300.00 /Total: 1853.72.</b>
            </li>
            <li>
              <b>
                Commitment: The program fee of $1853.72 is payable in advance to
                ensure the best outcome as a result of your full commitment to
                the program over the 3 month duration. No refunds issued once
                payment is made. Your commitment and dedication is crucial to
                your success and we want to work with you so that you experience
                the very best results possible and improve your quality of life.{" "}
              </b>
            </li>
          </ul>
          <div style={{ textAlign: "center" }}>
            <div className="package_health_costing_content_title">
              Discover your Magnificence{" "}
            </div>
            <br />
            <button
              type="button"
              class="btn btn-outline-secondary package_health_banner_content_btn"
            >
              <a href="mailto:kim@visionproducts.co.nz?subject=Health%20Directive%20-%20Program%20Cost%20-%20Enquire">Enquire Now</a>
            </button>

            <button
              type="button"
              class="btn btn-outline-secondary package_health_banner_content_btn"
            >
              <a href="tel:+6421505170">
                <i class="bi bi-telephone">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-telephone"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                  </svg>
                </i>{" "}
                021 505 170
              </a>
            </button>
          </div>
        </div>
      </div>
      <div className="sectionTitle">Your health facilitator</div>
      <div className="facilitator">
        <div
          className="facilitator_video"
          style={{ backgroundImage: `url(${Video1})` }}
          alt="video2"
        >
          <iframe
            style={{ height: "100%", width: "100%" }}
            src={`https://www.youtube.com/embed/5BQJbReM8_E`}
            title="Kim Health Directive"
            allowFullScreen
          ></iframe>
        </div>
        <div className="facilitator_card">
          <div className="facilitator_card_details">
            <div className="facilitator_card_details_name">Kim Larking</div>
            <div className="facilitator_card_details_post">
              Human Advancement Architect
              <br />
              Founder Vision for Humanity / Vision Products / The Sanctuary
              Project
            </div>
            <div className="facilitator_card_details_desc">
              <br />
              The imaginal cells within the caterpillar, intrinsic to the
              miraculous transformation, which creates the butterfly, are also
              within us. Our imaginal cells are represented by the human mind,
              which holds the creation powers of imagination, planning and
              self-determination.
              <br />
              <br />
              As like the process of metamorphous, the mind harbours the ability
              to transform our lives as we wish. By understanding and governing
              the Mind-Body system, and ultimately by amplifying the processors
              of the body through the advanced application of the mind, we are
              able to take hold of evolutions best kept secrets to achieve
              greater holistic health span, and lock in our own Health Evolution
              process by mastering a Self- Advancing Life Style. In doing so we
              enable a higher echelon of satisfaction and life success, while
              also contributing to a better planet and humanity.
              <br />
              <br />
              We are the architect of our own Self, and hence we can define the
              person we wish to be, and with focus and belief materialise our
              dreams. Be the architect of your life and create an abundance of
              love, wealth, health and happiness.
            </div>
            <br />
            <b>
              <i>{`"Let me help you catalyse and architect your creation powers of imagination, planning & self-determination"`}</i>
            </b>
          </div>
        </div>
      </div>
      <div className="sectionTitle">Endorsement</div>
      <div className="endorsement">
        <div
          className="endorsement_video"
          style={{ backgroundImage: `url(${ComingSoon})` }}
          alt="video2"
        >
          <img src={PlayIcon} alt="icon" />
        </div>
        <div className="endorsement_card">
          <img src={IvanM_endorsement} alt="Ivan" />
          <div className="endorsement_card_details">
            <div className="endorsement_card_details_name">Ivan</div>
            <div className="endorsement_card_details_post">
              General Accountant
            </div>
            <div className="endorsement_card_details_desc">
              “Best decision and temporary sacrifice that I ever made. I feel
              dynamic, inspired to live fully, and vibrant. A program every
              person should do, fit or unfit. Extremely well-priced – making
              greater health and well-being accessible to everyone! At the start
              it feels high but worth it when you get to month 1, 2 +.”
            </div>
          </div>
        </div>
      </div>
      <div className="customers">
        <div className="customers_content">
          <div className="customers_content_star">
            <div className="customers_content_star_title">
              <img alt="not found" src={Star} title="WRITE REVIEWS" />
              <div className="customers_content_head">
                <h1>Customer Reviews</h1>
              </div>
            </div>
          </div>

          <CustomerReviews reviews={reviews} />
        </div>
      </div>
      <div class="wellness_package">
        <div class="wellness_package_Title">Online Wellness Package</div>
        <div class="wellness_package_Detail">
          <div class="wellness_package_Detail_header">
            Can’t come into the Urban Sanctuary? Do it all from home!
          </div>
          <div class="wellness_package_Detail_desc">
            <div className="wellness_package_Detail_item">
              <div className="wellness_package_Detail_item_title">
                Personal Consult
              </div>
              <div className="wellness_package_Detail_item_desc">
                Private video consultation to establish initial at home
                commencement data, realistic goal setting, and a personalised 3
                month regimen.
              </div>
              <div className="wellness_package_Detail_item_title">Online Learning Tools</div>
              <div className="wellness_package_Detail_item_desc"> E-book PDF of the Principle Health Guide and
              Book Health Psychology. Plus 12 x 40 minute on demand learning
              modules. 
              </div>
              <div className="wellness_package_Detail_item_title">
              Longevity Exercise Program
              </div> 
              <div className="wellness_package_Detail_item_desc">Access the Urban Sanctuary
              home workout programs - includes introductory orientation and
              guidance on the programs appropriate for you. 
              </div>
              
              <div className="wellness_package_Detail_item_title">Monitoring
              </div>
              <div className="wellness_package_Detail_item_desc">Two
              online check-ins over 3 months to chart and guide progress and
              evaluate results. Add on inspirational personal health coaching
              sessions as needed.
              </div> 
              <div className="wellness_package_Detail_item_title">Packaged Food Bundle – optional extra</div>
              <div className="wellness_package_Detail_item_desc">Pick a
              plant based food bundle from Sanctuary Store to go with the
              program - any purchase includes advanced knowledge concerning
              cooking methods, maximising nutritional needs for gradual toning
              and healthy Weight Shifting, nutritional optimisation and
              cleansing.
              </div>
              <div className="wellness_package_price">
                <div className="wellness_package_price_number">
                  $799.00
                </div>
                <div className="wellness_package_price_enquire light">
                <a href="mailto:kim@visionproducts.co.nz?subject=Health%20Directive%20-%20Wellness%20Package%20-%20Enquire">Enquire Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SanctuaryServices />
      <Disclaimer />
      <FooterBanner />
      <Footer />
    </div>
  );
};

export default Index;
