import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "shared/auth";
import { useQuery } from "react-query";
import HTMLFlipBook from "react-pageflip";
import { fetchEbook } from "shared/react-query/strapiAPI";
import { getStrapiAssetURL } from "shared/utils/strapiUtils";

// Function to sort images by the number in the filename
function sortByNumberInFilename(objects) {
  return objects.sort((a, b) => {
    const numA = parseInt(a.url.match(/_(\d+)_/)[1], 10);
    const numB = parseInt(b.url.match(/_(\d+)_/)[1], 10);
    return numA - numB;
  });
}

// Lazy-loaded image component with preloading for the next image
const Page = React.forwardRef(({ pageData, nextPageData }, ref) => {
  const imgRef = useRef(null);

  useEffect(() => {
    // Preload the next image in the background
    if (nextPageData) {
      const img = new Image();
      img.src = getStrapiAssetURL(nextPageData.url);
    }
  }, [nextPageData]);

  return (
    <div style={{ height: "100%", overflow: "hidden" }} ref={ref}>
      <img
        ref={imgRef}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
        src={getStrapiAssetURL(pageData.url)}
        loading="lazy" // Lazy loading the image
        alt={`Page ${pageData.id}`}
      />
    </div>
  );
});

function BookPageViewer() {
  const { auth } = useAuth();
  const { data, error, isLoading } = useQuery(
    ["ebook", auth.token],
    () => fetchEbook(),
    {
      staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading pages</div>;
  }

  const sortedPages = sortByNumberInFilename(data?.Pages ?? []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        overflow: "hidden",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage:
          "url('https://t3.ftcdn.net/jpg/05/74/98/30/360_F_574983020_1ebtYrNUHq9IwPDCuwOc6SODOgMg5rJY.jpg')",
      }}
    >
      {sortedPages && (
        <HTMLFlipBook showCover={true} width={500} height={700}>
          {sortedPages.map((page, index) => (
            <Page
              key={page.id}
              pageData={page}
              nextPageData={sortedPages[index + 1]} // Preload next image
            />
          ))}
        </HTMLFlipBook>
      )}
    </div>
  );
}

export default BookPageViewer;
