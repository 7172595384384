import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import greenActive from "../../assets/images/greenActive.png";
import BannerImg from '../../assets/images/service-health/provision5.png';
import './index.scss';

const Index = () => {
    const [secondaryDrop, setSecondaryDrop] = useState(false);
    const history = useHistory();
    const dbLinks = (link) => {
        history.push(link)
    }

    return (
        <div className='dropContainer'>
            <div className='service_dropdown'>
                <div className='service_dropdown_item'>
                    <div className='service_dropdown_item_title'>{`Shop by Products & Services`}</div>
                    <div className='service_dropdown_item_content' style={{ borderRight: '2px solid #8C8C8C', paddingRight: '30px'}}>
                        <div className='service_dropdown_item_content_links'>
                            <a href='https://sanctuarystore.co.nz/shop-by-product-and-services/raw-organic-ingredients' target="_blank">Lifeforce Food</a>
                            <a href='https://visionproductsdirect.com/' target="_blank">Vision Products</a>
                            <p onClick={() => dbLinks('/holistic-system')}>Holistic Health Testing</p>
                            <p onClick={() => dbLinks('/advance-Exercises')}>Advancement Exercises</p>
                            <p onClick={() => dbLinks('/advance-exercises-guide')}>Advanced Learning</p>
                            <p onClick={() => dbLinks('/health-facilitators')}>Health Facilitators</p>
                        </div>
                        <div className='service_dropdown_item_content_img' style={{ backgroundImage: `url(${BannerImg})`}}/>
                    </div>
                </div>
                <div className='service_dropdown_item'>
                    <div className='service_dropdown_item_title'>Shop by Advancement Interest</div>
                    <div className='service_dropdown_item_content'>
                        <div className='service_dropdown_item_content_links'>
                            {/* removed the link to show the secondary dropdown */}
                            {/* <a href='/service-health'>Health</a> */}
                            <p className={`${secondaryDrop ? 'active' : ''}`} onClick={() => setSecondaryDrop(!secondaryDrop)}>Health</p>
                            <p>Prosperity </p>
                            <p>Inspiration</p>
                            <p>Joy</p>
                            <p>Online Services</p>
                            <p>Shop by Levels</p>
                        </div>
                        <div className='service_dropdown_item_content_img' style={{ backgroundImage: `url(${greenActive})`}}/>
                    </div>
                </div>                   
            </div>
            {secondaryDrop && (<div className='secondaryDropdown'>
                <div className='secondaryDropdown_content'>
                    <div className='secondaryDropdown_content_item'>
                        <p onClick={() => dbLinks('/advance-exercises-guide')}>Guides</p>
                        <p onClick={() => dbLinks('/advance-exercises-guide')}>Workshops</p>
                        <p onClick={() => dbLinks('/advance-exercises-guide')}>Courses</p>
                        <p onClick={() => dbLinks('/advance-Exercises')}>Advancement Exercises</p>
                    </div>
                    <div className='secondaryDropdown_content_item'>
                        <p><a target="_blank" href='https://sanctuarystore.co.nz/shop-by-product-and-services/raw-organic-ingredients'>Lifeforce Food</a></p>
                        <p>Natural Highs bar</p>
                        <p><a target="_blank" href='https://visionproductsdirect.com/'>Vision Products</a></p>
                        <p>{`Spa & Salon`}</p>
                        <p>Rooftop Health Spa</p>
                    </div>
                    <div className='secondaryDropdown_content_item'>
                        <p><a target="_blank" href='https://sanctuarystore.co.nz/shop-by-health-interest/health-protocols'>health Protocols</a></p>
                        <p onClick={() => dbLinks('/health-facilitators')}>health Facilitators</p>
                    </div>
                    <div className='secondaryDropdown_content_item'>
                        <p>Health Creator App</p>
                        <p>Weight Shifter App</p>
                        <p onClick={() => dbLinks('/package-health')}>Health Packages</p>
                    </div>
                </div>
            </div>)}
        </div>
    )
}

export default Index;