import React, { useEffect, useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import "./index.scss";
import FooterBanner from "../../components/footer-banner";
import Disclaimer from "../../components/disclaimer";
import CustomerReviews from "../../components/customer-reviews";
import ClusterIcons from "../../components/cluster-icons";
import Star from "../../assets/images/health-package/star.png";
import Odette_ref from "../../assets/images/health-package/Odette_for_CR_book_ref.JPG";
import bookthumbnail from "../../assets/images/health-package/bookInFlowers.jpg";
import Video1 from "../../assets/images/health-package/video1.png";
import Quality from "../../assets/images/health-package/quality.png";
import GoldenTick from "../../assets/images/health-package/goldenTick.png";
import GreenDisk from "../../assets/images/health-package/greenDisk.png";
import InviteCard from "../../assets/images/guide/inviteCard.png";
import ServiceItem from "../../components/service-item";
import HealthImg1 from "../../assets/images/advance-guide/health1.png";
import HealthImg2 from "../../assets/images/advance-guide/health2.png";
import HealthImg3 from "../../assets/images/advance-guide/health3.png";
import HealthImg4 from "../../assets/images/advance-guide/health4.png";
import HealthImg5 from "../../assets/images/advance-guide/health5.png";
import HealthImg6 from "../../assets/images/advance-guide/health6.png";
import Course1 from "../../assets/images/guide/course1.png";
import Course2 from "../../assets/images/guide/course2.png";
import Course3 from "../../assets/images/guide/course3.png";
import Course4 from "../../assets/images/guide/course4.png";
import Course5 from "../../assets/images/guide/course5.png";
import Course6 from "../../assets/images/guide/course6.png";
import girlJumping from "../../assets/images/guide/girlJumping.png";
import applogo from "../../assets/images/advance-guide/app-logos.png";
import Anthea from "../../assets/images/reviews/Anthea.jpeg";
import Graeme from "../../assets/images/reviews/Graeme.png";
import Mike from "../../assets/images/reviews/Mike.png";
import Mari from "../../assets/images/reviews/Mari.jpg";
import IvanM from "../../assets/images/reviews/IvanM.jpg";
import Stars from "../../assets/images/guide/stars.png";
import ClockIcon from "../../assets/images/guide/clockIcon.png";
import LengthIcon from "../../assets/images/guide/lengthIcon.png";
import LangIcon from "../../assets/images/guide/langIcon.png";
import MoneyBackIcon from "../../assets/images/guide/moneyBackIcon.png";
import Accordion from "../../components/Accordion";
import Footer from "../../components/Footer/Footer";
import Client from "shopify-buy";
import BookPageViewer from "~/components/BookPageViewer";
import { useAuth } from "shared/auth";
import { useCreateCart, useFetchOrderHistoryWithLineitems } from "shared/react-query/hooks";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    height: "100vh",
    width: "100vw",
  },
}));

const Index = () => {
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [checkout, setCheckout] = useState({ lineItems: [] });
  const { auth } = useAuth();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data: orderHistoryWithLineitems } = useFetchOrderHistoryWithLineitems(
    { token: auth?.token }
  );

  function getVariantIds(data) {
    const ids = [];

    function extractIds(obj) {
      if (Array.isArray(obj)) {
        obj.forEach((item) => extractIds(item));
      } else if (typeof obj === "object") {
        for (const key in obj) {
          if (key === "variant" && obj[key]?.id) {
            ids.push(obj[key]?.id);
          } else {
            extractIds(obj[key]);
          }
        }
      }
    }

    extractIds(data);
    return ids;
  }
  
  const orderedProductVariantIds = getVariantIds(
    orderHistoryWithLineitems?.customer ?? []
  );
  const bookProductVariantId = "gid://shopify/ProductVariant/42566191972547"; // The product's ID (Shopify's global ID format)

  const isProductPurchased =
    orderedProductVariantIds.includes(bookProductVariantId);

    const SHOPIFY_STOREFRONT_API_TOKEN = process.env.REACT_APP_SHOPIFY_STOREFRONT_API_TOKEN ?? ''

  const client = Client.buildClient({
    domain: "visionproductsdirect.com",
    storefrontAccessToken: SHOPIFY_STOREFRONT_API_TOKEN,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const buyBookandCheckout = (productVariant) => {
    client.checkout.create().then((res) => {
      setCheckout(res);
      const lineItemsToAdd = [
        {
          variantId: "gid://shopify/ProductVariant/" + productVariant,
          quantity: parseInt(1, 10),
        },
      ];
      client.checkout.addLineItems(res.id, lineItemsToAdd).then((res) => {
        setCheckout(res);
        window.open(res.webUrl, "_blank");
      });
    });
  };

  const dbLinks = (link) => {
    if (link.includes("http")) {
      window.open(link, "_blank");
    } else {
      history.push(link);
    }
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const guideContentStyle = {
    color: "#006994",
    textDecoration: "underline",
  };
  let guidesWorkshops = [
    {
      name: "HEALTH PSYCHOLOGY",
      subname: "Principle Health Guide and Book available now",
      image: HealthImg1,
      link: "/guide-landing-page",
    },
    {
      name: "LIFE FORCE FOOD",
      subname: "Advance Order",
      image: HealthImg2,
      link: "https://sanctuarystore.co.nz/shop-by-product-and-services/raw-organic-ingredients",
    },
    {
      name: "LONGEVITY ACTIVITIES",
      subname: "Advance Order",
      image: HealthImg3,
    },
    {
      name: "PHYSIOLOGICAL EFFICIENCIES",
      subname: "Advance Order",
      image: HealthImg4,
    },
    { name: "NATURAL REMEDIES", subname: "Advance Order", image: HealthImg5 },
    { name: `PURITY`, subname: "Advance Order", image: HealthImg6 },
  ];

  let courses = [
    { name: `Discovery Weekend`, image: Course1, btn: "Pre-Course" },
    { name: "Vitality and Longevity", image: Course2, btn: "Main-Course" },
    { name: "Wisdom and Successes", image: Course3, btn: "Main-Course" },
    {
      name: "Empowerment and Significance",
      image: Course4,
      btn: "Main-Course",
    },
    { name: "Clarity and Beauty", image: Course5, btn: "Main-Course" },
    { name: `Metawheel Package `, image: Course6, btn: "Course Pkg" },
  ];

  let panels = [
    {
      label: `Overview: Health Psychology's role in holistic health`,
      content: `In this inaugural chapter, we embark on a journey to comprehend the various bodily systems and their profound interplay in nurturing holistic health. Exploring the realm of hormones and neurotransmitters, we unravel the intricate communication pathways that form the backbone of homeostasis - the perpetual guardian of our well-being. We explore how the brain conducts this symphony of equilibrium, but crucially, how the profound influence of the mind can be utilised to further orchestrate and govern that directive.

        Unveil the innate potential within yourself: Discover how ultimately you are equipped with a Self-protecting and Healing Mind-Body System (SPHMBS), an inherent facet of your being with a mandate dedicated to preservation, self-renewal, and continuous vitality. By tapping into the mechanisms of your SPHMBS, we seek to foster awareness of how you can actively cultivate increased vibrancy, health, and health span. This knowledge not only grants you the ability but also empowers you to reclaim sovereignty over your health and master your well-being.
        
        This chapter also serves as a pivotal gateway introducing the Urban Sanctuary’s Holistic Health Framework, which illustrates how your SPHMBS, and the vital role of Health Psychology, intertwine with the other five foundational pillars of well-being: Life Force Food, Longevity Exercises, Physiological Efficiencies, Natural Remedies, and Purity. A comprehensive model to provide a broad spectrum of well-being, which collectively forms a system to fortify our natural immunity, bolster disease resistance, expedite healing processes, and fortify physical and mental resilience. Collectively, these pillars pave the path toward enhanced youthfulness, vitality, and holistic wellness. Through the lens of Health Psychology, we embark on a transformative journey towards nurturing holistic health, armed with knowledge that not only enlightens but also empowers us to chart our course to optimal well-being.`,
      component: () => {
        return (
          <div>
            <br />
            <h5 style={guideContentStyle}>
              Chapter 1. Health Psychology in a Holistic Health Framework
            </h5>
            <ul style={guideContentStyle}>
              <li>Overview: A Contiguous, Mind – Brain – Body System</li>
              <li>The Central Nervous System: The Central Command Centre</li>
              <li>The Endocrine System</li>
              <li>Psychoneuroimmunology</li>
              <li>The Immune System: Protector and Healer</li>
              <li>Neuro-immune Interactions</li>
              <li>Endocrine-immune Interactions</li>
              <li>The Self-protecting and Healing Mind-Body System</li>
              <li>The Urban Sanctuary Holistic Health Framework</li>
              <li>
                Tools and Techniques: Understanding, Trusting, and Caring for
                Your SPHMBS
              </li>
              <li>Planet Impact</li>
            </ul>
          </div>
        );
      },
    },
    {
      label: "The Power of the Mind over Physical Health",
      content: `Contemplate this empowering statement: 'Your psychology determines your physiology' – highlighting the incredible influence your mind holds not just over your mental and emotional state, but also over your physical state and your long-term wellness journey. Together, let's delve into the fascinating realm of neuroscience-backed research, exploring how your mind can proactively counter stress, leverage the placebo effect, and even influence gene expression, thereby reshaping the course of your life. Discover why positive psychology techniques work and how to amplify their effectiveness, unlocking a pathway to tangible results and setting in motion a progressive feedback loop of enhancing mindset and action, for positive reward mechanisms and better health outcomes to evolve. Only with a solid perspective and appreciation of your innate abilities can you engineer your advancement and approach health in such a manner that allows your Self-Protecting and Healing Mind-Body System (SPHMBS) to take proper effect and truly thrive.`,
      component: () => {
        return (
          <div>
            <h5 style={guideContentStyle}>
              Chapter 2. The Power of the Mind Over Our Health
            </h5>
            <ul style={guideContentStyle}>
              <li>Psychology Can Control Physiology</li>
              <li>Healthy Interpretation of Feelings and Situations</li>
              <li>Acute and Chronic Stress and the Immune System</li>
              <li>Psychology Controls Physiology: The Wim Hof Method</li>
              <li>
                Tools and Techniques: Stress-is-Enhancing Mindset and Wim Hof
                Exercises
              </li>
              <li>Planet Impact: Depression Hypothesis</li>
              <li>
                Placebo and Nocebo: The Incredible Power of the Subconscious
                Mind
              </li>
              <li>The Nocebo Effect</li>
              <li>Planet Impact: Societal Effects of Placebo and Nocebo</li>
              <li>Altering Gene Expression: Our Secret Human Potential</li>
              <li>
                Self-empowerment: We Accept Unto Ourselves That Which Is Good
                For Us
              </li>
              <li>
                Tools and Techniques: Stress Management Psychology,
                Placebo-Nocebo and Gene Expression
              </li>
              <li>Planet Impact</li>
            </ul>
          </div>
        );
      },
    },
    {
      label: "The Emotional Immune System",
      content: `Emotions possess unique chemical signatures that significantly impact your state of health and physical well-being. This chapter progresses from chemical signatures to the magnitude of an entire mental-emotional well-being system. We delineate the key aspects of the Emotional Immune System (EIS) – a novel term that depicts and highlights the importance of proactively cultivating all self-enhancing activities to establish a stable and regenerative system for mental, emotional, physical, and spiritual health. While positive emotions are valuable, they may be transient; thus, fostering enduring feeling states necessitates guiding principles for life. This chapter explores fundamental principles to live by in order to cultivate an underlying state of well-being. Furthermore, we examine practical examples, such as the effects of benevolent beliefs and regular laughter to foster genuine happiness and aid healing processes.`,
      component: () => {
        return (
          <div>
            <h5 style={guideContentStyle}>
              Chapter 3. The Emotional Immune System
            </h5>
            <ul style={guideContentStyle}>
              <li>
                Neuropeptides and Psychoneuroimmunology: The Impact of Feelings
                on the Immune System
              </li>
              <li>Neuropeptides: The Biochemistry of Emotions</li>
              <li>
                Interlocking your SPHMBS: Nervous System, Thought, Feeling, and
                Gene Expression
              </li>
              <li>
                Positive Emotions and Greater Health: Life Through a Positive
                Lens
              </li>
              <li>Clearing Negative Emotions</li>
              <li>Tools and Techniques: Emotional Exercises</li>
              <li>Planet Impact: Family and Children’s Mental Health</li>
              <li>Empowering the Emotional Immune System</li>
              <li>Tools and Techniques: Benevolence and Happiness</li>
              <li>
                Planet Impact: Happiness as a Political Agenda to Enhance Social
                and Human Capital
              </li>
              <li>Laughter Therapy</li>
              <li>
                Tools and Techniques: Equipping Your Emotional Immune System
              </li>
              <li>Planet Impact: More Laughter in the Home and Hospitals</li>
            </ul>
          </div>
        );
      },
    },
    {
      label:
        "Nutritional Psychiatry, Fortifying Psychology, and Trauma Processing",
      content: `Explore the intricate connection between the foods we eat and our emotions. Discover how understanding and refining your diet can lead to greater happiness and well-being by incorporating the very best foods for positive mental health and bodily wellness.

       Unlock the mystery behind deeper-set trauma, uncovering the underlying causes and precipitating events. Learn effective strategies for navigating and processing deeply rooted emotional pain and significant past experiences. Discover how this knowledge can aid in healing past trauma and gradually undo chronic stress, offering relief to those struggling with lingering negative emotions, and mental health challenges, and assisting those who seek more emotional vibrancy and joy.
       
       Perhaps most intriguingly, this chapter reveals not only how the mind can heal the body but also how to unlock faster Natural Remedies and trauma processing by amplifying the power of the body through the advanced application of the mind. By harnessing the body's innate power through combining mental and physical techniques, the potential exists to champion life challenges and experience a new horizon of life.`,
      component: () => {
        return (
          <div>
            <h5 style={guideContentStyle}>
              Chapter 4. Nutritional Psychiatry, Fortifying Psychology, and
              Trauma Processing
            </h5>
            <ul style={guideContentStyle}>
              <li>
                Nutritional Psychiatry: How Foods Affect Feelings and Emotions
              </li>
              <li>Inflammatory Foods and Mental Health</li>
              <li>IReconditioning the Mind for Greater Pleasure</li>
              <li>Tools and Techniques: Food for Positive Mental Health</li>
              <li>Planet Impact: Food for Global Mental Health and Harmony</li>
              <li>Origins of Addiction and Trauma Processing</li>
              <li>Fortifying Psychology: Developing a Mind that Serves You</li>
              <li>
                Tools and Techniques: Three Aspects to Leaving Addiction Behind
              </li>
              <li>Trauma Processing</li>
              <li>
                Tools and Techniques: Restoring Efficient Vagal Tone Through
                Chiropractic and Massage
              </li>
              <li>Exercise: The Psychologically Transformative Activity</li>
              <li>The Mind Heals the Body, and the Body Heals the Mind</li>
              <li>Tools and Techniques: Brain Food and Trauma Therapy</li>
              <li>
                Planet Impact: The Global Mental Health Effects of a Sedentary
                Lifestyle, Omega-3 Deficiency, Junk Food and UPFs
              </li>
            </ul>
          </div>
        );
      },
    },
    {
      label: "Advancement - Higher Echelon Directives",
      content: `This chapter goes beyond health and healing and questions why we cannot aspire to advanced health, superhuman vitality, healing, anti-aging, and enduring youthful vigour throughout our elder years. We find our answers in the remarkable examples of the blue zones, particularly among the Okinawan elders, who not only live exceptionally long lives but also remain active and vibrant, fully engaged in life until their final days. We explore the factors that contribute to this remarkable vitality and longevity, often resulting in a staggering 30-year difference in life and/or health span. Their existence, invaluable indigenous knowledge, and culture serve as a partial litmus test to the concept of "living in vibrant health to 120 years of age."

      Through the means of a Self-Advancing Lifestyle (SAL), we uncover the potential to continuously integrate progressive initiatives to enhance our well-being. Armed with the Tools and Techniques from previous chapters, and empowered by our newfound knowledge and skills, the potential exists to grasp evolution's best-kept secrets to achieve greater health span. By mastering your own Self-Advancing Lifestyle; continually bolting on new self-improvement initiatives and thereby determining your own unique Health Evolution, you inadvertently reach a higher echelon of life satisfaction and success.`,
      component: () => {
        return (
          <div>
            <br />
            <h5 style={guideContentStyle}>
              Chapter 5. Advancement: Higher Echelon Directives
            </h5>
            <ul style={guideContentStyle}>
              <li>Mind Over Matter: The Mind-Brain Relationship</li>
              <li>Tools and Techniques: Higher Self-directives</li>
              <li>The Science of Faith</li>
              <li>Tools and Techniques: Nurturing Faith and Belief</li>
              <li>
                Planet Impact: The Lourdes Cures Concern Science as Well as
                Religion
              </li>
              <li>
                A Greater Mind to Greater Health: Preventative Health and Health
                Evolution
              </li>
              <li>Preventative Health</li>
              <li>Beyond Prevention: Health Evolution</li>
              <li>
                Tools and Techniques: Higher Self-Directives, Advanced Thinking,
                and Planning
              </li>
              <li>
                Integrative Health: Integrating a Greater Health Mindset within
                the Traditional Medical System
              </li>
              <li>Tools and Techniques: Quality Sleep</li>
              <li>Profile of a Healthy and Advancing Individual</li>
              <li>Urban Sanctuary Protocols: Health Routine Systems</li>
              <li>The Inspiring Example of Okinawan Elders</li>
            </ul>
          </div>
        );
      },
    },
    {
      label: "Summary of Tools and Techniques",
      content: `The Tools and Techniques are throughout the book and explained in each chapter within the context of that chapter. This section provides a summary and quick reference to those T&Ts, ideal to refer to once you have read the book for easy access.`,
      component: () => {
        return (
          <div>
            <h5 style={guideContentStyle}>
              Chapter 6. Summary of Tools and Techniques
            </h5>
            <ul style={guideContentStyle}>
              <li>Chapter 1: Mind is the Key</li>
              <li>
                Chapter 2: Stress-is-Enhancing Mindset and Wim Hof Exercises
              </li>
              <li>
                Chapter 2: Stress-is-Enhancing Mindset, Placebo-Nocebo and Gene
                Expression
              </li>
              <li>Chapter 3: Emotional Exercises</li>
              <li>Chapter 3: Benevolence and Happiness</li>
              <li>Chapter 3: Equipping your Emotional Immune System</li>
              <li>Chapter 4: Food for Positive Mental Health</li>
              <li>Chapter 4: Three Aspects to Leaving Addiction Behind</li>
              <li>
                Chapter 4: Restoring Efficient Vagal Tone Through Chiropractic
                and Massage
              </li>
              <li>Chapter 4: Brain Food and Trauma Therapy</li>
              <li>Chapter 5: Higher Self-directives</li>
              <li>Chapter 5: Nurturing Faith and Belief</li>
              <li>
                Chapter 5: Higher Self-Directives, Advanced Thinking, and
                Planning
              </li>
              <li>Chapter 5: Quality Sleep</li>
            </ul>
          </div>
        );
      },
    },
    {
      label:
        "Planet Impact - Global Health Psychology for Greater Intergenerational Healthspan",
      content: `Beyond your personal well-being and evolution lies something greater: the impact you have on society. Your self-advancement creates a positive effect for your family, community, and ultimately, the entire planet.

        At the end of each chapter, we segue into the potential impact our actions could have on the planet. This chapter dives deep into these ripple effects, exploring how our individual actions shape the world in which we live. By each striving to be our best selves, we collectively contribute to witnessing our social constructs change around us. In such a scenario, the earth and humanity would further evolve in health, wholesomeness, and harmony. An alignment for people and planet to catalyse well-being and interconnectedness – effectively: A Paradigm Shift to Greater Healthspan.`,
      component: () => {
        return (
          <div>
            <h5 style={guideContentStyle}>
              Chapter 7. Planet Impact: Global Health Psychology for Greater
              Intergenerational Healthspan
            </h5>
            <ul style={guideContentStyle}>
              <li>From Old Genome Health to New Genome Health</li>
              <li>Planetary Homeostasis / Human Homeostasis</li>
              <li>Bio-interdependence</li>
              <li>Life-Logical Health Care Principles and Systems</li>
              <li>
                A Global Paradigm Shift in Health Psychology: Greater
                Intergenerational Healthspan
              </li>
            </ul>
          </div>
        );
      },
    },
  ];
  const reviews = [
    {
      ppic: Anthea,
      caption: "Well researched",
      stars: 4,
      customer: "Anthea Baker",
      tab: "Health Psychology Guide and Book",
      review: "Comprehensive, highly educational and beneficial.",
      date: "20/03/2023",
    },
    {
      ppic: Graeme,
      caption: "A great read and aid to my health advancement",
      stars: 5,
      customer: "Graeme Lamond",
      customerTitle: "Business owner",
      tab: "Health Psychology Guide and Book",
      review:
        "Great comprehensive book and not too over complicated so that it was relatable and wasn’t dull like so many course books…..definitely a bonus to read prior to starting the Health Directive Program.",
      date: "24/05/2023",
    },
    {
      ppic: IvanM,
      caption: "Exceptional, comprehensive and highly beneficial.",
      stars: 5,
      customer: "Ivan Middlemost",
      customerTitle: "General accountant",
      tab: "Health Psychology Guide and Book",
      review:
        "The book is very detailed and comprehensive, but explains material in a lay-person and easy to understand manner. Exceptional, well researched and presented material. I took out what was important to me and what I enjoyed most was the feeling of advancement.",
      date: "25/04/2023",
    },
    {
      ppic: Odette_ref,
      caption: "It’s actually changed my life..",
      stars: 5,
      customer: "Odette Singleton-Wards",
      customerTitle: "Business Owner",
      tab: "Health Psychology Guide and Book",
      review:
        "This book makes you feel empowered. It makes you want to shake your life up and give the very practical and easy Tools and Techniques a go! You realise you’re in the driver’s seat of your life and if you want to see changes, you are the only one who can do it.",
      date: "08/11/2023",
    },
    {
      ppic: Mike,
      caption: "Solid research.",
      stars: 5,
      customer: "Mike Lough",
      customerTitle: "Business owner",
      tab: "Health Psychology Guide and Book",
      review: "A great read and aid to my health advancement.",
      date: "05/07/2023",
    },
    {
      ppic: Mari,
      caption: "…opened my eyes to see the value in the book.",
      stars: 4.5,
      customer: "Mari Gordon",
      tab: "Health Psychology Guide and Book",
      review:
        "The more Kim explained the content, the more I began to see the value in it, in fact the knowledge provided is critical for overall well-being. You just need to make the effort to read and engage with it and then you will have “AHA” moments.",
      date: "18/07/2023",
    },
  ];

  return (
    <div className="guide">
      <Header />
      <div className="guide_banner">
        <div className="guide_banner_content">
          <div className="guide_banner_content_data">
            <div className="guide_banner_content_data_title">
              Principle Health Guide and Book
            </div>
            <div className="guide_banner_content_data_post">
              Health Psychology
            </div>
            <div className="guide_data_desc">
              {" "}
              A Paradigm Shift to Greater Health Span
            </div>
            <div className="guide_data_rating">
              <img src={Stars} alt="Stars" />
              <p>{`(6)`}</p>
            </div>
            <div className="guide_data_author">
              <b>Author:</b> <i>Kim Larking</i>
            </div>
            <p>
              <b>Description:</b> A book to spark immense awareness and belief
              in your intrinsic superpowers – your Self Protecting and Healing
              Mind–Body System (SPHMBS), your Emotional Immune System (EIS), and
              which hands you the keys to a Self-Advancing Lifestyle (SAL) to
              truly feel a sense of significance, purpose, and dynamic living!
              <br />
              <br />
              If you want to live a long vibrant life - one that you inherently
              knew you were entitled to and destined to live, then this book
              outlines how to do exactly that. A well-researched technical book,
              which references leading neuroscientists, doctors, and health
              specialists to validate every method, ‘Tool and Technique’, and
              delivered to you in a system to architect your best life!
              <br />
              <br />
              Gain the knowledge, methodologies, and strategies to create a mind
              that serves you, conquer mental health issues, evolve emotional
              vibrancy and joy, unlock faster Natural Remedies and trauma
              processing, ponder the science of faith healing, and rejoice in
              the ways you can enhance your life with extra years of advanced
              health span.
              <br />
              <br />
              Use this Principle Guide as a solid base to start proactively
              generating holistic health, and to lead into and interlock the
              following five Guides in the Vitality and Longevity Series.
            </p>
            <div className="guide_banner_content_data_bordered">
              {" "}
              Living in Vibrant Health to 120 Years of Age
              <br />
              One of Six Health Guides in the Vitality and Longevity Series.
              <br />
              Principle Guide and Book
            </div>
          </div>
          <div className="guide_banner_content_card">
            <div className="guide_banner_content_card_banner">
              <div className="guide_banner_content_card_banner_middle">
                <a href="mailto:kim@visionproducts.co.nz?subject=Health%20Psychology%20Book%20-%20Available%20Now">
                  Available Now
                </a>
              </div>
            </div>
            <div className="guide_banner_content_card_price">
              <div className="guide_banner_content_card_price">
                E-BOOK $29.99
                <br />
                Read on website
              </div>
              {isProductPurchased ? (
                <button
                  className="guide_banner_content_card_price_btn light"
                  onClick={() => handleOpen()}
                >
                  Read book
                </button>
              ) : (
                <button
                  className="guide_banner_content_card_price_btn light"
                  onClick={() => buyBookandCheckout("42566191972547")}
                >
                  Buy
                </button>
              )}
            </div>
            <div className="guide_banner_content_card_price">
              <div className="guide_banner_content_card_price">
              BOOK $49.99 IN STORE PICKUP<br />
              NATIONAL DELIVERY $10 INTERNATIONAL DELIVERY $30
               </div>
              <button
                className="guide_banner_content_card_price_btn light"
                onClick={() => buyBookandCheckout("42566194856131")}
              >
                Buy
              </button>
            </div>
            <div className="guide_card_btns_btn dark">
              <a href="mailto:kim@visionproducts.co.nz?subject=Health%20Psychology%20Book%20-%20Enquire">
                Enquire
              </a>
            </div>
            <div className="guide_card_lb">
              <div className="guide_card_lb_list">
                <div className="guide_card_lb_list_item">
                  <img src={LengthIcon} alt="icon" />
                  <div>
                    <b>Length :</b> 121,000 words
                  </div>
                </div>
                <div className="guide_card_lb_list_item">
                  <img src={LangIcon} alt="icon" />
                  <div>
                    <b>Language :</b> English
                  </div>
                </div>
                <div className="guide_card_lb_list_item">
                  <img src={MoneyBackIcon} alt="icon" />
                  <div>
                    <b>Money-Back Guarantee</b>
                  </div>
                </div>
              </div>
              <div className="guide_card_lb thumbnail-container">
                <img
                  class="thumbnail-image"
                  src={girlJumping}
                  alt="Thumbnail"
                ></img>
              </div>
            </div>
          </div>
          <ClusterIcons />
        </div>
      </div>
      {isProductPurchased && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Button
                onClick={handleClose}
                style={{ alignSelf: "flex-end" }}
                variant="text"
              >
                Close
              </Button>
              <BookPageViewer />
            </div>
          </Fade>
        </Modal>
      )}
      <div className="guide_lightTitle">Discover your Magnificence</div>
      <div className="quality">
        <div className="quality_content">
          <div className="quality_content_item">
            <div className="quality_content_item_title">
              In this Guide you will learn:
            </div>
            <div className="quality_content_item_list">
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                The ‘why’ and the ‘how’ of wellbeing
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" /> Psychology behind successful
                outcomes
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                How to use placebo and alter gene expression
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Stress management and vagal tone
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Reprogramming and dual reward habits
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Strategies to harness the mind and emotions
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Processing negative emotions that halt progress
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                The elements to genuine happiness
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Preventative Health and Health Evolution
              </div>
              <div className="quality_content_item_list_item">
                <img src={GreenDisk} alt="green" />
                Steps to mastering Self- Advancement
              </div>
            </div>
          </div>
          <div className="quality_content_item">
            <div className="quality_content_item_title">
              Urban Sanctuary Delivery Guarantee
            </div>
            <div className="quality_content_item_assurance">
              <img src={Quality} alt="assurance" />
              <div className="quality_content_item_assurance_list">
                <div className="quality_content_item_assurance_list_item">
                  <img src={GoldenTick} alt="tick" />
                  Interlocks mental health, self-advancement and health span
                  initiatives
                </div>
                <div className="quality_content_item_assurance_list_item">
                  <img src={GoldenTick} alt="tick" />
                  With universal principles to take sovereignty over your
                  wellbeing and champion broad based life successes
                </div>
                <div className="quality_content_item_assurance_list_item">
                  <img src={GoldenTick} alt="tick" />
                  Complete with practical ‘Tools and Techniques’ that you can
                  apply immediately to change your life – as of today!
                </div>
              </div>
            </div>
          </div>
          <div className="quality_content_invite">
            <img src={InviteCard} alt="inviteCard" />
          </div>
        </div>
      </div>
      <div className="guide_accordion">
        <div className="guide_accordion_title">Guide Content</div>
        <div className="guide_accordion_section">
          <div className="guide_accordion_section_list">
            <Accordion panels={panels} />
          </div>
          <div className="guide_accordion_section_banner">
            <div className="guide_accordion_section_banner_content">
              AN INTRODUCTION TO
              <div className="guide_accordion_section_banner_content_large">
                The Self-Protecting and Healing Mind-Body System
              </div>
              and
              <div className="guide_accordion_section_banner_content_large">
                Mind - Body- Biosphere
              </div>
              FEATURING
              <div className="guide_accordion_section_banner_content_large">
                A Self-Advancing Lifestyle
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sectionTitle">companion workshop</div>
      <div className="guide_workshop_card">
        <div className="guide_workshop_card_banner">
          <div className="guide_workshop_card_banner_top">
            <img src={GreenDisk} alt="green" />
            Vitality and Longevity
          </div>
          <div className="guide_workshop_card_banner_middle">
            <a href="mailto:kim@visionproducts.co.nz?subject=Health%20Psychology%20Companion%20Workshop%20-%20Available%20Now">
              Available Now
            </a>
          </div>
          <div className="guide_workshop_card_banner_bottom">
            Health Psychology
          </div>
        </div>
        <div className="guide_workshop_card_details">
          <div className="guide_data_desc" style={{ color: "#B5B5B5" }}>
            Workshop
          </div>
          <img
            className="guide_workshop_card_details_thumbnail"
            src={bookthumbnail}
            alt="Health Psychology Book"
          />
          <div className="guide_data_desc">Health Psychology</div>
          <div className="guide_data_rating">
            <img src={Stars} alt="Stars" />
            <p>{`(102)`}</p>
          </div>
          <div className="guide_data_author">
            <b>Principle presenter:</b>&nbsp;&nbsp; <i>Kim Larking</i>
          </div>
          <p
            className={
              expanded
                ? "guide_workshop_card_details_desc expanded"
                : "guide_workshop_card_details_desc collapsed"
            }
          >
            <b>Description:</b>This workshop distils the Health Psychology book
            to help you navigate a path to greater happiness, well-being and
            joy. With practical lessons to gain the insights, personal
            confidence and ability to implement numerous Tools and Techniques to
            start positively transforming your life right away - creating a New
            You and engineering a highly rewarding Self-Advancing Lifestyle.
          </p>
          <div className="readMore" onClick={() => toggleExpand()}>
            {expanded ? "Read Less" : "Read More"}
          </div>
          {/* <div className="guide_workshop_card_details_price">
            $169.99 <span className="discount">&nbsp;&nbsp;$299.99</span>
          </div> */}
          <div className="guide_card_list">
            <div className="guide_card_lb_list_item">
              <img src={ClockIcon} alt="icon" />

              <div class="duration">
                <b>Duration :</b> 5 x 1 hour live interactive modules
                <p>
                  <i>(available now) includes e-book PDF</i>
                </p>
              </div>
              <div className="guide_workshop_card_details_price">
                $499.00 <span className="discount">$799.00</span>
              </div>
              <div className="guide_card_btn light cart-button">
                <a href="mailto:kim@visionproducts.co.nz?subject=Companion%20Workshop%20-%20Interactive%20Module%20-%20Enquire">
                  Enquire Now
                </a>
              </div>
            </div>
            <div className="guide_card_lb_list_item">
              <img src={ClockIcon} alt="icon" />

              <div class="duration">
                <b>Duration :</b> 5 x 1 hour online learning modules
                <p>
                  <i>(coming soon) includes e-book PDF</i>
                </p>
              </div>
              <div className="guide_workshop_card_details_price">$129.00</div>
              <div className="guide_card_btn light cart-button">
                <a href="mailto:kim@visionproducts.co.nz?subject=Companion%20Workshop%20-%20Learning%20Module%20-%20Enquire">
                  Enquire Now
                </a>
              </div>
            </div>
            <div className="guide_card_lb_list_item">
              <img src={LangIcon} alt="icon" />
              <div>
                <b>Language :</b> English
              </div>
            </div>
          </div>
          <div className="guide_card_btns">
            <div className="guide_card_btns_btn dark">
              <a href="tel:+6421505170">
                Contact Kim at +64 21 505 170 for a free 15 minute call
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="discover-section">
        <img alt="not found" className="nactiveimg" src={applogo} />
        <h1>Discover your Magnificence with Metawheel</h1>
        <p>
          Analysis to pinpoint specific areas of growth that you need most to
          advance your holistic well-being.
        </p>
        <p>
          Unlock the pathways to your full Life-force, Holistic Human Vibrancy,
          Significance and Purpose
        </p>
        <h3>Discover, Shift, Evolve, Create</h3>
        <h4>Human Advancement Architecture</h4>
      </div>
      <div className="sectionTitle">About Author</div>
      <div className="facilitator">
        <div
          className="facilitator_video"
          style={{ backgroundImage: `url(${Video1})` }}
          alt="video2"
        >
          <iframe
            style={{ height: "100%", width: "100%" }}
            src={`https://www.youtube.com/embed/ssWgE3N2xpM`}
            title="Kim's Video Guide"
            allowFullScreen
          ></iframe>
        </div>
        <div className="facilitator_card">
          <div className="facilitator_card_details">
            <div className="facilitator_card_details_name">Kim Larking</div>
            <div className="facilitator_card_details_post">
              Human Advancement Architect
              <br />
              Founder Vision for Humanity / Vision Products / The Sanctuary
              Project
            </div>
            <div className="facilitator_card_details_desc">
              <br />
              The imaginal cells within the caterpillar, intrinsic to the
              miraculous transformation, which creates the butterfly, are also
              within us. Our imaginal cells are represented by the human mind,
              which holds the creation powers of imagination, planning and
              self-determination.
              <br />
              <br />
              As like the process of metamorphous, the mind harbours the ability
              to transform our lives as we wish. By understanding and governing
              the Mind-Body system, and ultimately by amplifying the processors
              of the body through the advanced application of the mind, we are
              able to take hold of evolutions best kept secrets to achieve
              greater holistic health span, and lock in our own Health Evolution
              process by mastering a Self- Advancing Life Style. In doing so we
              enable a higher echelon of satisfaction and life success, while
              also contributing to a better planet and humanity.
              <br />
              <br />
              We are the architect of our own Self, and hence we can define the
              person we wish to be, and with focus and belief materialise our
              dreams. Be the architect of your life and create an abundance of
              love, wealth, health and happiness.
            </div>
            <br />
            <b>
              <i>{`"Let me help you catalyse and architect your creation powers of imagination, planning & self-determination"`}</i>
            </b>
          </div>
        </div>
      </div>
      <div className="customers">
        <div className="customers_content">
          <div className="customers_content_star">
            <div className="customers_content_star_title">
              <img alt="not found" src={Star} title="WRITE REVIEWS" />
              <div className="customers_content_head">
                <h1>Customer Reviews</h1>
              </div>
            </div>
          </div>
          <CustomerReviews reviews={reviews} />
        </div>
      </div>
      <div className="guide_services">
        <div className="guide_services_subTitle">{`Metawheel Courses`}</div>
        <div className="guide_services_action">View all Courses</div>
        <div className="guide_services_list">            
          {courses.map((item) => {
            return <ServiceItem cn="guide_services_list_item" item={item} />;
          })}
        </div>
      </div>
      <div className="guide_services">
        <div className="guide_services_subTitle">{`GUIDES & Workshops`}</div>
        <div
          className="guide_services_action"
          onClick={() => dbLinks("/advance-exercises-guide")}
        >
          See all Guides and workshops
        </div>
        <div className="guide_services_list">
          {guidesWorkshops.map((item) => {
            return (
              <div onClick={() => dbLinks(item?.link)}>
                <ServiceItem cn="guide_services_list_item" item={item} />
              </div>
            );
          })}
        </div>
      </div>
      <Disclaimer />
      <FooterBanner />
      <Footer />
    </div>
  );
};

export default Index;
