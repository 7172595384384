import {Breadcrumbs} from '~/components/ui/Breadcrumbs/Breadcrumbs';
import headerBackground from '~/assets/images/layout/SanctuaryStore_home.png';
import Image3 from '~/assets/images/page/techtesting/acroAcroPoseAcroYoga1139491@3x.png';
import ImageBg from '~/assets/images/page/healthGuides/banner_bg.jpg';
// import Section1TechTesting from '~/layouts/techTesting/Sections/Section1';
import bannerImg from '~/assets/images/page/account/banner.png';
import React from 'react';
import Account from '~/views/Account';

import ImageMainProduct from '~/assets/images/layout/submenu/services-submenu-main-product.png';
import ImageGiftPacks from '~/assets/images/layout/submenu/services-submenu-gift-packs.png';
import ImageHealthProtocal from '~/assets/images/layout/submenu/services-submenu-health-protocal.png';
import ImageLifeforceFood from '~/assets/images/layout/submenu/services-submenu-lifeforce-food.png';
import ImageMainHealth from '~/assets/images/layout/submenu/services-submenu-main-health.png';
import ImagePersonalGuide from '~/assets/images/layout/submenu/services-submenu-personal-guide.png';
import ImageTechTesting from '~/assets/images/layout/submenu/services-submenu-tech-testing.png';
import ImageVisionProducts from '~/assets/images/layout/submenu/services-submenu-vision-products.png';
import VarietyFreshTasty from '~/assets/images/layout/submenu/variety-fresh-tasty-vegetables-dark.jpg';
// import Header from '~/layouts/healthGuides/Sections/Header';
// import HeroSection from '~/layouts/healthProtocols/Sections/HeroSection';
// import GuideCoverFlow from '~/pages/healthGuides/Sections/GuideCoverFlow';

export const routes = [
  // {
  //   path: '/',
  //   name: 'Homepage',
  //   component: Home,
  // },
  // {
  //   path: '/about-us',
  //   name: 'About us',
  //   head: true,
  //   footer: true,
  //   side: true,
  //   component: Aboutus,
  //   breadcrumbs: headerBackground,
  // },
  // {
  //   path: '/find-a-store',
  //   name: 'Find a store',
  //   component: FindaStore,
  //   head: false,
  //   footer: false,
  //   side: false,
  //   breadcrumbs: headerBackground,
  // },
  {
    url: 'https://vfhurbansanctuary.com/discover',
    name: 'Discover',
    // component: Discover,
    head: true,
    footer: true,
    side: true,
    breadcrumbs: headerBackground,
  },
  // {
  //   path: '/careers',
  //   name: 'Careers',
  //   component: Participate,
  //   head: false,
  //   footer: false,
  //   side: false,
  //   breadcrumbs: headerBackground,
  // },
  // {
  //   name: 'Services',
  //   path: null,
  //   head: true,
  //   footer: false,
  //   side: true,
  //   noPath: true,
  //   submenu: [
  //     {
  //       name: 'Shop by Product & Services',
  //       path: '/shop-by-product-and-services',
  //       component: ShopByProduct,
  //       head: true,
  //       footer: true,
  //       side: true,
  //       menuThumbnail: ImageMainProduct,
  //       breadcrumbs: headerBackground,
  //       submenu: [
  //         {
  //           path: '/shop-by-product-and-services/life-force-food',
  //           name: 'Life Force Food',
  //           component: LifeForceFood,
  //           head: true,
  //           footer: true,
  //           side: true,
  //           noPath: true,
  //           breadcrumbs: headerBackground,
  //           menuThumbnail: ImageLifeforceFood,
  //           submenu: [
  //             {
  //               name: 'Ready to eat',
  //               path: '/shop-by-product-and-services/life-force-food',
  //               head: true,
  //               footer: true,
  //               side: true,
  //               submenu: [
  //                 {
  //                   name: 'Food',
  //                   path: '/shop-by-product-and-services/life-force-food?tag1=food',
  //                   component: LifeForceFood,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   submenu: [
  //                     {
  //                       name: 'Sushi',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=sushi',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                     {
  //                       name: 'Bowls and Soups',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=bowlsAndSoup',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                     {
  //                       name: 'Salads',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=salad',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                   ],
  //                 },
  //                 {
  //                   name: 'Drinks',
  //                   path: '/shop-by-product-and-services/life-force-food?tag1=drinks',
  //                   head: true,
  //                   component: LifeForceFood,
  //                   footer: true,
  //                   side: true,
  //                   submenu: [
  //                     {
  //                       name: 'Tonics',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=tonics',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                     {
  //                       name: 'Coffees and Teas',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=coffeeAndTeas',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                     {
  //                       name: 'Smoothies',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=smoothies',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                   ],
  //                 },
  //                 {
  //                   name: 'Condiments',
  //                   path: '/shop-by-product-and-services/life-force-food?tag1=condiments',
  //                   component: LifeForceFood,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   submenu: [
  //                     {
  //                       name: 'Dressings',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=dressings',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                     {
  //                       name: 'Spreads',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=spreads',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                     {
  //                       name: 'Sauces',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=sauces',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                   ],
  //                 },
  //                 {
  //                   name: 'Desserts',
  //                   path: '/shop-by-product-and-services/life-force-food?tag1=desserts',
  //                   component: LifeForceFood,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   submenu: [
  //                     {
  //                       name: 'Slabs',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=slabs',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                     {
  //                       name: 'Dessert Pots',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=dessertPots',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                     {
  //                       name: 'Yogurt Cups',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=yogurtCups',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                     {
  //                       name: 'Treats',
  //                       path: '/shop-by-product-and-services/life-force-food?tag1=treats',
  //                       component: LifeForceFood,
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                   ],
  //                 },
  //               ],
  //             },
  //             {
  //               name: 'Raw Organic Ingredients',
  //               path: '/shop-by-product-and-services/raw-organic-ingredients',
  //               component: RawIngredients,
  //               breadcrumbs:VarietyFreshTasty,
  //               head: true,
  //               footer: true,
  //               side: true,
  //               submenu: [
  //                 {
  //                   name: 'Raw Organic Ingredients',
  //                   path: '/shop-by-product-and-services/raw-organic-ingredients',
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   submenu: [
  //                     {
  //                       name: 'Specialised Organic Cereal mixes',
  //                       path:
  //                         '/shop-by-product-and-services/raw-organic-ingredients?tag1=specialisedOrganicCerealMixes',
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                     {
  //                       name: 'Organic grains, legumes, seeds, nuts and snacks',
  //                       path:
  //                         '/shop-by-product-and-services/raw-organic-ingredients?tag1=organicGrainsLegumesSeedsNutsAndSnacks',
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                     {
  //                       name: 'Functional powders, herbs and spices',
  //                       path:
  //                         '/shop-by-product-and-services/raw-organic-ingredients?tag1=functionalPowdersHerbsAndSpices',
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                     {
  //                       name: 'Organic Coffees and teas',
  //                       path: '/shop-by-product-and-services/raw-organic-ingredients?tag1=organicCoffeesAndTeas',
  //                       head: true,
  //                       footer: true,
  //                       side: true,
  //                     },
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
          // },
      //     {
      //       name: 'Vision Products',
      //       head: true,
      //       footer: true,
      //       noPath: true,
      //       side: true,
      //       breadcrumbs: 'Url',
      //       menuThumbnail: ImageVisionProducts,
      //       submenu: [
      //         {
      //           name: 'Vision Products',
      //           path: null,
      //           url: 'https://visionproductsdirect.com/',
      //           head: true,
      //           footer: true,
      //           side: true,
      //           submenu: [
      //             {
      //               name: 'Super Food Skin Care',
      //               url: 'https://visionproductsdirect.com/',
      //               path: null,
      //               noPath: true,
      //               head: true,
      //               footer: true,
      //               side: true,
      //             },
      //             {
      //               name: 'Eco Hair and Body Care',
      //               url: 'https://visionproductsdirect.com/',
      //               path: null,
      //               noPath: true,
      //               head: true,
      //               footer: true,
      //               side: true,
      //             },
      //             {
      //               name: 'Super Soft Eco Apparel',
      //               url: 'https://visionproductsdirect.com/',
      //               path: null,
      //               noPath: true,
      //               head: true,
      //               footer: true,
      //               side: true,
      //             },
      //             {
      //               name: 'Natures Greeting Cards',
      //               url: 'https://visionproductsdirect.com/',
      //               path: null,
      //               noPath: true,
      //               head: true,
      //               footer: true,
      //               side: true,
      //             },
      //             {
      //               name: 'Coconut Oil',
      //               url: 'https://visionproductsdirect.com/',
      //               path: null,
      //               noPath: true,
      //               head: true,
      //               footer: true,
      //               side: true,
      //             },
      //             {
      //               name: 'Gift Packs',
      //               url: 'https://visionproductsdirect.com/',
      //               path: null,
      //               noPath: true,
      //               head: true,
      //               footer: true,
      //               side: true,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       name: 'Tech Testing',
      //       component: TechTesting,
      //       noPath: true,
      //       head: true,
      //       footer: true,
      //       side: true,
      //       breadcrumbs: Image3,
      //       menuThumbnail: ImageTechTesting,
      //       Extension: Section1TechTesting,
      //       submenu: [
      //         {
      //           name: 'Tech Testing',
      //           url: 'https://vfhurbansanctuary.com/holistic-system',
      //           head: true,
      //           footer: true,
      //           side: true,
      //           submenu: [
      //             {
      //               name: 'Hair Testing',
      //               url: 'https://vfhurbansanctuary.com/tech-hair-testing',
      //               component: HairTesting,
      //               head: true,
      //               footer: true,
      //               side: true,
      //             },
      //             {
      //               name: 'Inbody Scanning',
      //               url: 'https://vfhurbansanctuary.com/store-service-inbody-scanning',
      //               component: InBodyScanning,
      //               head: true,
      //               footer: true,
      //               side: true,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       name: 'Gift Packs',
      //       path: '/shop-by-product-and-services/gift-packs',
      //       component: Giftpacks,
      //       head: true,
      //       footer: true,
      //       side: true,
      //       breadcrumbs: ImageGiftPacks,
      //       menuThumbnail: ImageGiftPacks,
      //     },
      //   ],
      // },
  //     {
  //       name: 'Shop by Health Interest',
  //       path: '/shop-by-health-interest',
  //       component: ShopByHealth,
  //       breadcrumbs: ImageBg,
  //       head: true,
  //       footer: true,
  //       side: true,
  //       menuThumbnail: ImageMainHealth,
  //       submenu: [
  //         {
  //           name: 'Personal Guides',
  //           component: HealthGuides,
  //           path: '/shop-by-health-interest/personal-guides',
  //           menuThumbnail: ImagePersonalGuide,
  //           head: true,
  //           noPath: true,
  //           footer: true,
  //           side: true,
  //           breadcrumbs: 'ImageBg',
  //           menuThumbnail: ImagePersonalGuide,
  //           submenu: [
  //             {
  //               name: 'Personal Guides',
  //               path: '/shop-by-health-interest/personal-guides',
  //               head: true,
  //               footer: true,
  //               side: true,
  //               submenu: [
  //                 {
  //                   name: 'Health Psychology',
  //                   component: HealthGuidesPsychology,
  //                   url: 'https://vfhurbansanctuary.com/guide-landing-page',
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   breadcrumbs: ImageBg,
  //                   Extension: Header,
  //                 },
  //                 {
  //                   name: 'Longevity Activities',
  //                   url: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=longevity-activities',
  //                   component: HealthGuidesPsychology,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   breadcrumbs: ImageBg,
  //                   Extension: Header,
  //                 },
  //                 {
  //                   name: 'Natural Remedies',
  //                   url: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=natural-remedies',
  //                   component: HealthGuidesPsychology,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   breadcrumbs: ImageBg,
  //                   Extension: Header,
  //                 },
  //                 {
  //                   name: 'Life Force Food',
  //                   component: HealthGuidesPsychology,
  //                   url: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=lifeforcefood',
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   breadcrumbs: ImageBg,
  //                   Extension: Header,
  //                 },
  //                 {
  //                   name: 'Physiological Efficiencies',
  //                   url: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=physiological-efficiencies',
  //                   component: HealthGuidesPsychology,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   breadcrumbs: ImageBg,
  //                   Extension: Header,
  //                 },
  //                 {
  //                   name: 'Purity',
  //                   url: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=purity',
  //                   component: HealthGuidesPsychology,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   breadcrumbs: ImageBg,
  //                   Extension: Header,
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: 'Health Protocols',
  //           path: '/shop-by-health-interest/health-protocols',
  //           component: AllHealthProtocols,
  //           breadcrumbs: 'ImageBg',
  //           noPath: true,
  //           head: true,
  //           footer: true,
  //           side: true,
  //           menuThumbnail: ImageHealthProtocal,
  //           submenu: [
  //             {
  //               name: 'Health Protocols',
  //               path: '/shop-by-health-interest/health-protocols',
  //               head: true,
  //               footer: true,
  //               side: true,
  //               submenu: [
  //                 {
  //                   name: 'Immune System',
  //                   path: '/shop-by-health-interest/health-protocols/immune-system',
  //                   component: HealthProtocolsImmuneSystem,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   breadcrumbs: ImageBg,
  //                   Extension: HeroSection,
  //                 },
  //                 {
  //                   name: 'Brain Health',
  //                   path: '/shop-by-health-interest/health-protocols/brain-health',
  //                   component: HealthProtocolsImmuneSystem,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   breadcrumbs: ImageBg,
  //                   Extension: HeroSection,
  //                 },
  //                 {
  //                   name: 'Heart Health',
  //                   path: '/shop-by-health-interest/health-protocols/heart-health',
  //                   component: HealthProtocolsImmuneSystem,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   breadcrumbs: ImageBg,
  //                   Extension: HeroSection,
  //                 },
  //                 {
  //                   name: 'Cellular Health',
  //                   path: '/shop-by-health-interest/health-protocols/cellular-health',
  //                   component: HealthProtocolsImmuneSystem,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   breadcrumbs: ImageBg,
  //                   Extension: HeroSection,
  //                 },
  //                 {
  //                   name: 'Bone and Cartilage Health',
  //                   path: '/shop-by-health-interest/health-protocols/bone-and-cartilage-health',
  //                   component: HealthProtocolsImmuneSystem,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   breadcrumbs: ImageBg,
  //                   Extension: HeroSection,
  //                 },
  //                 {
  //                   name: 'Increased Health Span',
  //                   path: '/shop-by-health-interest/health-protocols/increased-health-span',
  //                   component: HealthProtocolsImmuneSystem,
  //                   head: true,
  //                   footer: true,
  //                   side: true,
  //                   breadcrumbs: ImageBg,
  //                   Extension: HeroSection,
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: 'Health Creator App',
  //           component: '',
  //           url:'https://urban-sanctuary-dev.web.app/discover#healthcreator',
  //           head: true,
  //           footer: true,
  //           side: true,
  //         },
  //         {
  //           name: 'Weight Shifter',
  //           component: WeightShifter,
  //           url:'https://urban-sanctuary-dev.web.app/discover#weightshifter',
  //           head: true,
  //           footer: true,
  //           side: true,
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   name: 'Volunteer',
  //   path: '/volunteer',
  //   component: Team,
  //   head: false,
  //   footer: false,
  //   side: false,
  //   breadcrumbs: headerBackground,
  // },
  // {
  //   name: 'Participate',
  //   path: '/participate',
  //   component: Participate,
  //   head: true,
  //   footer: true,
  //   side: true,
  //   breadcrumbs: headerBackground,
  // },
  // {
  //   name: 'Participate detail',
  //   path: '/participate/:id',
  //   component: ParticipateDetail,
  //   head: false,
  //   footer: false,
  //   side: false,
  //   breadcrumbs: headerBackground,
  // },
  // {
  //   name: 'Know More',
  //   path: '/know-more',
  //   component: Knowmore,
  //   head: false,
  //   footer: false,
  //   side: false,
  //   breadcrumbs: headerBackground,
  // },
  // {
  //   name: 'Prosper',
  //   path: '/prosper',
  //   component: Prosper,
  //   head: true,
  //   footer: true,
  //   side: true,
  //   breadcrumbs: headerBackground,
  // },
  // {
  //   name: 'Get Started',
  //   path: '/get-started',
  //   component: Register,
  //   head: true,
  //   footer: true,
  //   side: true,
  //   loggedinRedirect: true,
  //   breadcrumbs: headerBackground,
  // },
  {
    name: 'Account',
    path: '/account',
    component: Account,
    head: false,
    footer: false,
    side: false,
    notloggedinRedirect: true,
    breadcrumbs: bannerImg,
  },
  // {
  //   name: 'Life force food Product detail',
  //   path: '/shop-by-product-and-services/life-force-food/:id',
  //   component: ProductDetail,
  //   head: false,
  //   footer: false,
  //   side: false,
  //   breadcrumbs: headerBackground,
  // },
  // {
  //   name: 'Raw Organics Product detail',
  //   path: '/shop-by-product-and-services/raw-organic-ingredients/:id',
  //   component: ProductDetail,
  //   head: false,
  //   footer: false,
  //   side: false,
  //   breadcrumbs: headerBackground,
  // },
  // {
  //   name: 'Life Force Food Ingredients',
  //   path: '/life-force-food-ingredients',
  //   component: LifeIngredients,
  //   breadcrumbs: headerBackground,
  //   head: false,
  //   footer: false,
  //   side: false,
  // },
  // {
  //   name: 'Nutritional details life force food',
  //   path: '/shop-by-product-and-services/life-force-food/:id/nutritional-details',
  //   component: NutritionalDetail,
  //   breadcrumbs: headerBackground,
  //   head: false,
  //   footer: false,
  //   side: false,
  // },
  // {
  //   name: 'Nutritional details raw organic ingredients',
  //   path: '/shop-by-product-and-services/raw-organic-ingredients/:id/nutritional-details',
  //   component: NutritionalDetail,
  //   breadcrumbs: headerBackground,
  //   head: false,
  //   footer: false,
  //   side: false,
  // },
];

const routesForSwitch = (rwp, prev = []) => {
  const arr = prev;
  rwp.forEach((element) => {
    arr.push(element);
    if (element.hasOwnProperty('submenu')) {
      routesForSwitch(element.submenu, arr);
    }
  });
  return arr;
};

export const flattenedRoutes = routesForSwitch(routes)
  .filter((ele) => !!ele.path)
  .map((element) => {
    const obj = {
      ...element,
      breadcrumbs: element.breadcrumbs
        ? (props) => <Breadcrumbs {...props} url={element.breadcrumbs} Extension={element.Extension ?? undefined} />
        : null,
      render: element.hasOwnProperty('component')
        ? (props) => <element.component {...props} />
        : (props) => <h2>{element.name}</h2>,
    };
    obj.hasOwnProperty('submenu') && delete obj.submenu;
    return obj;
  });

export const getPath = (name) => {
  const found = flattenedRoutes.find((element) => element.name.toLowerCase() === name.toLowerCase());
  if (found) {
    return found.path;
  } else {
    return null;
  }
};
