import React from "react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./account.scss";

import { AiFillPicture } from "react-icons/ai";
import { BsFillGrid3X3GapFill } from "react-icons/bs";

import metaBG from "../../assets/images/visionaire-network/metaBG.png";
import sslogo from "../../assets/images/visionaire-network/sslogo.png";
import uslogo from "../../assets/images/visionaire-network/uslogo.png";
import mslogo from "../../assets/images/visionaire-network/mslogo.png";
import mwlogo from "../../assets/images/visionaire-network/mwlogo.png";
import hclogo from "../../assets/images/visionaire-network/hclogo.png";
import wslogo from "../../assets/images/visionaire-network/wslogo.png";
import vfhlogo from "../../assets/images/visionaire-network/vfhlogo.png";
import bclogo from "../../assets/images/visionaire-network/bclogo.png";
import vplogo from "../../assets/images/visionaire-network/vplogo.png";
import sgnlogo from "../../assets/images/visionaire-network/sgnlogo.png";
import hcnlogo from "../../assets/images/visionaire-network/hcnlogo.png";


const VisionaireNetworkTable = ({display}) => {
  console.log(display)
  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };
  
  return (
    <>
      {/* <Header /> */}
      {/* <div className="profile-wrap"> */}
        {/* <div className="tab-wraper"> */}
          {/* <Row> */}
            <Col sm={12} md={12} className="no-pad">
              {/* <div className="profile-content"> */}

                <div className="form-wrapper">
                  <div className="viewOptCont">
                      <AiFillPicture className="optIcon" onClick={()=>display('wheel')}/>
                      <BsFillGrid3X3GapFill
                        className="optIcon"
                        style={{ color: "#999" }}
                        onClick={()=>display('grid')}
                      />
                  </div>

                  <div className="meta-cont">
                    <div className="meta-wheel">
                      <img className="metaBGImg" src={metaBG} alt="metaBG" />

                      <div className="logoz-cont">
                        <a className="logoz-link sslogo">
                          <img className="logoz" src={sslogo} alt="sslogo" />
                        </a>

                        <a className="logoz-link uslogo">
                          <img className="logoz" src={uslogo} alt="uslogo" />
                        </a>

                        <a className="logoz-link mslogo">
                          <img className="logoz" src={mslogo} alt="mslogo" />
                        </a>

                        <a className="logoz-link mwlogo" onClick={togglePopup}>
                          <img className="logoz" src={mwlogo} alt="mslogo" />
                        </a>

                        <a className="logoz-link hclogo">
                          <img className="logoz" src={hclogo} alt="hclogo" />
                        </a>

                        <a className="logoz-link wslogo">
                          <img className="logoz" src={wslogo} alt="wslogo" />
                        </a>

                        <a className="logoz-link vfhlogo">
                          <img className="logoz" src={vfhlogo} alt="vfhlogo" />
                        </a>

                        <a className="logoz-link bclogo">
                          <img className="logoz" src={bclogo} alt="bclogo" />
                        </a>

                        <a className="logoz-link vplogo">
                          <img className="logoz" src={vplogo} alt="vplogo" />
                        </a>

                        <a className="logoz-link sgnlogo">
                          <img className="logoz" src={sgnlogo} alt="sgnlogo" />
                        </a>

                        <a className="logoz-link hcnlogo">
                          <img className="logoz" src={hcnlogo} alt="hcnlogo" />
                        </a>

                        {popup && (
                          <form className="meta-form-cont">
                            <div className="list">
                              <label class="custom-checkbox">
                                <input type="checkbox" />
                                <span class="helping-el"></span>
                                <span class="label-text">Visionaire</span>
                              </label>
                            </div>

                            <div className="list">
                              <label class="custom-checkbox">
                                <input type="checkbox" />
                                <span class="helping-el"></span>
                                <span class="label-text">
                                  Receive Marketing
                                </span>
                              </label>
                            </div>

                            <div className="review-btn">
                              <a href="#">Review Visionare Details {">>"}</a>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </Col>
          {/* </Row> */}
        {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default VisionaireNetworkTable;


