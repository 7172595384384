import React, { useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import MindWorkoutTab from '../../components/mind-workout-tabs';
import FusionMasterSlider from '../../components/FusionMasterSlider/FusionMasterSlider';
import playerIcon from '../../assets/images/personHealth.png';

import ClusterIcons from '../../components/cluster-icons';
import Calender from '../../components/calender';
import './index.scss';
import HealthInfoWrapper from '../../components/health-info-wrapper'
import FooterBanner from '../../components/footer-banner';
import HealthBook from '../../components/health-book';

const ExercisesHealthMinds = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    return (
        <div className="exercise-air-wrapper">
            <Header />
            <div className="Banner-head">
                <div className="Banner-head_details">
                    <div className="breadCrumbs">Advancement exercises - Health</div>
                    <div className="title">Mind-Health</div>
                    <div className="data">Practice the Techniques and interlock the Tools to propel your life forward. Affirmations, breathing exercises, guided meditations, visualisations and healing readings are all methods to reprogram the mind for greater health, healing and advancement.
                        <br /><br />As the mind-body is a contiguous system encompassing the endocrine, nervous, cardiovascular, endocannabinoid and immune systems via messaging systems of neurotransmitters, neuropeptides and hormones – biological processors which are directly influenced by our most predominant thoughts and feelings, then the quality of the core beliefs and feelings states that we harbour at a conscious and subconscious level, play a significant role in improving overall well-being.</div>
                </div>
                
                <ClusterIcons />
            </div>
            <HealthInfoWrapper dotGlobal={1} />
            <MindWorkoutTab />

            <Row className="m-5">
                <Col sm={12} md={12}>
                    <div className="slider-secs">
                        <h1>Our Fusion Facilitators and Masters</h1>
                        <FusionMasterSlider></FusionMasterSlider>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12}>
              
                <HealthBook></HealthBook>
                </Col>
            </Row>
            <Calender />
            <FooterBanner />
            <Footer />
            {/* <div id='weight-shift-plan'>Weight Shifter Exercise Plan</div> */}
        </div>
    );
}

export default ExercisesHealthMinds;